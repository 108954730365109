import React, {  useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Loader from "../../loader/loader";
import MyInput from "../../MyInput";
import axiosClient from "../../axiosClient/axiosClient";
import MyAlert from "../../MyAlert";

const ProfileUpdate = () => {
  const [alertStatus, setAlertStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [data, setData] = useState();
  const [showpas, setShowpas] = useState(false);
  const handleShowpass = () => {
    setShowpas((pre) => !pre);
  };

  const [allValues, setAllValues] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setLoading] = useState(true);

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const handlealert = () => {
    goToTopFast();
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  useEffect(() => {
    axiosClient.get(`admin`).then((res) => {
      setLoading(false);
      setData(res.data);
    });
  }, []);

  const UpdateAdmin = (e) => {
    e.preventDefault();
    axiosClient
      .put(`admin`, {
        email: allValues.email,
        password: allValues.password,
      })
      .then((res) => {
        setShowAlert(true);
        setAlertStatus(true);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertStatus(false);
      });
  };

  const handelChange = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  return (
    <div className="max-md:ml-0 ">
      <h1 className="hidden max-lg:flex w-full justify-center mb-[10px] text-white text-[20px]">
          პროფილის რედაქტირება
        </h1>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="w-full flex flex-col gap-y-[10px] px-[50px] py-[50px] rounded-[10px] shadow-md shadow-[#3d7294] bg-[#0e1420] text-white">
          <form
            onSubmit={(e) => {
              UpdateAdmin(e);
            }}
            encType="multipart/form-data"
            className="flex flex-col gap-y-[20px]"
          >
            <div className="flex w-[420px] max-sm:w-auto justify-between items-center gap-[10px] max-sm:gap-0 max-sm:flex-col max-sm:items-start">
              <p className="">მეილი</p>
              <div className="w-[260px] h-[40px]  max-sm:w-full">
                <MyInput
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.email}
                  name="email"
                />
              </div>
            </div>
            <div className="flex w-[420px] justify-between items-center gap-[10px] max-sm:flex-col max-sm:items-start max-sm:w-auto">
              <p className="">ახალი პაროლი</p>
              <div className="flex items-center w-[260px] max-sm:w-full pr-[10px] h-[40px] rounded-[10px] max-md:justify-between max-md:pr-[4px] shadow-md shadow-[#3d7294] bg-[#1d263a]">
                <input
                  onChange={(e) => handelChange(e)}
                  value={data.password}
                  name="password"
                  placeholder="***********"
                  type={showpas ? "text" : "password"}
                  className="outline-none bg-[#1d263a] px-[10px] w-[calc(100%-18px)] "
                />
                <div
                  className="w-[18px] flex justify-center h-[18px] cursor-pointer"
                  onClick={() => {
                    handleShowpass();
                  }}
                >
                  {showpas ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-[50px] items-center gap-[20px] max-sm:flex-col max-sm:items-start">
              <button
                onClick={() => handlealert()}
                className="w-[230px] cursor-pointer h-[50px] flex items-center rounded-[10px] justify-center bg-[#23345a] shadow-md shadow-[#3d7294]
                hover:bg-[#335f7a] duration-200 "
                type="submit"
              >
                რედაქტირება
              </button>
            </div>
          </form>
        </div>
      )}
      <MyAlert
        showAlert={showAlert}
        alertStatus={alertStatus}
        text={
          alertStatus
            ? "ინფორმაცია წარმატებით შეიცვალა"
            : "ინფორმაცია ვერ შეიცვალა"
        }
      />
    </div>
  );
};

export default ProfileUpdate;
