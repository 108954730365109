import React, { useEffect, useState } from "react";

import Loader from "../../loader/loader";
import axiosClient from "../../axiosClient/axiosClient";
import AdminAllPartsComponent from "./AdminAllPartsComponent";
import { BsSearch } from "react-icons/bs";

export default function AdminAllParts() {
  const [isLoading, setIsLoading] = useState(true);
  const [newRender, setNewRender] = useState();

  const [partsData, setPartsData] = useState([]);
  const [fulldata, setFulldata] = useState();

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`admin/parts`)
      .then((res) => {
        setPartsData(res.data.data);
        setFulldata(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [newRender]);

  const [lari, setLari] = useState(1);
  const [runType, setRunType] = useState(1);

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  return (
    <div className="max-md:ml-0">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col  justify-between items-center w-full gap-y-[20px]">
          <h1 className="hidden max-lg:flex text-white text-[20px]">
          ნაწილები
        </h1>
          <div className="w-full flex items-center gap-[20px] max-sm:flex-col max-sm:items-start">
            <p className="text-white shadow shadow-[#325063] bg-[black] self-start h-[40px] px-[20px] flex items-center rounded-[10px] duration-200">
              სულ {fulldata.length} ნაწილი
            </p>
            <div className="flex justify-between h-[40px] w-[300px] shadow shadow-[#325063] px-[3px] bg-[black] rounded-[10px] items-center">
              <input
                className="outline-none w-[calc(100%-51px)] bg-transparent text-[white] pl-[5px]"
                placeholder="ნაწილების ძიება..."
              />
              <div className="w-[40px] h-[35px] text-[18px] cursor-pointer rounded-[10px] bg-white flex justify-center items-center">
                <BsSearch />
              </div>
            </div>
          </div>

          <div className="w-full gap-[30px] grid grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
            {(partsData || []).map((item) => (
              <AdminAllPartsComponent
                key={item.id}
                item={item}
                setNewRender={setNewRender}
                setIsLoading={setIsLoading}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
