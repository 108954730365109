import { useEffect } from "react";
import MakeModelDropDown from "../../MakeModelDropDown";
import PartsYearFromTo from "./PartsYearFromTo";
import { AiOutlineClose } from "react-icons/ai";
export default function PartsFirmModYear({
  item,
  manufacturer,
  test1,
  setTest1,
  allValues,
  setAllValues,
  handleDelete,
}) {
  return (
    <div className="flex items-end gap-[20px] max-xl:items-center max-xl:border-b-[1px] pb-[20px]">
      <div className="flex gap-[20px] max-xl:flex-col">
        <div className="flex flex-col items-start gap-y-[10px] justify-between">
          <p className="">მწარმოებელი</p>
          <div className="w-[200px] h-[50px] max-2xl:w-[160px] max-xl:w-[250px]">
            <MakeModelDropDown
              data={manufacturer}
              test1={test1}
              setTest1={setTest1}
              allValues={allValues}
              setAllValues={setAllValues}
              firstValue={item && item.firm}
              name="firm"
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-y-[10px] justify-between">
          <p>მოდელი</p>
          <div className="w-[200px] h-[50px]  max-2xl:w-[160px]  max-xl:w-[250px]">
            <MakeModelDropDown
              data={test1}
              allValues={allValues}
              setAllValues={setAllValues}
              firstValue={item && item.model}
              name="model"
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-y-[10px] justify-between">
          <p>წელი</p>
          <div className="w-[250px] h-[50px] max-2xl:w-[230px]  max-xl:w-[250px]">
            <PartsYearFromTo
              data={"year"}
              name1={"fyear"}
              name2={"tyear"}
              firstValue1={item && item.fyear}
              firstValue2={item && item.tyear}
              allValues={allValues}
              setAllValues={setAllValues}
            />
          </div>
        </div>
      </div>
      {item && (
        <div
          onClick={() => {
            handleDelete(item.id);
          }}
          className="w-[50px] h-[50px] text-[20px] flex items-center justify-center cursor-pointer rounded-[10px]"
        >
          <AiOutlineClose />
        </div>
      )}
    </div>
  );
}
