import { useEffect, useRef, useState } from "react";
import { PiCaretDownLight } from "react-icons/pi";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BsCheck2All, BsCheckAll } from "react-icons/bs";

export default function ProductsDropDown({
  data,
  title,
  searchValue,
  setSearchValue,
  name,
  name1,
  setNewItems,
}) {
  const [drop, setDrop] = useState(false);

  const handleDrop = () => {
    setDrop((pre) => !pre);
  };

  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    if (setNewItems) {
      setNewItems(selectedItems);
    }
  }, [selectedItems]);

  // Update the selected items
  const toggleItemSelection = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((items) => items !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const [value, setValue] = useState(title);

  const [From, setFrom] = useState("დან");
  const [Bis, setBis] = useState("მდე");

  // engines

  const engine = [];
  for (let engi = 0.1; engi < 13.0; engi += 0.1) {
    engine.push(engi);
  }

  // engines

  // year

  const year = [];
  for (let yearr = 2024; yearr > 1949; yearr -= 1) {
    year.push(yearr);
  }

  useEffect(() => {
    if (setSearchValue) {
      setSearchValue({ ...searchValue, [name]: From, [name1]: Bis });
    }
  }, [From, Bis]);

  // year

  useEffect(() => {
    if (setSearchValue) {
      setSearchValue({ ...searchValue, [name]: selectedItems });
    }
  }, [selectedItems]);

  return (
    <div
      className={`shadow h-full rounded-[10px] duration-150 
          shadow-[#3d7294] bg-[#0e1420] text-white
      `}
    >
      <div
        onClick={() => {
          handleDrop();
        }}
        className={`h-[50px]  px-[15px] flex items-center justify-between cursor-pointer
        `}
      >
        {data !== "engine" && data !== "year" && (
          <div className="flex items-center gap-[5px]">
            <p className="text-[17px]">{title}</p>
            <AiOutlineCheck
              className={`text-[#42ff42] text-[20px] ${
                selectedItems.length !== 0 ? "flex" : "hidden"
              }`}
            />
          </div>
        )}

        {(data === "engine" || data === "year") && (
          <div className="flex items-center gap-[20px]">
            <p className="text-[17px]">{title}</p>
            <p>{From}</p>
            <p className="border-t-[3px] w-[40px] border-t-[white] rounded-full"></p>
            <p>{Bis}</p>
          </div>
        )}

        <div className="flex items-center justify-center gap-[10px]">
          <AiOutlineClose
            onClick={() => {
              setSelectedItems([]);
            }}
            className={` ${selectedItems.length !== 0 ? "flex" : "hidden"}`}
          />
          <PiCaretDownLight
            className={`${drop ? "rotate-180 duration-200" : "duration-200"}`}
          />
        </div>
      </div>

      {data === "year" && (
        <div
          className={`flex justify-around duration-150 ${
            drop ? "h-[150px] p-[5px]" : "h-0 overflow-hidden p-0"
          }`}
        >
          <div className="w-[100px]">
            <p className="h-[30px] px-[10px]">დან</p>
            <div className="showScrollbar bg-[#141d2d] rounded-[8px] h-[110px] overflow-y-scroll">
              {data === "year" &&
                year.map((yearr, index) => (
                  <p
                    className="cursor-pointer px-[10px] rounded-[5px] hover:bg-[#203f52] duration-100"
                    onClick={() => {
                      setFrom(yearr.toFixed(0));
                    }}
                    key={index}
                  >
                    {yearr.toFixed(0)}
                  </p>
                ))}
            </div>
          </div>
          <div className="w-[100px]">
            <p className="h-[30px] px-[10px]">მდე</p>
            <div className="showScrollbar bg-[#141d2d] rounded-[8px] h-[110px] overflow-y-scroll">
              {data === "year" &&
                year.map((yearr, index) => (
                  <p
                    className="cursor-pointer px-[10px] rounded-[5px] hover:bg-[#203f52] duration-100"
                    onClick={() => {
                      setBis(yearr.toFixed(0));
                    }}
                    key={index}
                  >
                    {yearr.toFixed(0)}
                  </p>
                ))}
            </div>
          </div>
        </div>
      )}

      {data === "engine" && (
        <div
          className={`flex justify-around duration-150 ${
            drop ? "h-[150px] p-[5px]" : "h-0 overflow-hidden p-0"
          }`}
        >
          <div className="w-[100px]">
            <p className="h-[30px] px-[10px]">დან</p>
            <div className="showScrollbar bg-[#141d2d] rounded-[8px] h-[110px] overflow-y-scroll">
              {data === "engine" &&
                engine.map((engi, index) => (
                  <p
                    className="cursor-pointer px-[10px] rounded-[5px] hover:bg-[#203f52] duration-100"
                    onClick={() => {
                      setFrom(engi.toFixed(1));
                    }}
                    key={index}
                  >
                    {engi.toFixed(1)}
                  </p>
                ))}
            </div>
          </div>
          <div className="w-[100px]">
            <p className="h-[30px] px-[10px]">მდე</p>
            <div className="showScrollbar bg-[#141d2d] rounded-[8px] h-[110px] overflow-y-scroll">
              {data === "engine" &&
                engine.map((engi, index) => (
                  <p
                    className="cursor-pointer px-[10px] rounded-[5px] hover:bg-[#203f52] duration-100"
                    onClick={() => {
                      setBis(engi.toFixed(1));
                    }}
                    key={index}
                  >
                    {engi.toFixed(1)}
                  </p>
                ))}
            </div>
          </div>
        </div>
      )}

      {(data !== "engine" || data !== "year") && (
        <div
          className={`showScrollbar w-full duration-150 
        ${
          drop
            ? "max-h-[140px] overflow-y-scroll p-[5px]"
            : "h-0 overflow-hidden p-0"
        }
        `}
        >
          <ul>
            {data &&
              data !== "engine" &&
              data !== "year" &&
              data.map((item) => (
                <li
                  key={item.id}
                  onClick={() => {
                    setValue(item.text);
                    toggleItemSelection(item.text);
                  }}
                  className="text-[17px] px-[10px] flex items-center gap-[8px] duration-100 hover:bg-[#203f52] cursor-pointer mb-[5px]"
                >
                  <div
                    className={`w-[20px] rounded-full h-[20px] text-white flex items-center justify-center duration-100 ${
                      selectedItems.includes(item.text)
                        ? "bg-[green]"
                        : "bg-white"
                    }`}
                  >
                    <BsCheckAll />
                  </div>
                  {item.text}
                </li>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
}
