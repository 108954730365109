import "./App.css";
import Header from "./components/header/Header";
import MainPage from "./components/pages/mainPage/MainPage";
import Footer from "./components/footer/Footer";
import { Route, Routes, useNavigate } from "react-router-dom";
import User from "./components/pages/user/User";
import Login from "./components/pages/login/Login";
import AdminPanel from "./components/pages/adminpanel/AdminPanel";
import Car from "./components/pages/productPage/Car";
import Part from "./components/pages/partsPage/Part";
import ProductCar from "./components/pages/products/ProductCar";
import ProductParts from "./components/pages/products/ProductParts";
import Services from "./components/pages/products/Services";
import Serv from "./components/pages/products/serv";
import { UserContext } from "./components/UserSettingContext/AxiosContext";
import { useContext, useState } from "react";
import MenuPopUp from "./components/MenuPopUp";
import MyAlert from "./components/MyAlert";
import ErrorPage from "./components/pages/ErrorPage/ErrorPage";

function App() {
  const {
    setIsAuthorized,
    setUser,
    setToken,
    isAuthorized
  } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [showAlertLogOut, setShowAlertLogOut] = useState(false);
  const [alertStatusLogOut, setAlertStatusLogOut] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const navigate = useNavigate();

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  const handleUploadClick = () => {
    goToTopFast();
    setTimeout(() => {
      setShowAlert(false);
      setShowAlertLogOut(false);
    }, 2200);
  };

  const logOut = () => {
    setIsAuthorized(false);
    setUser({});
    setToken(null);
    navigate("/");
  };

  return (
    <div className="bg-black w-full min-h-[100vh] ">
      <Header setShowMenu={setShowMenu} logOut={logOut} />
      <Routes>
        <Route path="*" element={<ErrorPage />} />
        <Route path="ErrorPage" element={<ErrorPage />} />

        <Route
          path="admin"
          element={
            <Login
              setShowAlert={setShowAlert}
              setAlertStatus={setAlertStatus}
              handleUploadClick={handleUploadClick}
            />
          }
        />
        <Route
          path="adminPanel/*"
          element={
              <AdminPanel
                setShowAlertLogOut={setShowAlertLogOut}
                setAlertStatusLogOut={setAlertStatusLogOut}
                handleUploadClick={handleUploadClick}
              />
          }
        />
        <Route path="/" element={<MainPage />} />
        <Route path="productcars" element={<ProductCar />} />
        <Route path="productparts" element={<ProductParts />} />
        <Route path="services" element={<Services />} />
        <Route path="car/:id" element={<Car />} />
        <Route path="part/:id" element={<Part />} />
        <Route path="serv/:id" element={<Serv />} />

        <Route path="user/*" element={isAuthorized ? <User /> : <MainPage />} />
      </Routes>
      <Footer />
      <MenuPopUp
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        logOut={logOut}
      />

      <MyAlert
        showAlert={showAlert}
        alertStatus={alertStatus}
        text={
          alertStatus
            ? "ავტორიზაცია წარმატებით შესრულდა"
            : "გადაამოწმე ინფორმაცია"
        }
      />
      <MyAlert
        showAlert={showAlertLogOut}
        alertStatus={alertStatusLogOut}
        text={
          alertStatusLogOut
            ? "ადმინპანელიდან გამოსვლა შესრულდა"
            : "ადმინპანელიდან გამოსვლა ვერ შესრულდა!"
        }
      />
    </div>
  );
}

export default App;
