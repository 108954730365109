import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import { useDatasContext } from "../../DatasContext/DatasContext";
import { useEffect, useState } from "react";
import axiosClient from "../../axiosClient/axiosClient";
import Loader from "../../loader/loader";
import AddContact from "../user/AddContact";
import AddLocationInServ from "../user/AddLocationServ";
import AddDescr from "../user/AddDescr";
import UploadedVids from "../user/UploadedVids";
import AddVideo from "../user/AddVideo";
import UploadedPhts from "../user/UploadedPhts";
import AddPhoto from "../user/AddPhoto";
import AddValuableServs from "../user/AddValuableServs";

export default function AdminAllServiceInfo() {
  const navigate = useNavigate();
  const { servicetype } = useDatasContext();

  const params = useParams();
  const { id } = params;

  const [condition, setCondition] = useState("");

  const [allValues, setAllValues] = useState({
    services: "",
    location: [],
    description: "",
    ServcenterName: "",
    phone: "",
    vip: 0,
  });
  const [data, setData] = useState();

  const [addAdress, setAddAdress] = useState({
    city: "",
    adress: "",
  });

  const [selectedItems, setSelectedItems] = useState([]);
  // Update the selected items
  const toggleItemSelection = (item) => {
    if (selectedItems.filter((items) => items.id === item.id)[0]) {
      setSelectedItems(selectedItems.filter((items) => items.id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  useEffect(() => {
    if (data) {
      setSelectedItems(data.services);
    }
  }, [data]);

  useEffect(() => {
    setAllValues({ ...allValues, services: selectedItems });
  }, [selectedItems]);

  const [showAcceptAlert, setShowAcceptAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [loader, setLoader] = useState(true);
  const handleUploadClick = () => {
    setTimeout(() => {
      setLoader(true);
      setShowAcceptAlert(false);
      setShowErrorAlert(false);
    }, 2000);
  };

  useEffect(() => {
    axiosClient.get(`admin/servcenters/${id}`).then((res) => {
      setLoader(false);
      setData(res.data);
      setAllValues(res.data);
    });
  }, [id]);

  const UpdateService = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    formData.append("services", JSON.stringify(allValues.services));
    formData.append("location", JSON.stringify(allValues.location));
    formData.append("description", allValues.description);
    formData.append("servcenterName", allValues.servcenterName);
    formData.append("phone", allValues.phone);
    formData.append("vip", allValues.vip);

    axiosClient
      .post(`admin/servcenters/${id}`, formData)
      .then((res) => {
        setShowAcceptAlert(true);
        goToTopFast();
        navigate("/adminPanel/adminAllService");
        console.log(res);
        setSelectedItems(res.data.services);
      })
      .catch((err) => {
        console.log(err);
        setShowErrorAlert(true);
      });
  };

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const DeleteImgBack = (id) => {
    axiosClient
      .delete(`user/ServcentersImage/${id}`)
      .then((res) => {
        DeleteImgFront(id);
      })
      .catch((err) => console.log(err))
      .finally(setLoader(false));
  };
  const DeleteImgFront = (id) => {
    const newData = { ...data };

    newData.servcenters_imgs = newData.servcenters_imgs.filter(
      (item) => item.id !== id
    );
    setData(newData);
  };
  const DeleteVideoBack = (id) => {
    axiosClient
      .delete(`user/ServcentersVideo/${id}`)
      .then((res) => {
        DeleteVideoFront(id);
      })
      .catch((err) => console.log(err))
      .finally(setLoader(false));
  };
  const DeleteVideoFront = (id) => {
    const newData = { ...data };

    newData.servcenters_videos = newData.servcenters_videos.filter(
      (item) => item.id !== id
    );
    setData(newData);
  };

  return (
    <div className="w-full flex flex-col gap-7 text-white">
      <h1 className="hidden max-lg:flex w-full justify-center mb-[10px] text-white text-[20px]">
          სერვისის რედაქტირება
        </h1>
      {loader ? (
        <Loader />
      ) : (
        <form
          onSubmit={(e) => UpdateService(e)}
          encType="multipart/form-data"
          className="flex flex-col gap-7"
        >
          <AddContact
            allValues={allValues}
            setAllValues={setAllValues}
            isService={true}
            data={data}
          />
          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">სერვისები</p>
            <div className="grid grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-tiny:grid-cols-2 w-full gap-y-[20px] gap-[20px]">
              {servicetype.map((item) => (
                <div
                  key={item.id}
                  onClick={() => toggleItemSelection(item)}
                  className={`flex justify-between w-full h-[60px] duration-200 items-center px-[10px] rounded-[10px] cursor-pointer 
                    
                ${
                  selectedItems.filter((items) => items.id === item.id)[0]
                    ? "bg-[#335f7a] text-white"
                    : "shadow-md shadow-[#3d7294] bg-[#0e1420]"
                }
                `}
                >
                  <p className="text-[14px]">{item.text}</p>
                  <div
                    className={`w-[30px] h-[30px] rounded-full flex items-center justify-center duration-200 
        ${
          selectedItems.filter((items) => items.id === item.id)[0]
            ? "bg-[#3d7294] border-[1px] text-[22px] text-white"
            : "bg-[#335f7a]"
        }  `}
                  >
                    {selectedItems.filter(
                      (items) => items.id === item.id
                    )[0] ? (
                      <AiOutlineCheck />
                    ) : (
                      <AiOutlinePlus />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <AddLocationInServ
            allValues={allValues}
            setAllValues={setAllValues}
            setAddModel={setAddAdress}
            setAddAdress={setAddAdress}
            addAdress={addAdress}
          />

          <AddDescr
            allValues={allValues}
            setAllValues={setAllValues}
            firstValue={data && data.description}
            name="description"
          />

          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">ატვირთული ვიდეობი</p>

            <div className="grid grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-sm:grid-cols-2  gap-4 items-center">
              {loader ? (
                <Loader />
              ) : (
                data &&
                data.servcenters_videos &&
                data.servcenters_videos.map((item) => (
                  <UploadedVids
                    key={item.id}
                    item={item}
                    DeleteVideoBack={DeleteVideoBack}
                  />
                ))
              )}
            </div>

            <p className="hmli text-[17px]">დაამატე ვიდეო</p>
            <AddVideo inputName={"servcenters_videos"} />

            <p className="hmli text-[17px]">ატვირთული ფოტოები</p>

            <div className="grid grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-sm:grid-cols-2  gap-4 items-center">
              {loader ? (
                <Loader />
              ) : (
                data &&
                data.servcenters_imgs.map((item) => (
                  <UploadedPhts
                    key={item.id}
                    item={item}
                    DeleteImgBack={DeleteImgBack}
                  />
                ))
              )}
            </div>
            <p className="hmli text-[17px]">დაამატე ფოტო</p>
            <AddPhoto inputName={"servcenters_imgs"} />
          </div>

          <AddValuableServs
            allValues={allValues}
            setAllValues={setAllValues}
            firstValue={data && data.vip}
            name="vip"
          />
          <div className="w-full rounded-[10px] flex justify-end gap-y-[20px]">
            <button
              onClick={(e) => {
                handleUploadClick();
              }}
              type="submit"
              className="bg-[#0e1420] shadow-md shadow-[#3d7294]
              hover:bg-[#335f7a] duration-200 px-[40px] h-[50px] rounded-[10px] cursor-pointer flex items-center justify-center"
            >
              გამოქვეყნება
            </button>
          </div>
          <div
            className={`z-[20] fixed h-[80px] flex  rounded-[5px] bg-[#174717] shadow shadow-[#386c38] text-[17px] text-white left-[50%] translate-x-[-50%] duration-100 ${
              showAcceptAlert ? "top-[30px]" : "top-[-90px]"
            }`}
          >
            <div className="w-[8px] h-full bg-[green] rounded-l-[5px]"></div>
            <p className="px-[20px] flex items-center">წარმატებით აიტვირთა</p>
          </div>
          <div
            className={`z-[20] fixed h-[80px] flex  rounded-[5px] bg-[#671c1c] shadow shadow-[#671c1c] text-[17px] text-white left-[50%] translate-x-[-50%] duration-100 ${
              showErrorAlert ? "top-[30px]" : "top-[-90px]"
            }`}
          >
            <div className="w-[8px] h-full bg-[red] rounded-l-[5px]"></div>
            <p className="px-[20px] flex items-center">რაღაცა ჩეპპეეაააა!</p>
          </div>
        </form>
      )}
    </div>
  );
}
