import React from "react";
import { BsDot, BsFillPersonFill, BsFillTelephoneFill } from "react-icons/bs";

export const ServMainInfo = ({ data }) => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col gap-[20px]">
        <div className="flex flex-col items-center justify-start w-full">
          <div className="flex flex-row items-center gap-[10px] w-full">
            <div className="bg-gray-600 flex flex-row gap-1.5 items-center justify-start pr-[20px] rounded-full">
              <div className="bg-slate-100 flex flex-col h-[41px] items-center justify-center p-1 rounded-full w-[41px] mr-[10px]">
                <BsFillPersonFill className="text-[25px] text-gray-500" />
              </div>
              <h1 className="text-[20px] text-white ">{data.servcenterName}</h1>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-[10px] border-[1px] self-start px-[15px] rounded-full">
          <BsFillTelephoneFill className="text-white text-[24px]" />
          <p className="text-white text-[25px]">{data.phone}</p>
        </div>
      </div>
      <div className="flex flex-col py-5 gap-[20px]">
        <div className="flex flex-col gap-[13px] items-start justify-start ">
          <h1 className="text-base text-white">ფილიალების მისამართები</h1>
          {data &&
            data.location &&
            JSON.parse(data.location).map((item) => (
              <p
                key={item.id}
                className="text-white gap-[5px] rounded-full flex items-center self-start"
              >
                <BsDot />
                {item.city} {item.adress}
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};
