import { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";
import { useDatasContext } from "../../DatasContext/DatasContext";
import { useContext } from "react";
import { CurrencyContext } from "../../CurrencyContext/CurrencyContext";

export default function AddProdPrice({
  allValues,
  setAllValues,
  name1,
  name2,
  firstValue,
  secValue,
}) {
  const { userChooseCurrency, setUserChooseCurrency } =
    useContext(CurrencyContext);

  const [number, setNumber] = useState("");

  useEffect(() => {
    if (setAllValues) {
      setAllValues({
        ...allValues,
        [name1]: number,
        [name2]: userChooseCurrency,
      });
    }
  }, [number, userChooseCurrency]);

  const handleScroll = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (firstValue) {
      setNumber(firstValue);
      setUserChooseCurrency(secValue);
    }
  }, [firstValue]);

  return (
    <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
      <p className="hmli text-[17px]">ფასი</p>
      <div className=" flex gap-[30px]">
        <div
          onClick={() => {
            setUserChooseCurrency("GEL");
          }}
          className="flex items-center gap-[10px] cursor-pointer"
        >
          <div
            className={`w-[30px] h-[30px] rounded-full flex items-center justify-center text-[18px] ${
              userChooseCurrency === "GEL"
                ? "bg-[#3d7294] shadow shadow-[#3d7294] text-white duration-100"
                : "bg-white duration-100"
            }`}
          >
            {userChooseCurrency === "GEL" && <AiOutlineCheck />}
          </div>
          <p className="select-none">ლარი</p>
        </div>
        <div
          onClick={() => {
            setUserChooseCurrency("USD");
          }}
          className="flex items-center gap-[10px] cursor-pointer"
        >
          <div
            className={`w-[30px] h-[30px] rounded-full flex items-center justify-center text-[18px] ${
              userChooseCurrency === "USD"
                ? "bg-[#3d7294] shadow shadow-[#3d7294] text-white duration-100"
                : "bg-white duration-100"
            }`}
          >
            {userChooseCurrency === "USD" && <AiOutlineCheck />}
          </div>
          <p className="select-none">დოლარი</p>
        </div>
      </div>

      <p>სრული ფასი</p>

      <div className="w-[200px] cursor-pointer rounded-[10px] duration-150  shadow shadow-[#3d7294] bg-[#1d263a] flex items-center justify-between h-[60px] pl-[15px] pr-[8px]">
        <input
          value={number}
          min={0}
          onWheel={handleScroll}
          onChange={(e) =>
            setNumber((e.target.value = e.target.value.replace(/[^0-9]/g, "")))
          }
          className="w-[80%] rounded-[5px] h-[30px] bg-[#2c3956] outline-none pl-[10px]"
        />
        {userChooseCurrency === "GEL" ? (
          <p className="px-[10px] w-[40px] flex justify-center text-[25px]">
            ₾
          </p>
        ) : (
          <div className="w-[40px] flex items-center justify-center">
            <BsCurrencyDollar className="text-[25px]" />
          </div>
        )}
      </div>
    </div>
  );
}
