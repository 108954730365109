import React from "react";
import { BsSearch } from "react-icons/bs";
import merc from "../../../photos/merc-removebg-preview.png";
import parts from "../../../photos/parts-removebg-preview.png";
import bground from "../../../photos/bground.jpg";
import { Link } from "react-router-dom";

const FirstSection = () => {
  return (
    <div className=" relative w-full flex flex-col items-center pt-[150px] max-sm:pt-[110px] z-[1] px-[190px] max-xl:px-[100px] max-md:px-[50px] max-sm:px-[16px]">
      <img
        alt=""
        src={bground}
        className="absolute z-[-1] top-0 left-0 h-full w-full object-cover"
      />
      <div className="w-full  flex flex-col  gap-10">
        <div className="w-full  h-auto flex flex-col justify-center items-center text-white text-[30px] max-md:text-[24px] max-tiny:text-[18px]">
          <h1>შეამოწმე ავტომობილის ვინ კოდი</h1>
          <h1>ექსკლუზიურად CARFAX.GE-ზე</h1>
        </div>
        <div className="w-full px-[20%] max-lg:px-[10%] max-tiny:px-0">
          <div className="flex w-full items-center justify-between border-white bg-[#00000071] border-[1px] rounded-full pl-8 pr-[5px] h-[55px] max-tiny:h-[45px]">
            <input
              className="outline-none w-[80%] bg-[#00000000] text-[white]"
              placeholder="ჩაწერეთ ვინ კოდი"
            />
            <div className="w-[45px] h-[45px] max-tiny:w-[40px] max-tiny:h-[40px] text-[20px] cursor-pointer rounded-full bg-white flex justify-center items-center">
              <BsSearch />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-auto flex max-tiny:flex-col max-tiny:gap-y-[20px] justify-between px-[1px] items-center py-10">
        <Link
          to="/productcars"
          className="w-[45%] max-sm:w-[48%] max-tiny:w-full h-[298px] max-lg:h-[200px] max-sm:h-[150px]"
        >
          <div className="relative w-ful h-full border-white rounded-[30px] bg-white flex items-center justify-center">
            <div className="black-rectangle"></div>
            <img
              src={merc}
              alt=""
              className="w-[100%] max-lg:w-auto max-lg:h-full absolute object-contain "
            />
            <div>
              <h1 className="absolute bottom-[15px] text-[20px] right-[30px] text-white">
                ავტომობილები
              </h1>
            </div>
          </div>
        </Link>
        <Link
          to="/productparts"
          className="w-[45%] max-sm:w-[48%] max-tiny:w-full h-[298px] max-lg:h-[200px]  max-sm:h-[150px]"
        >
          <div className="relative w-full h-full border-white rounded-[30px] bg-white flex items-center justify-center">
            <div className="black-rectangle"></div>
            <img
              src={parts}
              alt=""
              className="w-[60%]  max-tiny:w-[65%] absolute object-contain "
            />
            <div>
              <h1 className="absolute bottom-[15px] text-[20px] right-[30px] text-white">
                ავტონაწილები
              </h1>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default FirstSection;
