import { useEffect, useState } from "react";
import axiosClient from "../../axiosClient/axiosClient";
import Loader from "../../loader/loader";
import EditCarCards from "./EditCarCards";
import EditServCards from "./EditServCards";
import MyAlert from "../../MyAlert";
import EditPartCards from "./EditPartCards";

export default function MyStatements() {
  const [active, setActive] = useState("cars");
  const [cardata, setCarData] = useState([]);
  const [partdata, setPartData] = useState([]);
  const [servdata, setServData] = useState([]);

  const [isLoader, setIsLoader] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);

  const [newRender, setNewRender] = useState();
  const [newRender1, setNewRender1] = useState();
  const [newRender2, setNewRender2] = useState();

  const handleUploadClick = () => {
    setTimeout(() => {
      setShowAlert(false);
    }, 2200);
  };

  useEffect(() => {
    axiosClient
      .get("user/car")
      .then((res) => {
        setCarData(res.data);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [newRender]);

  useEffect(() => {
    axiosClient
      .get("user/part")
      .then((res) => {
        setPartData(res.data);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [newRender1]);

  useEffect(() => {
    axiosClient
      .get("user/Servcenters")
      .then((res) => {
        setServData(res.data);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [newRender2]);

  return (
    <div className="w-full flex flex-col text-white">
      <div className="flex items-center self-start rounded-t-[10px] shadow-md shadow-[#3d7294]">
        <p
          onClick={() => {
            setActive("cars");
          }}
          className={`px-[10px] h-[35px] flex items-center  bg-[#1a4865] hover:bg-[#3d7294] duration-100 cursor-pointer rounded-tl-[10px] ${
            active === "cars" && "bg-[#3d7294]"
          }`}
        >
          ავტომობილები
        </p>
        <p
          onClick={() => {
            setActive("parts");
          }}
          className={`px-[10px] h-[35px] flex items-center bg-[#1a4865] hover:bg-[#3d7294] duration-100 cursor-pointer ${
            active === "parts" && "bg-[#3d7294]"
          }`}
        >
          ნაწილები
        </p>
        <p
          onClick={() => {
            setActive("servs");
          }}
          className={`px-[10px] h-[35px] flex items-center bg-[#1a4865] hover:bg-[#3d7294] duration-100 cursor-pointer rounded-tr-[10px] ${
            active === "servs" && "bg-[#3d7294]"
          }`}
        >
          სერვისები
        </p>
      </div>

      <div className="shadow-md shadow-[#3d7294] grid grid-cols-4 max-2xl:grid-cols-3 max-xl:grid-cols-2 max-sm:grid-cols-1  bg-[#0e1420] w-full gap-[20px] gap-y-[30px] p-[15px] rounded-b-[10px] rounded-tr-[10px]">
        {isLoader ? (
          <div className="col-span-3">
            <Loader />
          </div>
        ) : (
          <>
            {active === "cars" &&
              cardata &&
              cardata.map((item) => (
                <EditCarCards
                  key={item.id}
                  item={item}
                  setNewRender={setNewRender}
                  setIsLoader={setIsLoader}
                  handleUploadClick={handleUploadClick}
                  setShowAlert={setShowAlert}
                  setAlertStatus={setAlertStatus}
                />
              ))}
            {active === "parts" &&
              partdata.map((item) => (
                <EditPartCards
                  key={item.id}
                  item={item}
                  setNewRender={setNewRender1}
                  setIsLoader={setIsLoader}
                  handleUploadClick={handleUploadClick}
                  setShowAlert={setShowAlert}
                  setAlertStatus={setAlertStatus}
                />
              ))}
            {active === "servs" &&
              servdata.map((item) => (
                <EditServCards
                  key={item.id}
                  item={item}
                  setNewRender={setNewRender2}
                  setIsLoader={setIsLoader}
                  handleUploadClick={handleUploadClick}
                  setShowAlert={setShowAlert}
                  setAlertStatus={setAlertStatus}
                />
              ))}
          </>
        )}
      </div>

      <MyAlert
        showAlert={showAlert}
        alertStatus={alertStatus}
        text={alertStatus ? "პროდუქტი წაიშალა" : "პროდუქტი ვერ წაიშალა!"}
      />
    </div>
  );
}
