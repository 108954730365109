import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { PiCaretDownLight } from "react-icons/pi";

export default function DropDownFilter({ data, servischecked, setDaynum, firstValue }) {
  const dropRef = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setDrop(false);
    }
  }
  const [drop, setDrop] = useState(false);
  const handleDrop = () => {
    setDrop((pre) => !pre);
  };

  const [dayValue, setDayValue] = useState(1);

    setDaynum(dayValue);

    useEffect(()=>{
      if(firstValue){
        setDayValue(firstValue)
      }
    },[firstValue])

  return (
    <div ref={dropRef}
      className={`shadow-md  rounded-[10px] duration-150 ${
        servischecked
          ? "shadow-[#3d7294] bg-[#0e1420] text-white"
          : "shadow-[#3d73943c] bg-[#0e14207d] text-gray-400"
      }`}
    >
      <div
        onClick={() => {
          handleDrop();
        }}
        className={`h-[50px]  px-[10px] flex items-center justify-between  ${
          servischecked ? "cursor-pointer" : "pointer-events-none"
        }`}
      >
        <p>{dayValue} დღე</p>
        <div className="flex items-center justify-center">
          <PiCaretDownLight
            className={`${drop ? "rotate-180 duration-200" : "duration-200"}`}
          />
        </div>
      </div>
      <div
        className={`showScrollbar w-full  px-[5px] overflow-y-scroll duration-150 ${
          drop ? "h-[120px] " : "h-0 pb-0"
        }`}
      >
        <ul>
          {data.map((item) => (
            <li
              key={item.id}
              onClick={() => {
                setDrop(false);
                setDayValue(item.id);
              }}
              className="text-[17px] px-[10px] duration-100 hover:bg-[#203f52] cursor-pointer mb-[5px]"
            >
              {item.id}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
