import { Link, useLocation } from "react-router-dom";

export default function UserRouterList({setDropLeftCol}) {
  const location = useLocation();
  return (
    <div className="bg-[#0e1420] shadow-md shadow-[#3d7294] text-white rounded-[10px] flex items-center gap-5  py-4">
      <ul className="flex flex-col w-full overflow-x-hidden">
        <Link to="addcar">
          <li onClick={()=>(setDropLeftCol(false))}
            className={`flex items-center gap-5 justify-between pr-2 h-[50px]  hover:bg-[#1a4865] duration-100 cursor-pointer ${
              location.pathname.split("/")[2] === "addcar" && "bg-[#1a4865]"
            }`}
          >
            <div className="flex items-center gap-5 h-full">
              <div
                className={`w-[2px] h-full  duration-100 ${
                  location.pathname.split("/")[2] === "addcar" && "bg-[#3d7294]"
                }`}
              ></div>

              <p className="">ავტომობილის დამატება</p>
            </div>
          </li>
        </Link>
        <Link to="addpart">
          <li onClick={()=>(setDropLeftCol(false))}
            className={`flex items-center gap-5 justify-between pr-2 h-[50px]  hover:bg-[#1a4865] duration-100 cursor-pointer ${
              location.pathname.split("/")[2] === "addpart" && "bg-[#1a4865]"
            }`}
          >
            <div className="flex items-center gap-5 h-full">
              <div
                className={`w-[2px] h-full  duration-100 ${
                  location.pathname.split("/")[2] === "addpart" &&
                  "bg-[#3d7294]"
                }`}
              ></div>

              <p>ნაწილის დამატება</p>
            </div>
          </li>
        </Link>
        <Link to="addservice">
          <li onClick={()=>(setDropLeftCol(false))}
            className={`flex items-center gap-5 justify-between pr-2 h-[50px]  hover:bg-[#1a4865] duration-100 cursor-pointer ${
              location.pathname.split("/")[2] === "addservice" && "bg-[#1a4865]"
            }`}
          >
            <div className="flex items-center gap-5 h-full">
              <div
                className={`w-[2px] h-full  duration-100 ${
                  location.pathname.split("/")[2] === "addservice" &&
                  "bg-[#3d7294]"
                }`}
              ></div>

              <p>სერვისის დამატება</p>
            </div>
          </li>
        </Link>
        <Link to="mystatements">
          <li onClick={()=>(setDropLeftCol(false))}
            className={`flex items-center gap-5 justify-between pr-2 h-[50px] group hover:bg-[#1a4865] duration-100 cursor-pointer  ${
              location.pathname.split("/")[2] === "mystatements" &&
              "bg-[#1a4865]"
            }`}
          >
            <div className="flex items-center gap-5 h-full">
              <div
                className={`w-[2px] h-full   duration-100 ${
                  location.pathname.split("/")[2] === "mystatements" &&
                  "bg-[#3d7294]"
                }`}
              ></div>

              <p>ჩემი განცხადებები</p>
            </div>
          </li>
        </Link>
        <Link to="transactions">
          <li onClick={()=>(setDropLeftCol(false))}
            className={`flex items-center gap-5 justify-between pr-2 h-[50px] group hover:bg-[#1a4865] duration-100 cursor-pointer  ${
              location.pathname.split("/")[2] === "transactions" &&
              "bg-[#1a4865]"
            }`}
          >
            <div className="flex items-center gap-5 h-full">
              <div
                className={`w-[2px] h-full   duration-100  ${
                  location.pathname.split("/")[2] === "transactions" &&
                  "bg-[#3d7294]"
                }`}
              ></div>

              <p>ტრანზაქციები</p>
            </div>
          </li>
        </Link>
        <Link to="profileediting">
          <li onClick={()=>(setDropLeftCol(false))}
            className={`flex items-center gap-5 justify-between pr-2 h-[50px] group hover:bg-[#1a4865] duration-100 cursor-pointer  ${
              location.pathname.split("/")[2] === "profileediting" &&
              "bg-[#1a4865]"
            }`}
          >
            <div className="flex items-center gap-5 h-full">
              <div
                className={`w-[2px] h-full   duration-100 ${
                  location.pathname.split("/")[2] === "profileediting" &&
                  "bg-[#3d7294]"
                }`}
              ></div>

              <p>პროფილის რედაქტირება</p>
            </div>
          </li>
        </Link>
      </ul>
    </div>
  );
}
