import React from "react";
import { Link } from "react-router-dom";
import { BsPencil, BsTrash } from "react-icons/bs";
import axiosClient from "../../axiosClient/axiosClient";

export default function AdminCarCheckComponent({ item, setNewRender, setShowAlert, handleAlertClose,
  setAlertStatus }) {

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const handelDelete = (Id) => {
    axiosClient
      .delete(`admin/car/${Id}`)
      .then((response) => {
        setNewRender(response);
        setShowAlert(true);
        setAlertStatus(true);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertStatus(false);
      })
      .finally(() => {
        handleAlertClose();
      });
  };

  return (
    <div className="flex flex-col gap-[10px] rounded-[10px] text-white shadow-md shadow-[#3d7294] bg-[#0e1420]">
      <div className="w-full flex gap-[30px] relative group">
        <div className="relative w-[100%] h-[170px] cursor-pointer">
          <Link to={`/car/${item.id}`}>
            <img
              onClick={() => {
                goToTopFast();
              }}
              src={`https://back.carfax.ge/${
                item && item.cars_imgs.length > 0 && item.cars_imgs[0].url
              }`}
              className="w-[100%] h-[100%] cursor-pointer object-cover rounded-t-[10px]"
              alt=""
            />
          </Link>
        </div>
        <div className="absolute bottom-0 w-full text-white px-[10px] pb-[15px]">
          <p className="text-[16px] cursor-pointer">
            {item.firm} {item.model} {item.year}
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center px-[10px] pb-[10px]">
        <Link
          to={`/adminPanel/adminAllCarsInfo/${item.id}`}
          className="flex items-center gap-[8px] border-[1px] rounded-[10px] px-2 h-[35px] cursor-pointer bg-[#5c5b5b] text-white hover:bg-[#707070] duration-100"
        >
          <p className="cursor-pointer">ჩასწორება</p>
          <BsPencil />
        </Link>
        <div
          onClick={() => handelDelete(item.id)}
          className="w-[35px] h-[35px] rounded-full flex items-center justify-center cursor-pointer bg-[red] text-white hover:bg-[#9a2c2c] hover:scale-105 duration-100"
        >
          <BsTrash />
        </div>
      </div>
    </div>
  );
}
