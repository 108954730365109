import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../photos/logo.png";

export default function ErrorPage() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/ErrorPage");
  });

  return (
    <div className="w-full h-[100vh] select-none gap-y-[30px] flex flex-col items-center justify-center text-white">
      <h1 className="text-[30px] ">გვერდი ვერ მოიძებნა!</h1>
      <img src={logo} alt="" className="w-[200px]" />
      <Link to="/">
        <h1 className="px-[15px] cursor-pointer hover:scale-95 duration-200 h-[50px] shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex items-center">
          დაბრუნდი მთავარ გვერდზე
        </h1>
      </Link>
    </div>
  );
}
