import { useEffect, useState } from "react";

export default function AddDescr({ allValues, setAllValues, name, firstValue }) {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (setAllValues) {
      setAllValues({ ...allValues, [name]: value });
    }
  }, [value]);

  const handelChangeValiue = (e) => {
    setValue(e.target.value);
  };

  useEffect(()=>{
    if(firstValue){
      setValue(firstValue)
    }
  },[firstValue])
  return (
    <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
      <p className="hmli text-[17px]">აღწერილობა</p>

      <div className="flex flex-col gap-[10px]">
        <p>ქართულად</p>
        <textarea
        value={value}
        onChange={(e) => handelChangeValiue(e)}
        placeholder="აღწერა"
          // name={name}
          className="w-full h-[140px] resize-none rounded-[10px] outline-none shadow-md shadow-[#3d7294] bg-[#1d263a] p-[10px] focus:shadow focus:shadow-[#3d7294] duration-100 typedmessages"
        />
      </div>
    </div>
  );
}
