import { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import SignIn from "./signIn";
import NewAccount from "./newAccount";
import ForgetPassword from "./forgetPassword";
import MyAlert from "../MyAlert";

export default function LoginRegister({
  setLogreg,
  handleLogreg,
  setShowAlert,
  setAlertStatus,
  handleAlertClose,
}) {
  const [showAlert, setShowAlertReg] = useState(false);
  const [alertStatus, setAlertStatusReg] = useState(false);

  const handleRegAlert = () => {
    setTimeout(() => {
      setShowAlertReg(false);
    }, 2200);
  };

  const outlogregedRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (
      outlogregedRef.current &&
      !outlogregedRef.current.contains(event.target)
    ) {
      setLogreg(false);
    }
  }

  const [active, setActive] = useState("signin");

  return (
    <div className="ChromePopUp w-[100%] fixed top-0 left-0 z-[50] flex  py-[50px] justify-center bg-[#000000c8] overflow-y-auto">
      <div
        ref={outlogregedRef}
        className="rounded-[15px] w-[500px] self-start bg-white"
      >
        <div className="px-[30px] py-[20px] border-b-[1px] flex justify-between items-center">
          <h1 className="text-[19px]">ავტორიზაცია</h1>
          <div
            className="w-[40px] h-[40px] rounded-full flex items-center justify-center  text-white duration-200 cursor-pointer hover:bg-[#000000c1] bg-black"
            onClick={handleLogreg}
          >
            <AiOutlineClose className="text-[23px]" />
          </div>
        </div>
        <div className="px-[30px] py-[20px] flex flex-col gap-y-[20px]">
          <div className="flex">
            <p
              className={`px-[8px] py-[10px] cursor-pointer border-b-[2px]  ${
                active === "signin"
                  ? "border-black text-black duration-100"
                  : "border-transparent text-gray-500 hover:text-black duration-100"
              }`}
              onClick={() => {
                setActive("signin");
              }}
            >
              შესვლა
            </p>
            <p
              className={`px-[8px] py-[10px] cursor-pointer border-b-[2px] ${
                active === "newacc"
                  ? " border-black text-black duration-100"
                  : " border-transparent text-gray-500 hover:text-black duration-100"
              }`}
              onClick={() => {
                setActive("newacc");
              }}
            >
              რეგისტრაცია
            </p>
          </div>

          {active === "signin" && (
            <SignIn
              active={active}
              setActive={setActive}
              handleLogreg={handleLogreg}
              setShowAlert={setShowAlert}
              setAlertStatus={setAlertStatus}
              handleAlertClose={handleAlertClose}
            />
          )}
          {active === "newacc" && (
            <NewAccount
              setActive={setActive}
              setShowAlertReg={setShowAlertReg}
              setAlertStatusReg={setAlertStatusReg}
              handleRegAlert={handleRegAlert}
            />
          )}
          {active === "forgetpassword" && <ForgetPassword />}
        </div>
      </div>
      <MyAlert
        showAlert={showAlert}
        alertStatus={alertStatus}
        text={
          alertStatus
            ? "რეგისტრაცია წარმატებით შესრულდა"
            : "შეიყვანე ინფორმაცია სწორად"
        }
      />
    </div>
  );
}
