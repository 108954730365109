import React from "react";
import { BsAt } from "react-icons/bs";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  return (
    <div
      className={`flex px-[100px] max-md:px-[50px] max-sm:px-[16px] py-7 items-center justify-between w-full max-[1300px]:flex-wrap max-[1300px]:gap-[20px] ${
        (location.pathname.split("/")[1] === "adminPanel" ||
        location.pathname.split("/")[1] === "ErrorPage" ||
          location.pathname.split("/")[1] === "admin") &&
        "hidden"
      }`}
    >
      <div className="flex items-center w-[30%] gap-[20px] max-[1300px]:w-full max-[1300px]:justify-center ">
        <div className="flex items-center gap-[2px]">
          <BsAt className="text-white text-[20px]" />
          <h1 className="text-base text-center  text-white">2023</h1>
        </div>
        <h1 className="text-base text-center text-white">
          ყველა უფლება დაცულია
        </h1>
      </div>
      <div className="flex max-[1300px]:w-full gap-4 items-center max-[1004px]:flex-col max-[1300px]:justify-center ">
        <p className="bg-white cursor-pointer leading-[normal]  py-[5px] px-5 rounded-[16px] text-base text-black text-center">
          კონფიდენციალურობის პოლიტიკა
        </p>
        <p className="bg-white cursor-pointer leading-[normal] min-w-[101px] py-[5px] px-5 rounded-[16px] text-base text-black text-center">
          მიწოდება
        </p>
        <p className="bg-white cursor-pointer leading-[normal] min-w-[174px] py-[5px] rounded-[16px] px-5 text-base text-black text-center">
          წესები და პირობები
        </p>
      </div>
    </div>
  );
};

export default Footer;
