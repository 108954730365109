import React, { useContext } from "react";
import { MdSpeed } from "react-icons/md";
import { FaGasPump } from "react-icons/fa";
import { TbManualGearbox } from "react-icons/tb";
import { FiEdit3 } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";

import { Link } from "react-router-dom";

import axiosClient from "../../axiosClient/axiosClient";
import { CurrencyContext } from "../../CurrencyContext/CurrencyContext";
import { RunningTypeContext } from "../../runningTypeContext/RunningTypeContext";

export default function AdminAllCarsInfo({ item, setNewRender, setIsLoading }) {
  const { userChooseCurrency } = useContext(CurrencyContext);
  const { runType } = useContext(RunningTypeContext);
  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const handelDelete = (Id) => {
    axiosClient.delete(`admin/car/${Id}`).then((response) => {
      setNewRender(response);
    });
  };

  return (
    <div className="flex flex-1 flex-col items-center justify-start w-full">
      <div className="relative flex flex-col gap-[13px] items-start justify-start w-full">
        <div className="absolute z-[1] top-[10px] px-[10px] flex items-start w-full justify-between">
          <Link to={`/adminPanel/adminAllCarsInfo/${item.id}`} className="">
            <div className=" bg-[#0e1420] text-white cursor-pointer hover:scale-105 duration-100  w-[40px] h-[40px] rounded-full flex items-center justify-center">
              <FiEdit3 />
            </div>
          </Link>
          {item.vip > 0 && (
            <div className="bg-[red] h-[30px] text-white px-[10px] flex items-center rounded-full">
              VIP
            </div>
          )}
          <div
            onClick={() => {
              handelDelete(item.id);
              setIsLoading(true);
            }}
            className="bg-[#c92929]  text-white cursor-pointer hover:scale-105 duration-100  w-[40px] h-[40px] rounded-full flex items-center justify-center"
          >
            <BsTrash />
          </div>
        </div>

        <div className="h-[180px] relative w-full">
          <Link to={`/car/${item.id}`}>
            <img
              onClick={() => {
                goToTopFast();
              }}
              className="h-full m-auto object-cover cursor-pointer rounded-[10px] w-full"
              src={
                item.cars_imgs &&
                item.cars_imgs[0] &&
                `https://back.carfax.ge/${item.cars_imgs[0].url}`
              }
              alt=""
            />
          </Link>
          <div className="absolute bottom-[7%] flex flex-col  right-[0] text-white gap-y-[10px]">
            <h1 className=" bg-[green] cursor-pointer  min-w-[80px] pl-[5px] h-[30px] self-end flex items-center justify-center right-[0] rounded-l-[30px] text-center text-white text-[14px]">
              {item.price && item.price && `${item.price}`}{" "}
              {userChooseCurrency === "USD" ? "$" : "₾"}
            </h1>
            {item.status === 1 && item.checked === 0 && (
              <p className="px-[15px] bg-[#353535] rounded-l-[30px] h-[30px] text-[14px] flex items-center">
                გაგზავნილია TL-თან
              </p>
            )}
            {item.checked === 1 && (
              <p className="px-[15px] bg-[#353535] rounded-l-[30px] h-[30px] text-[14px] flex items-center">
                ვერიფიცირებულია
              </p>
            )}
          </div>
        </div>
        <div className="flex flex-row items-center justify-between w-full">
          <div className="flex flex-col items-start justify-start">
            <p className="text-center text-white">
              {item.firm && item.firm && `${item.firm}`}
            </p>
            <p className="text-center text-white">
              {item.model && item.model && `${item.model}`}
            </p>
          </div>
          <h1 className="bg-[red] cursor-pointer leading-[normal] h-[24px] flex items-center pb-[1px] px-4 rounded-full text-center text-white text-[14px]">
            {item.year && item.year && `${item.year}`}
          </h1>
        </div>

        <div className="flex flex-row items-start justify-between w-full">
          <div className="flex items-center">
            <div className="flex gap-[7px] items-center ">
              <FaGasPump className="text-white text-[13px]" />
              <p className="text-[13px] text-center text-white">
                {item.petrol && item.petrol && `${item.petrol}`}
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex flex-row gap-[5px] items-center justify-between ">
              <div className="flex items-center gap-[5px]">
                <MdSpeed className="text-white  text-[15px]" />
                <p className="text-[13px] text-center text-white">
                  {runType === "კმ"
                    ? item.name === "კმ"
                      ? `${item.metersRun} კმ`
                      : `${(item.metersRun * 1.609).toFixed(0)} კმ`
                    : item.name === "მილი"
                    ? `${item.metersRun} მილი`
                    : `${(item.metersRun * 0.6214).toFixed(0)} მილი`}
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex gap-1.5 items-center ">
              <TbManualGearbox className="text-white  text-[14px]" />
              <p className="text-[13px] text-center text-white">
                {item.transmission &&
                  item.transmission &&
                  `${item.transmission}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
