import React, { useContext, useEffect, useState } from "react";
import {
  AiOutlineEye,
  AiOutlinePrinter,
  AiOutlineShareAlt,
} from "react-icons/ai";
import { PiEngineBold } from "react-icons/pi";
import {
  BsFillCalendarDayFill,
  BsPhoneLandscape,
  BsSpeedometer2,
  BsCardImage,
  BsFillCameraFill,
} from "react-icons/bs";
import { GiGearStick, GiCarSeat, GiSteeringWheel } from "react-icons/gi";
import { IoIosColorPalette } from "react-icons/io";
import { FaGasPump } from "react-icons/fa";
import { BiCylinder } from "react-icons/bi";
import { AiFillCar } from "react-icons/ai";

import chasisicon from "../../../photos/download.png";
import { BiTimeFive } from "react-icons/bi";
import { TbSquareToggle } from "react-icons/tb";
import { ImLocation } from "react-icons/im";

import InfoItem from "./InfoItem";
import MainInfo from "./MainInfo";
import SecondSlider from "../mainPage/SecondSlider";
import FirstSlider from "../mainPage/FirstSlider";

import ProductSlider from "./ProductSlider";
import { useParams } from "react-router-dom";
import axiosClient from "../../axiosClient/axiosClient";
import Loader from "../../loader/loader";

const Car = () => {
  const params = useParams();

  const { id } = params;
  const [data, setData] = useState({});
  useEffect(() => {
    axiosClient
      .get(`front/cars/${id}`)
      .then(({ data }) => {
        setData(data);
        setIsLoader(false);
      })
      .catch((error) => {});
  }, [id]);
  const [active, setActive] = useState("characteristics");
  const [isLoader, setIsLoader] = useState(true);
  const [infoItem, setInfoItem] = useState([]);

  useEffect(() => {
    setInfoItem([
      {
        id: 1,
        title: "ძრავი",
        text: data.engine !== null && data.engine,
        icon: <PiEngineBold />,
      },
      {
        id: 2,
        title: "წელი",
        text: data.year !== null && data.year,
        icon: <BsFillCalendarDayFill />,
      },
      {
        id: 3,
        title: "გადაცემათა კოლოფი",
        text: data.transmission !== null && data.transmission,
        icon: <GiGearStick />,
      },
      {
        id: 4,
        title: "ფერი",
        text: data.color !== null && data.color,
        icon: <IoIosColorPalette />,
      },
      {
        id: 5,
        title: "საწვავის ტიპი",
        text: data.petrol !== null && data.petrol,
        icon: <FaGasPump />,
      },
      {
        id: 6,
        title: "სალონის ფერი",
        text: data.salonColor !== null && data.salonColor,
        icon: <GiCarSeat />,
      },
      {
        id: 7,
        title: "წამყვანი თვლები",
        text: data.pullingWheels !== null && data.pullingWheels,
        icon: chasisicon,
      },
      {
        id: 8,
        title: "მულტიმედია",
        text: data.multimedia !== null && data.multimedia ? "კი" : "არა",
        icon: <BsPhoneLandscape />,
      },
      {
        id: 9,
        title: "ცილინდრი",
        text: data.cylinders !== null && data.cylinders,
        icon: <BiCylinder />,
      },
      {
        id: 10,
        title: "ლუქი",
        text: data.Hatch !== null && data.Hatch ? "კი" : "არა",
        icon: <AiFillCar />,
      },
      {
        id: 11,
        title: "გარბენი",
        text: data.metersRun !== null && data.metersRun,
        icon: <BsSpeedometer2 />,
      },
      {
        id: 12,
        title: "პანორამა",
        text: data.Panorama !== null && data.Panorama ? "კი" : "არა",
        icon: <BsCardImage />,
      },
      {
        id: 13,
        title: "საჭე",
        text: data.weel !== null && data.weel,
        icon: <GiSteeringWheel />,
      },
      {
        id: 14,
        title: "კამერა",
        text: data.camera !== null && data.camera ? "კი" : "არა",
        icon: <BsFillCameraFill />,
      },
      {
        id: 15,
        title: "მანქანის ტიპი",
        text: data.vehicleType !== null && data.vehicleType,
        icon: <AiFillCar />,
      },
      {
        id: 16,
        title: "ტყავის სავარძლები",
        text: data.leather !== null && data.leather,
        icon: <GiCarSeat />,
      },
    ]);
  }, [data]);

  return (
    <div className="flex flex-col py-[150px] max-sm:pt-[110px] px-[190px] max-xl:px-[100px] max-md:px-[50px] max-sm:px-[16px]">
      {isLoader ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-y-[20px]">
          <div className="flex items-start w-full py-2 justify-between gap-y-[10px] max-lg:flex-col">
            <div className="flex items-center gap-[20px]">
              {data && data.vip > 0 && (
                <h1 className="bg-[red] h-[30px] flex items-center pt-[3px] px-3 rounded-full text-[15px] text-center text-white">
                  {data && data.vip && "VIP"}
                </h1>
              )}
              <h1 className=" text-white text-lg">
                {data && data.firm} {data && data.model}
              </h1>
              <div className="flex gap-[3px] items-center">
                <AiOutlineEye className="text-white text-[18px]" />
                <h1 className="text-[14px] text-white">{data && data.view}</h1>
              </div>
              <div className="flex items-center justify-center gap-[3px] ">
                <BiTimeFive className="text-white text-[18px]" />

                <h1 className="text-[14px] text-white">
                  {data && data.createdAt.split("T")[0].replace(/-/g, " ")}
                </h1>
              </div>
            </div>
            <div className="flex items-center gap-[30px]">
              <div className="bg-red-600 flex flex-row  items-center justify-start  rounded-[15px]">
                <div className="bg-white flex flex-col h-[31px] items-center justify-center rounded-full w-[31px]">
                  <ImLocation className="text-[red]" />
                </div>
                <p className="text-[17px] text-white px-2.5">
                  {data && data.country}
                </p>
              </div>
              <div className="flex items-center gap-[15px]">
                <TbSquareToggle className="text-white text-[18px] cursor-pointer" />
                <AiOutlinePrinter className="text-white text-[18px] cursor-pointer" />
                <AiOutlineShareAlt className="text-white text-[18px] cursor-pointer" />
              </div>
            </div>
          </div>

          <div className="w-full h-auto flex gap-[20px] max-lg:flex-col">
            <div className="w-[70%] max-xl:w-[65%] max-lg:w-full h-auto flex flex-col gap-[50px]">
              <ProductSlider
                data={data && data.cars_imgs}
                datavid={data && data.cars_videos}
              />
            </div>
            <div className="flex w-[30%] max-xl:w-[45%] max-lg:w-auto max-lg:self-start">
              <MainInfo data={data} />
            </div>
          </div>

          <div className="flex gap-[37px] items-start justify-start w-full max-sm:flex-col">
            <div className="flex md:flex-1 flex-col gap-[15px] items-center justify-start  mt-0.5 max-sm:flex-row">
              <p
                onClick={() => {
                  setActive("characteristics");
                }}
                className={` cursor-pointer leading-[normal] w-[170px] max-tiny:w-[160px] h-[40px] flex items-center justify-center rounded-[20px] text-center text-white text-[12px] duration-100 ${
                  active === "characteristics"
                    ? "scale-105 bg-[#208A16]"
                    : "scale-100 bg-[#FD022F]"
                }`}
              >
                დამატებითი ინფორმაცია
              </p>
              <p
                onClick={() => {
                  setActive("description");
                }}
                className={` cursor-pointer leading-[normal] w-[170px] max-tiny:w-[160px] h-[40px] flex items-center justify-center rounded-[20px] text-center text-white text-[15px] duration-100 ${
                  active === "description"
                    ? "scale-105 bg-[#208A16]"
                    : "scale-100 bg-[#FD022F]"
                }`}
              >
                დეტალური აღწერა
              </p>
            </div>
            <div className="flex flex-col gap-5 justify-start w-[calc(100%-170px-37px)] max-sm:w-full">
              <div
                className={` w-full ${
                  active === "characteristics" ? "flex" : "hidden"
                }`}
              >
                <div className="grid grid-cols-4 max-2xl:grid-cols-3 max-lg:grid-cols-2 w-full gap-[10px]">
                  {data &&
                    infoItem.map((item) => (
                      <InfoItem key={item.id} item={item} data={data} />
                    ))}
                </div>
              </div>

              <div
                className={` ${
                  active === "description" ? "flex flex-col" : "hidden"
                }`}
              >
                <p className={`text-white`}>{data && data.description}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <SecondSlider />
      <FirstSlider />
    </div>
  );
};

export default Car;
