import React from "react";
import { FiEdit3, FiSettings } from "react-icons/fi";
import { BsGeoAlt, BsTrash } from "react-icons/bs";

import { Link } from "react-router-dom";
import axiosClient from "../../axiosClient/axiosClient";
export default function AdminAllServicesComponent({ item, setNewRender }) {
  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const handelDelete = (Id) => {
    axiosClient.delete(`admin/servcenters/${Id}`).then((response) => {
      setNewRender(response);
    });
  };
  return (
    <div className="flex flex-1 flex-col items-center justify-start w-full">
      <div className="flex flex-col  gap-y-[5px] items-start justify-start w-full relative">
        <div className="flex items-center w-full justify-between">
          <Link to={`/adminPanel/adminAllServiceInfo/${item.id}`} className="">
            <div className=" bg-white text-black cursor-pointer hover:scale-105 duration-100  w-[80px] h-[30px] rounded-full flex items-center justify-center">
              <FiEdit3 />
            </div>
          </Link>

          <div
            onClick={() => {
              handelDelete(item.id);
            }}
            className="bg-[#c92929] text-white cursor-pointer hover:scale-105 duration-100 top-[10px] right-[10px] w-[80px] h-[30px] rounded-full flex items-center justify-center"
          >
            <BsTrash />
          </div>
        </div>

        <div className="h-[180px] relative w-full">
          <Link to={`/serv/${item.id}`}>
            <img
              onClick={() => goToTopFast()}
              clasName="h-full m-auto object-cover rounded-[10px] w-full"
              src={
                item.servcenters_imgs &&
                item.servcenters_imgs[0] &&
                `https://back.carfax.ge/${item.servcenters_imgs[0].url}`
              }
              className="w-full h-full object-cover"
              alt=""
            />
          </Link>
          <div className="absolute flex flex-row gap-1.5 items-center justify-between left-0 px-[5px] top-[5px] w-full">
            {item.vip > 0 && (
              <p className="bg-[red] text-white  justify-center p-1 px-[10px] rounded-full text-[15px] text-center">
                VIP
              </p>
            )}
            {item.servcenterName && (
              <p className="bg-[#000000b9] text-white  justify-center p-1 px-[10px] rounded-full text-[15px] text-center">
                {item.servcenterName}
              </p>
            )}
          </div>
        </div>
        {item.location && (
          <div className="flex items-center justify-center gap-[5px]  rounded-full ">
            <BsGeoAlt className="text-[12px] text-center text-white" />
            <p className="text-[14px] text-white w-full text-ellipsis line-clamp-1">
              {item.location.map((item1, index) => {
                return `${item1.city} ${
                  index < item.location.length - 1 ? "," : ""
                } `;
              })}
            </p>
          </div>
        )}

        {item.services && (
          <div className="flex gap-[3px] items-center w-full">
            <FiSettings className="text-white text-[14px]" />
            <p className="text-[14px] text-white w-full text-ellipsis line-clamp-1">
              {item.services.map((item1, index) => {
                return `${item1.text} ${
                  index < item.services.length - 1 && ","
                } `;
              })}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
