import { React, useEffect, useState } from "react";
import Service from "./Service";
import { ScreenWidth } from "../../ScreenWidth";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { Autoplay } from "swiper/modules";
import Loader from "../../loader/loader";
import axiosClient from "../../axiosClient/axiosClient";

const ServiceSection = () => {
  const screenWidth = ScreenWidth();
  const [slidesPerView, setSlidesPerView] = useState(6);
  const [servsData, setServsData] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    axiosClient
      .get("front/Servcenters")
      .then((res) => {
        setServsData(res.data.data);
      })
      .finally(() => {
        setLoader(false);
      });
  }, []);

  useEffect(() => {
    if (screenWidth < 1540) {
      if (screenWidth < 1300) {
        if (screenWidth < 900) {
          if (screenWidth < 770) {
            if (screenWidth < 500) {
              setSlidesPerView(1);
            } else {
              setSlidesPerView(2);
            }
          } else {
            setSlidesPerView(2);
          }
        } else {
          setSlidesPerView(3);
        }
      } else {
        setSlidesPerView(4);
      }
    } else {
      setSlidesPerView(5);
    }
  }, [screenWidth]);

  return (
    <div className="flex flex-col py-10 gap-5">
      <h1 className="text-white text-[25px]">სერვისები</h1>
      <div className="flex w-full gap-8">
        {loader ? (
          <Loader />
        ) : (
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={30}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            className="w-full"
          >
            {servsData.map((item) => (
              <SwiperSlide key={item.id}>
                <Service item={item}/>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default ServiceSection;
