import { useEffect, useState } from "react";
import DropDownFilter from "../../DropownFilter";
import { useDatasContext } from "../../DatasContext/DatasContext";
import { useContext } from "react";
import { CurrencyContext } from "../../CurrencyContext/CurrencyContext";

export default function AddValuableServs({
  allValues,
  setAllValues,
  name,
  firstValue,
}) {
  const { currency, userChooseCurrency } = useContext(CurrencyContext);

  const [servischecked, setServischecked] = useState(false);
  const handleChecked = () => {
    setServischecked((pre) => !pre);
  };
  const [daynum, setDaynum] = useState(1);

  useEffect(() => {
    if (setAllValues) {
      if (servischecked) {
        setAllValues({ ...allValues, [name]: daynum });
      } else {
        setAllValues({ ...allValues, [name]: 0 });
      }
    }
  }, [servischecked, daynum]);

  const { days } = useDatasContext();

  let OneDayVip = 3;

  const sum = (OneDayVip * daynum).toFixed(2);

  useEffect(() => {
    if (firstValue) {
      setServischecked(firstValue);
    }
  }, [firstValue]);

  return (
    <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:overflow-x-scroll">
      <p className="hmli text-[17px]">ფასიანი სერვისები</p>
      <div
        className={`overflow-x-auto flex justify-between items-center border-b-[1px] py-[20px] min-w-[530px] rounded-[10px] duration-150 ${
          !servischecked && "bg-[#80808083] px-[10px] border-b-[gray]"
        }`}
      >
        <div className="flex items-center gap-[30px]">
          <div className="flex items-center gap-[30px]">
            <input
              onChange={() => {
                handleChecked();
              }}
              type="checkbox"
              checked={servischecked ? true : false}
              className={`w-[20px] h-[20px] cursor-pointer`}
            />

            <p
              className={` h-[35px] flex items-center w-[110px] justify-center rounded-[10px]  shadow   duration-150 ${
                servischecked
                  ? "bg-[#3d7294] shadow-[#3d7294] text-white"
                  : "bg-[#3d739457] shadow-[#3d73948f] text-gray-400 pointer-events-none"
              }`}
            >
              VIP
            </p>

            <div
              className={`duration-150 ${
                servischecked
                  ? "text-white"
                  : "text-gray-400 pointer-events-none"
              }`}
            >
              <p>1 დღე</p>
              <p className="text-[17px]">
                {userChooseCurrency === "USD"
                  ? `${Math.round(OneDayVip / currency.rate)} $`
                  : `${OneDayVip} ₾`}
              </p>
            </div>
          </div>
          <div className="w-[130px]">
            <DropDownFilter
              data={days}
              servischecked={servischecked}
              firstValue={firstValue}
              setDaynum={setDaynum}
            />
          </div>
        </div>
        <div
          className={`flex flex-col items-end ${
            servischecked ? "text-white" : "text-gray-400 pointer-events-none"
          }`}
        >
          <p>ჯამური ფასი</p>
          <p className="text-[18px]">
            {userChooseCurrency === "USD"
              ? `${Math.round(sum / currency.rate)} $`
              : `${sum} ₾`}
          </p>
        </div>
      </div>
    </div>
  );
}
