import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { AiOutlineClose } from "react-icons/ai";
import { BsImage } from "react-icons/bs";
export default function AddPhoto({
  name,
  handleNewPhotos = null,
  makeEmpty,
  handleMakeEmpty,
  setSelectedPhotoIndex = null,
  inputName,
}) {
  const location = useLocation();
  const [multipleFiles, setMultipleFiles] = useState([]);
  const inputElement = useRef(null);

  const handleMultipleFilesChange = (event) => {
    let tmpImageList = [];
    for (let i = 0; i < event.target.files.length; i++) {
      tmpImageList.push(event.target.files[i]);
    }
    setMultipleFiles((prev) => tmpImageList);
  };

  const HandleRemovePhoto = (index) => {
    let tmp = [...multipleFiles];
    tmp.splice(index, 1);
    setMultipleFiles(tmp);
  };

  const handleOpenInput = () => {
    inputElement.current.click();
  };

  useEffect(() => {
    if (multipleFiles != null && handleNewPhotos !== null) {
      handleNewPhotos(multipleFiles);
    }
  }, [multipleFiles]);

  useEffect(() => {
    if (makeEmpty) {
      setMultipleFiles([]);
      handleMakeEmpty(false);
    }
  }, [makeEmpty]);

  return (
    <div className="grid grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-sm:grid-cols-2 gap-4 items-center">
      {multipleFiles ? (
        multipleFiles.map((single, index) => {
          let fileUrl = URL.createObjectURL(single);
          let fileName = single.name;
          return (
            <div
              key={index}
              className={` group shadow-md shadow-[#3d7294] bg-[#182540] relative flex items-center
                                            max-sm:h-auto ${
                                              location.pathname.split(
                                                "/"
                                              )[2] === "profileediting"
                                                ? "w-full h-[60px] rounded-full"
                                                : "w-full h-[150px] rounded-[10px]"
                                            }`}
            >
              <div className="flex items-center w-full h-full gap-2 max-sm:flex-col">
                <img
                  src={fileUrl}
                  className={`w-full h-full object-cover  ${
                    location.pathname.split("/")[2] === "profileediting"
                      ? " rounded-full"
                      : " rounded-[6px]"
                  }`}
                  alt="#"
                />
              </div>
              <div
                className="rounded-full absolute group-hover:flex max-lg:flex top-[7px] right-[10px] w-8 h-8 bg-[#21335a] cursor-pointer hidden items-center justify-center"
                onClick={() => {
                  HandleRemovePhoto(index);
                }}
              >
                <AiOutlineClose className="text-gray-100 text-2xl" />
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
      <div className="my-1 ">
        <div
          onClick={handleOpenInput}
          className={`bg-[#182540] text-[40px] hover:scale-105 duration-100 border-dashed border-4  flex-col gap-2
                             border-gray-300 flex items-center justify-center cursor-pointer ${
                               location.pathname.split("/")[2] ===
                               "profileediting"
                                 ? "h-[60px] w-full rounded-full"
                                 : "h-[150px] w-full rounded-[10px]"
                             }`}
        >
          <BsImage />
        </div>
        <input
          name={inputName}
          ref={inputElement}
          multiple
          className="hidden"
          type="file"
          onChange={handleMultipleFilesChange}
          accept={["image/png image/jpg image/jpeg image/bmp image/webp"]}
        />
      </div>
    </div>
  );
}
