export default function MyAlert({showAlert, alertStatus, text}) {
  return (
    <div
      className={`z-[20] fixed flex min-h-[80px] items-center rounded-[5px]  shadow  text-[17px] text-white left-[50%] translate-x-[-50%] duration-100 ${alertStatus ? "bg-[#174717] shadow-[#386c38]" : "bg-[#821616] shadow-[#993939]"} ${
        showAlert ? "top-[30px]" : "top-[-90px]"
      }`}
    >
      <div className={`w-[10px] min-h-[80px] rounded-l-[5px] ${alertStatus ? "bg-[#229722]" : "bg-[#d32b2b]"}`}>
        
      </div>
      <p className="px-[20px] flex items-center text-center">{text}</p>
    </div>
  );
}
