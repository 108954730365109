import React from "react";
import FirstSection from "./FirstSection";
import FirstSlider from "./FirstSlider";
import SecondSlider from "./SecondSlider";
import ServiceSection from "./ServiceSection";

const MainPage = () => {

  return (
    <>
      <FirstSection />
      <div className="px-[190px] max-xl:px-[100px] max-md:px-[50px] max-sm:px-[16px]">
        <SecondSlider/>
        <FirstSlider/>
        <ServiceSection />
      </div>
    </>
  );
};

export default MainPage;
