import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { PiCaretDownLight } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";

export default function PartsDropDown({ data, allValues, setAllValues, name, firstValue }) {
  const dropRef = useRef(null);
  const [value, setValue] = useState("აირჩიე");

  useEffect(() => {
    if(setAllValues){
      setAllValues({...allValues, [name]:value})
    }
  },[value])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setDrop(false);
    }
  }

  const [drop, setDrop] = useState(false);
  const handleDrop = () => {
    setDrop((pre) => !pre);
  };


  useEffect(()=>{
    if(firstValue){
      setValue(firstValue)
    }
  },[firstValue])

  return (
    <div ref={dropRef} className="relative  h-full">
      <div
        onClick={() => {
          handleDrop();
        }}
        className={`h-full cursor-pointer px-[10px] flex items-center justify-between shadow-md shadow-[#3d7294] bg-[#0e1420] text-white ${
          drop ? " rounded-t-[10px]" : "rounded-[10px] "
        } `}
      >
        <div className="flex items-center gap-[10px]">
          <p
            className={`${
              value !== "აირჩიე" ? "text-[18px]" : "text-[15px] text-gray-400"
            }`}
          >
            {value}
          </p>
        </div>
        <div className="flex items-center justify-center gap-[10px]">
          {value !== "აირჩიე" && (
            <RxCross2
              className=""
              onClick={() => {
                setValue("აირჩიე");
              }}
            />
          )}
          <PiCaretDownLight
            className={`${drop ? "rotate-180 duration-200" : "duration-200"}`}
          />
        </div>
      </div>

      <div
        className={`showScrollbar absolute z-[1] w-full  max-h-[300px] shadow-md shadow-[#3d7294] bg-[#0e1420] rounded-b-[10px] overflow-y-scroll  ${
          drop ? "flex" : "hidden"
        }`}
      >
        <ul className="w-full">
          {data &&
            data.map((item) => (
              <li
                key={item.id}
                className="px-[10px] w-full duration-100 mb-[5px] flex flex-col gap-y-[5px]"
              >
                <div
                  onClick={() => {
                    setValue(item.part1);
                    setDrop(false)
                  }}
                  className="text-[17px] flex items-center gap-[10px]"
                >
                  <p className="hover:bg-[#203f52] bg-[#158bce] rounded-[5px] cursor-pointer w-full px-[10px]">
                    {item.part1}
                  </p>
                </div>
                <div
                  className={`px-[20px] flex flex-col gap-y-[5px] overflow-hidden duration-100`}
                >
                  {(item.partIn1 || []).map((item1) => (
                    <div
                      key={item1.id}
                      className=" flex flex-col gap-[5px] "
                    >
                      <p
                        onClick={() => {
                          setValue(item1.part2);
                          setDrop(false)
                        }}
                        className="hover:bg-[#294e65] px-[10px] bg-[#1c3e54] rounded-[5px] cursor-pointer duration-100"
                      >
                        {item1.part2}
                      </p>
                      <div
                        className={`px-[20px] flex flex-col gap-y-[5px] overflow-hidden duration-100`}
                      >
                        {(item1.partIn2 || []).map((item2) => (
                          <p
                            key={item2.id}
                            onClick={() => {
                              setValue(item2.part3);
                              setDrop(false)
                            }}
                            className="hover:bg-[#203f52] bg-[#266389] rounded-[5px] cursor-pointer px-[10px] duration-100"
                          >
                            {item2.part3}
                          </p>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
