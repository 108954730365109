import React, { useEffect, useState } from "react";

import Loader from "../../loader/loader";
import axiosClient from "../../axiosClient/axiosClient";
import AdminCarCheckComponent from "./AdminCarCheckComponent";
import { BsSearch } from "react-icons/bs";
import MyAlert from "../../MyAlert";

export default function AdminCarCheck() {
  const [isLoading, setIsLoading] = useState(true);
  const [newRender, setNewRender] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [carsForTlCheck, setCarsForTlCheck] = useState([]);
  const [fulldata, setFulldata] = useState();

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`admin/carCheck`)
      .then((res) => {
        setCarsForTlCheck(res.data.data);
        setFulldata(res.data.length);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [newRender]);

  const handleAlertClose = () => {
    goToTopFast();
    setTimeout(() => {
      setShowAlert(false);
    }, 2200);
  };

  const [runType, setRunType] = useState(1);

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  return (
    <div className="max-md:ml-0">
      <div className="flex flex-col  justify-between items-center w-full gap-y-[20px]">
        <h1 className="hidden max-lg:flex text-white text-[20px]">
          მანქანები ვერიფიკაციისთვის
        </h1>
        <div className="w-full flex items-center gap-[20px] max-md:flex-col max-md:items-start">
          <p className="text-white shadow shadow-[#325063] bg-[black] self-start h-[40px] px-[20px] flex items-center rounded-[10px] duration-200">
            განსახილველია {fulldata} მანქანა
          </p>
          <div className="flex justify-between h-[40px] w-[300px] shadow shadow-[#325063] px-[3px] bg-[black] rounded-[10px] items-center">
            <input
              className="outline-none w-[calc(100%-51px)] bg-transparent text-[white] pl-[5px]"
              placeholder="ვერიფ. მანქანების ძიება..."
            />
            <div className="w-[40px] h-[35px] text-[18px] cursor-pointer rounded-[10px] bg-white flex justify-center items-center">
              <BsSearch />
            </div>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="w-full   gap-[30px] grid grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1">
            {(carsForTlCheck || []).map((item) => (
              <AdminCarCheckComponent
                key={item.id}
                item={item}
                setNewRender={setNewRender}
                setIsLoading={setIsLoading}
                handleAlertClose={handleAlertClose}
                setShowAlert={setShowAlert}
                setAlertStatus={setAlertStatus}
              />
            ))}
          </div>
        )}
      </div>

      <MyAlert
        showAlert={showAlert}
        alertStatus={alertStatus}
        text={alertStatus ? "მანქანა წაიშალა" : "ვერ წაიშალა!"}
      />
    </div>
  );
}
