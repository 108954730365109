import React, { useEffect, useState } from "react";
import UserCard from "./UserCard";
import Loader from "../../loader/loader";
import axiosClient from "../../axiosClient/axiosClient";

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [newRender, setNewRender] = useState();
  const [usersAccs, setUsersAccs] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`admin/users`)
      .then((res) => {
        setUsersAccs(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [newRender]);

  return (
    <div className="max-md:ml-0 ">
      <h1 className="hidden w-full mb-[10px] justify-center max-lg:flex text-white text-[20px]">
          მომხმარებლები
        </h1>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-4 max-2xl:grid-cols-3 gap-4 max-xl:grid-cols-2 max-lg:grid-cols-3 max-sm:grid-cols-2 max-tiny:grid-cols-1  ">
          {(usersAccs || []).map((item) => (
            <UserCard key={item.id} item={item} setNewRender={setNewRender}/>
          ))}
        </div>
      )}
    </div>
  );
};

export default Users;
