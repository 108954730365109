import { useEffect, useRef, useState } from "react";
import { PiCaretDownLight } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";

export default function DropDownAbsolute({ data, allValues, setAllValues, name, firstValue }) {
  const [value, setValue] = useState("აირჩიე");
  const dropRef = useRef(null);

  useEffect(() => {
    if(setAllValues){
      setAllValues({...allValues, [name]:value})
    }
  },[value])

 
  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setDrop(false);
    }
  }

  const [drop, setDrop] = useState(false);
  const handleDrop = () => {
    setDrop((pre) => !pre);
  };

  // engines

  const engine = [];
  for (let engi = 0.1; engi < 13.0; engi += 0.1) {
    engine.push(engi);
  }

  // engines

  // year

  const year = [];
  for (let yearr = 2024; yearr > 1949; yearr -= 1) {
    year.push(yearr);
  }

  // year

  useEffect(()=>{
    if(firstValue){
      setValue(firstValue)
    }
  },[firstValue])

  return (
    <div ref={dropRef} className="relative  h-full">
      <div
        onClick={() => {
          handleDrop();
        }}
        className={`h-full cursor-pointer px-[10px] flex items-center justify-between shadow-md shadow-[#3d7294] bg-[#0e1420] text-white ${
          drop ? " rounded-t-[10px]" : "rounded-[10px] "
        } `}
      >
        <div className="flex items-center gap-[10px]">
          <p
            className={`select-none ${
              value !== "აირჩიე" ? "text-[18px]" : "text-[15px] text-gray-400"
            }`}
          >
            {value}
          </p>
        </div>
        <div className="flex items-center justify-center gap-[10px]">
          {value !== "აირჩიე" && (
            <RxCross2
              className=""
              onClick={() => {
                setValue("აირჩიე");
              }}
            />
          )}
          <PiCaretDownLight
            className={`${drop ? "rotate-180 duration-200" : "duration-200"}`}
          />
        </div>
      </div>
      <div
        className={`showScrollbar absolute z-[1] w-full px-[10px] max-h-[200px] shadow-md shadow-[#3d7294] bg-[#0e1420] rounded-b-[10px] overflow-y-scroll  ${
          drop ? "flex" : "hidden"
        }`}
      >
        <ul className="w-full">
          {data === "engine" &&
            engine.map((engi, index) => (
              <p
                className="select-none cursor-pointer hover:bg-[#203f52] duration-100"
                onClick={() => {
                  setDrop(false);
                  setValue(engi.toFixed(1));
                }}
                key={index}
              >
                {engi.toFixed(1)}
              </p>
            ))}
          {data === "year" &&
            year.map((yearr, index) => (
              <p
                className="select-none cursor-pointer hover:bg-[#203f52] duration-100"
                onClick={() => {
                  setDrop(false);
                  setValue(yearr.toFixed(0));
                }}
                key={index}
              >
                {yearr.toFixed(0)}
              </p>
            ))}
          {data &&
            data !== "engine" &&
            data !== "year" &&
            data.map((item) => (
              <li key={item.id} className="w-full duration-100 select-none">
                <div
                  onClick={() => {
                    setDrop(false);
                    setValue(item.text);
                  }}
                  className="text-[17px] flex items-center gap-[10px]"
                >
                  <p className="hover:bg-[#203f52] cursor-pointer w-full px-[10px]">
                    {item.text}
                  </p>
                </div>
                <div className="px-[10px] flex flex-col">
                  {(item.cities || []).map((item1) => (
                    <p
                      key={item1.id}
                      onClick={() => {
                        setDrop(false);
                        setValue(item1.city);
                      }}
                      className="hover:bg-[#203f52] cursor-pointer px-[10px] duration-100"
                    >
                      {item1.city}
                    </p>
                  ))}
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
