import { useEffect, useRef, useState } from "react";
import { PiCaretDownLight } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";

export default function MakeModelDropDown({ data, setTest1,  allValues, setAllValues, name, firstValue }) {
  const [value, setValue] = useState("აირჩიე");

  const dropRef = useRef(null);

  useEffect(() => {
    if(setAllValues){
      setAllValues({...allValues, [name]:value})
    }
  },[value])


  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setDrop(false);
    }
  }

  const [drop, setDrop] = useState(false);
  const handleDrop = () => {
    setDrop((pre) => !pre);
  };

  

  const handelClick = (item) => {
    setValue(item.make);
    if (setTest1) {
      setTest1(item.models);
    }
  };

  useEffect(()=>{
    if(firstValue){
      setValue(firstValue)
      setAllValues({...allValues, [name]:firstValue})
    }
  },[firstValue])

  return (
    <div ref={dropRef} className="relative  h-full">
      <div
        onClick={() => {
          handleDrop();
        }}
        className={`h-full cursor-pointer px-[10px] flex items-center justify-between shadow-md shadow-[#3d7294] bg-[#0e1420] text-white ${
          drop ? " rounded-t-[10px]" : "rounded-[10px] "
        } `}
      >
        <div className="select-none flex items-center gap-[10px]">
          <p
            className={`${
              value !== "აირჩიე" ? "text-[18px]" : "text-[15px] text-gray-400"
            }`}
          >
            {value}
          </p>
        </div>
        <div className="flex items-center justify-center gap-[10px]">
          {value !== "აირჩიე" && (
            <RxCross2
              className=""
              onClick={() => {
                setValue("აირჩიე");
              }}
            />
          )}
          <PiCaretDownLight
            className={`${drop ? "rotate-180 duration-200" : "duration-200"}`}
          />
        </div>
      </div>

      <div
        className={`showScrollbar absolute z-[1] w-full p-[10px] max-h-[200px] shadow-md shadow-[#3d7294] bg-[#0e1420] rounded-b-[10px] overflow-y-scroll  ${
          drop ? "flex" : "hidden"
        }`}
      >
        <ul className="w-full flex flex-col">
          {data &&
            data.map((item) => (
              <p key={item.id}
                onClick={() => {
                  handelClick(item);
                  setDrop(false);
                }}
                className="cursor-pointer hover:bg-[#203f52] duration-100 px-[10px] text-white"
              >
                {item.make}
              </p>
            ))}
        </ul>
      </div>
    </div>
  );
}