import { useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillCameraVideoFill } from "react-icons/bs";

export default function AddVideo({
  name,
  handleNewPhotos = null,
  makeEmpty,
  handleMakeEmpty,
  setSelectedPhotoIndex = null,
  inputName,
}) {
  const [multipleFiles, setMultipleFiles] = useState([]);
  const inputElement = useRef(null);
  const [clickedPhotoIndex, setClickedPhotoIndex] = useState(0);

  const handleMultipleFilesChange = (event) => {
    let tmpVideoList = [];
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i].type.startsWith("video/")) {
        tmpVideoList.push(event.target.files[i]);
      }
    }
    setMultipleFiles((prev) => tmpVideoList);
  };

  const HandleRemovePhoto = (index) => {
    let tmp = [...multipleFiles];
    tmp.splice(index, 1);
    setMultipleFiles(tmp);
  };

  const handleOpenInput = () => {
    inputElement.current.click();
  };

  const handleSelectedPhotoId = (index) => {
    setClickedPhotoIndex(index);
    if (setSelectedPhotoIndex !== null) {
      setSelectedPhotoIndex(index);
    }
  };

  useEffect(() => {
    if (multipleFiles != null && handleNewPhotos !== null) {
      handleNewPhotos(multipleFiles);
    }
  }, [multipleFiles]);

  useEffect(() => {
    if (makeEmpty) {
      setMultipleFiles([]);
      handleMakeEmpty(false);
    }
  }, [makeEmpty]);

  return (
    <div
      className="grid grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-sm:grid-cols-2 gap-4 items-center"
    >
      {multipleFiles ? (
        multipleFiles.map((single, index) => {
          let fileUrl = URL.createObjectURL(single);
          let fileName = single.name;
          return (
            <div
              key={index}
              className={`w-full  h-[100px] shadow-md shadow-[#3d7294] bg-[#182540] group rounded-[10px] relative flex items-center
                                             ${
                                              clickedPhotoIndex === index &&
                                              "border-[#182540] border-[4px]"
                                            }`}
            >
              <div className="flex items-center w-full h-full gap-2 max-sm:flex-col">
                <video
                  src={fileUrl}
                  className="w-full h-full object-cover rounded-[6px]"
                  alt="#"
                  controls
                />
              </div>
              <div
                className="rounded-full absolute group-hover:flex max-lg:flex top-[7px] right-[10px] w-8 h-8 bg-[#21335a] cursor-pointer hidden items-center justify-center"
                onClick={() => {
                  HandleRemovePhoto(index);
                }}
              >
                <AiOutlineClose className="text-gray-100 text-2xl" />
              </div>
              <div
                className=" absolute flex bottom-[7px] w-full h-8 px-[10px]    items-center justify-center"
                onClick={() => {
                  handleSelectedPhotoId(index);
                }}
              >
                <p className="bg-[#21335a] group-hover:flex hidden w-full text-white h-full rounded-[10px] cursor-pointer items-center justify-center">
                  მთავარი ვიდეო
                </p>
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
      <div className="my-1">
        <div
          onClick={handleOpenInput}
          className="bg-[#182540] duration-100 hover:scale-105 rounded-[10px]  border-dashed border-4 h-[100px] w-full flex-col gap-2
                             border-gray-300 flex items-center justify-center cursor-pointer text-[45px]"
        >
          <BsFillCameraVideoFill/>
        </div>
        <input
          name={inputName}
          ref={inputElement}
          multiple
          className="hidden"
          type="file"
          onChange={handleMultipleFilesChange}
          accept={["video/mp4, video/mkv, video/avi, video/mov"]}
        />
      </div>
    </div>
  );
}
