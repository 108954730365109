import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import pic from "../../../photos/tllogo.webp";
import axiosClient from "../../axiosClient/axiosClient";

const AdminCard = ({ item, setNewRender }) => {
  
  const handelDelete = (Id) => {
    axiosClient.delete(`admin/admins/${Id}`).then((response) => {
      setNewRender(response);
    });
  };

  return (
    <div
      className="shadow-md shadow-[#3d7294] bg-[#0e1420]  rounded-xl p-5 w-full relative
        flex flex-col items-center justify-center gap-3 max-sm:gap-1 dark:bg-slate-700"
    >
      <div className=" w-20 h-20 rounded-full bg-blue-600">
        <img
          src={pic}
          alt=""
          className="w-full h-full object-cover rounded-full"
        />
      </div>
      <p className="text-xs text-gray-400">ადმინი</p>
      <h1 className="text-lg font-medium text-white">{item.email}</h1>
      <div className="w-full flex justify-between max-md:flex-col max-md:gap-y-[10px]">
        <Link
          to={`/adminPanel/adminsUpdatePage/${item.id}`}
          className=" rounded-lg bg-[gray] border
            border-gray-400 flex gap-2 items-center justify-center py-1 px-[8px] hover:scale-105 duration-100 cursor-pointer text-white"
        >
          <BsPencilSquare className="" />
          <p className="text-sm">რედაქტირება</p>
        </Link>
        <div
          onClick={() => {
            handelDelete(item.id);
          }}
          className="rounded-lg bg-[red] hover:scale-105 duration-100 cursor-pointer flex gap-2 items-center justify-center p-2"
        >
          <BsTrash className="text-white" />
          <p className="text-white text-sm">წაშლა</p>
        </div>
      </div>
    </div>
  );
};

export default AdminCard;
