export default function Loader() {
  return (
    <div className="flex gap-[5px] items-center pt-[2px] w-[100%] h-[50px] rounded-[10px]  text-white justify-center  text-[18px] hover:bg-[#000000c1]   duration-200 bg-black">
      <div
        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      ></div>
    </div>
  );
}
