import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import pic from "../../../photos/tllogo.webp";
import axiosClient from "../../axiosClient/axiosClient";
const UserCard = ({ item, setNewRender }) => {

  const handelDelete = (Id) => {
    axiosClient.delete(`admin/users/${Id}`).then((response) => {
      setNewRender(response);
    });
  };
  return (
    <div
      className="shadow-md shadow-[#3d7294] bg-[#0e1420] rounded-xl p-5 w-full relative
        flex flex-col items-center justify-center gap-3 max-sm:gap-1 dark:bg-slate-700"
    >
      <div className=" w-20 h-20 ">
        <img
          src={pic}
          className="w-full h-full object-cover rounded-full"
          alt=""
        />
      </div>
      <p className=" text-gray-400">მომხმარებელი</p>
      <h2 className="text-lg font-medium text-white dark:text-navy-100 dark:text-gray-200">
        {item.name}
      </h2>
      <p className=" text-gray-400">{item.email}</p>
      <div className="w-full flex justify-between max-md:flex-col max-md:gap-y-[10px]">
        <Link
          to={`/adminPanel/userUpdatePage/${item.id}`}
          className="rounded-lg bg-[gray] border hover:scale-105 duration-100
            border-gray-400 flex gap-2 items-center justify-center py-1 px-[8px]"
        >
          <BsPencilSquare className="text-white" />
          <p className="text-white text-sm">რედაქტირება</p>
        </Link>
        <div onClick={()=>(handelDelete(item.id))} className="rounded-lg hover:scale-105 duration-100 cursor-pointer bg-[red] flex gap-2 items-center justify-center p-2">
          <BsTrash className="text-white" />
          <p className="text-white text-sm">წაშლა</p>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
