import { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { BsList } from "react-icons/bs";
import ProfileUpdate from "./ProfileUpdate";
import AdminsUpdatePage from "./AdminsUpdatePage";
import Admins from "./Admins";
import UserUpdatePage from "./UserUpdatePage";
import Users from "./Users";
import { UserContext } from "../../UserSettingContext/AxiosContext";
import AdminCarCheck from "./AdminCarCheck";
import AdminAllCars from "./AdminAllCars";
import AdminAllCarsInfo from "./AdminAllCarsInfo";
import AdminAllParts from "./AdminAllParts";
import AdminAllPartsInfo from "./AdminAllPartsInfo";
import AdminAllServices from "./AdminAllServices";
import AdminAllServiceInfo from "./AdminAllServicesInfo";
import artspaceLogo from "../../../photos/artspacelogo.webp";
import { LiaExchangeAltSolid } from "react-icons/lia";
import { CurrencyContext } from "../../CurrencyContext/CurrencyContext";
import Loader from "../../loader/loader";
import { RunningTypeContext } from "../../runningTypeContext/RunningTypeContext";

export default function AdminPanel({
  setShowAlertLogOut,
  setAlertStatusLogOut,
  handleUploadClick,
}) {
  const { currency, userChooseCurrency, setUserChooseCurrency } =
    useContext(CurrencyContext);
  const { runType, setRunType } = useContext(RunningTypeContext);
  const {
    setTokenAdmin,
    admin,
    setIsAuthorizedAdmin,
    isAuthorizedadmin,
    setAdmin,
  } = useContext(UserContext);

  const location = useLocation();

  const dropRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setdrop(false);
    }
  }

  const [drop, setdrop] = useState(false);
  const handledrop = () => {
    setdrop((pre) => !pre);
  };

  const navigate = useNavigate();

  const logout = () => {
    handleUploadClick();
    setShowAlertLogOut(true);
    setAlertStatusLogOut(true);
    setIsAuthorizedAdmin(false);
    setAdmin({});
    setTokenAdmin(null);
    navigate("/admin");
  };

  if (isAuthorizedadmin === false) {
    navigate("/admin");
  }

  return (
    <div className="w-full flex flex-col items-center ">
      <div className="w-[100%] flex  gap-y-[20px] min-h-[100vh]">
        <div ref={dropRef} className="relative">
          <div
            onClick={() => {
              handledrop();
            }}
            className={`absolute top-[20px]  w-[40px] h-[40px] rounded-[10px]  text-white shadow-[#325063] bg-[black] cursor-pointer shadow text-[25px] hidden items-center justify-center duration-200 max-lg:flex ${
              drop ? "left-[320px]" : "left-[16px]"
            }`}
          >
            <BsList />
          </div>
          <div
            className={`w-[300px] h-full border-r-[1px] flex flex-col gap-y-[30px] pt-[30px] px-[10px] shadow ${
              drop ? "fixed bg-black z-[10]" : "max-lg:hidden"
            }`}
          >
            <div className="">
              <img src={artspaceLogo} alt="" className="w-full" />
            </div>
            {currency.rate === undefined ? (
              <Loader />
            ) : (
              <div className="flex items-center w-full justify-between">
                <div
                  onClick={() => {
                    setUserChooseCurrency("USD");
                  }}
                  className={`rounded-[33px] cursor-pointer duration-150   select-none  h-[40px] px-[15px] flex items-center justify-center text-xl ${
                    userChooseCurrency === "USD"
                      ? "shadow-md shadow-[#3d7294] bg-[#0e1420] text-white"
                      : "shadow shadow-[#253742] text-[gray]"
                  }`}
                >
                  $ {currency.rate}
                </div>
                <LiaExchangeAltSolid
                  className={`text-white text-2xl cursor-pointer duration-200 ${
                    userChooseCurrency === "GEL" ? "rotate-0" : "rotate-180"
                  }`}
                  onClick={() =>
                    setUserChooseCurrency(
                      userChooseCurrency === "USD" ? "GEL" : "USD"
                    )
                  }
                />
                <div
                  onClick={() => {
                    setUserChooseCurrency("GEL");
                  }}
                  className={`rounded-[33px] cursor-pointer duration-150 select-none  h-[40px] px-[15px] flex items-center justify-center text-xl ${
                    userChooseCurrency === "GEL"
                      ? "shadow-md shadow-[#3d7294] bg-[#0e1420] text-white"
                      : "shadow shadow-[#253742] text-[gray]"
                  }`}
                >
                  ₾ 1.00
                </div>
              </div>
            )}
              <div className="flex items-center justify-center gap-[15px] text-white">
                <p onClick={()=>(setRunType("კმ"))} className={`text-white w-[50px] duration-150 rounded-full flex items-center justify-center cursor-pointer h-[30px] ${runType === "კმ" ? "shadow-md shadow-[green]" : "shadow shadow-[red]"}`}>კმ</p>
                -
                <p onClick={()=>(setRunType("მილი"))} className={`text-white w-[50px] rounded-full duration-150 flex items-center justify-center h-[30px] cursor-pointer ${runType === "მილი" ? "shadow-md shadow-[green]" : "shadow shadow-[red]"}`}>მილი</p>
              </div>
            <ul className="flex flex-col gap-y-[20px] text-white">
              <li
                className="px-[10px] border-[1px] shadow rounded-full h-[40px] flex items-center justify-center text-white bg-[#22228b]
                  "
              >
                {admin.email}
              </li>
              <Link to="adminCarCheck">
                <li
                  onClick={() => {
                    setdrop(false);
                  }}
                  className={`px-[10px] border-[1px] shadow rounded-full h-[40px] flex items-center justify-center cursor-pointer ${
                    location.pathname.split("/")[2] === "adminCarCheck" &&
                    "bg-[#464040] text-white"
                  }`}
                >
                  მანქანები ვერიფიკაციისთვის
                </li>
              </Link>
              <Link to="adminAllCars">
                <li
                  onClick={() => {
                    setdrop(false);
                  }}
                  className={`px-[10px] border-[1px] shadow rounded-full h-[40px] flex items-center justify-center cursor-pointer ${
                    location.pathname.split("/")[2] === "adminAllCars" &&
                    "bg-[#464040] text-white"
                  }`}
                >
                  მანქანები
                </li>
              </Link>
              <Link to="adminAllParts">
                <li
                  onClick={() => {
                    setdrop(false);
                  }}
                  className={`px-[10px] border-[1px] shadow rounded-full h-[40px] flex items-center justify-center cursor-pointer ${
                    location.pathname.split("/")[2] === "adminAllParts" &&
                    "bg-[#464040] text-white"
                  }`}
                >
                  ნაწილები
                </li>
              </Link>
              <Link to="adminAllService">
                <li
                  onClick={() => {
                    setdrop(false);
                  }}
                  className={`px-[10px] border-[1px] shadow rounded-full h-[40px] flex items-center justify-center cursor-pointer ${
                    location.pathname.split("/")[2] === "adminAllService" &&
                    "bg-[#464040] text-white"
                  }`}
                >
                  სერვისები
                </li>
              </Link>
              <Link to="users">
                <li
                  onClick={() => {
                    setdrop(false);
                  }}
                  className={`px-[10px] border-[1px] shadow rounded-full h-[40px] flex items-center justify-center cursor-pointer ${
                    location.pathname.split("/")[2] === "users" &&
                    "bg-[#464040] text-white"
                  }`}
                >
                  მომხმარებლები
                </li>
              </Link>

              <Link to="admins">
                <li
                  onClick={() => {
                    setdrop(false);
                  }}
                  className={`px-[10px] border-[1px] shadow rounded-full h-[40px] flex items-center justify-center cursor-pointer ${
                    location.pathname.split("/")[2] === "admins" &&
                    "bg-[#464040] text-white"
                  }`}
                >
                  ადმინები
                </li>
              </Link>

              <Link to="profileUpdate">
                <li
                  onClick={() => {
                    setdrop(false);
                  }}
                  className={`px-[10px] border-[1px] shadow rounded-full h-[40px] flex items-center justify-center cursor-pointer ${
                    location.pathname.split("/")[2] === "profileUpdate" &&
                    "bg-[#464040] text-white"
                  }`}
                >
                  პროფილის რედაქტირება
                </li>
              </Link>

              <li
                onClick={() => logout()}
                className="px-[10px] border-[1px] shadow rounded-full h-[40px] flex items-center justify-center  duration-150 cursor-pointer"
              >
                გასვლა
              </li>
            </ul>
          </div>
        </div>
        <div className="w-[calc(100vw-300px)] p-[50px] max-md:py-[70px] max-lg:px-[16px] bg-[#1d1b2c] max-lg:w-full">
          <Routes>
            <Route path="adminCarCheck" element={<AdminCarCheck />} />

            <Route path="adminAllCars" element={<AdminAllCars />} />
            <Route path="adminAllCarsInfo/:id" element={<AdminAllCarsInfo />} />

            <Route path="adminAllParts" element={<AdminAllParts />} />
            <Route
              path="adminAllPartsInfo/:id"
              element={<AdminAllPartsInfo />}
            />

            <Route path="adminAllService" element={<AdminAllServices />} />
            <Route
              path="adminAllServiceInfo/:id"
              element={<AdminAllServiceInfo />}
            />

            <Route path="users" element={<Users />} />
            <Route path="userUpdatePage/:id" element={<UserUpdatePage />} />

            <Route path="admins" element={<Admins />} />
            <Route path="adminsUpdatePage/:id" element={<AdminsUpdatePage />} />

            <Route path="profileUpdate" element={<ProfileUpdate />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}
