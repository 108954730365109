import React, { useContext } from "react";
import { FiEdit3, FiSettings } from "react-icons/fi";
import { BsGeoAlt, BsTrash } from "react-icons/bs";

import { Link } from "react-router-dom";
import axiosClient from "../../axiosClient/axiosClient";
import { CurrencyContext } from "../../CurrencyContext/CurrencyContext";
export default function AdminAllPartsComponent({ item, setNewRender }) {
  const { userChooseCurrency } = useContext(CurrencyContext);

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const handelDelete = (Id) => {
    axiosClient.delete(`admin/parts/${Id}`).then((response) => {
      setNewRender(response);
    });
  };

  return (
    <div className="flex flex-1 flex-col items-center justify-start w-full">
      <div className="flex flex-col  gap-y-[5px] items-start justify-start w-full relative">
        <div className="flex items-center w-full justify-between">
          <Link to={`/adminPanel/adminAllPartsInfo/${item.id}`} className="">
            <div className=" bg-white text-black cursor-pointer hover:scale-105 duration-100  w-[80px] h-[30px] rounded-full flex items-center justify-center">
              <FiEdit3 />
            </div>
          </Link>

          <div
            onClick={() => {
              handelDelete(item.id);
            }}
            className="bg-[#c92929] text-white cursor-pointer hover:scale-105 duration-100 top-[10px] right-[10px] w-[80px] h-[30px] rounded-full flex items-center justify-center"
          >
            <BsTrash />
          </div>
        </div>

        <div className="h-[180px] relative w-full">
          <Link to={`/part/${item.id}`}>
            <img
              onClick={() => goToTopFast()}
              className="h-full m-auto object-cover rounded-[10px] w-full"
              src={
                item.parts_imgs &&
                item.parts_imgs[0] &&
                `https://back.carfax.ge/${item.parts_imgs[0].url}`
              }
              alt=""
            />
          </Link>
          <div className="absolute flex flex-row gap-1.5 items-center justify-start left-[5px] top-[5px] w-[57%]">
            {item.vip > 0 && <div className="bg-[red] text-white px-[10px] rounded-full">
              VIP
            </div>}
            {item.condition && (
              <p className="bg-[white] text-black border-[1px] justify-center p-1 px-2 rounded-full text-[13px] text-center">
                {item.condition}
              </p>
            )}
            {item.o_city && (
              <div className="bg-[#4a4949] cursor-pointer flex items-center justify-center gap-[5px] p-1 px-3 rounded-full ">
                <BsGeoAlt className="text-[12px] text-center text-white" />
                <p className="leading-[normal] text-[13px] text-center text-white">
                  {item.o_city}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center w-full gap-[5px]">
        {item.Parts_models &&
            item.Parts_models.map((item2, index) => (
              <h1 key={item2.id} className="oneline text-white text-[15px] ">
                {item2.firm}
                {index < item.Parts_models.length - 1 && ","}
              </h1>
            ))}
        </div>
        <div className="flex flex-row  items-start justify-between w-[86%] md:w-full">
          <div className="flex items-center w-full gap-[5px]">
          {item.Parts_models &&
            item.Parts_models.map((item2, index) => (
              <p key={item2.id} className="oneline text-white text-[15px] ">
                {item2.model}
                {index < item.Parts_models.length - 1 && ","}
              </p>
            ))}
          </div>
        </div>
        <div className="flex flex-row gap-[3px] justify-between items-center w-full">
          <div className="flex items-center gap-[5px]">
            <h1 className=" text-[17px]  text-white">{item.price}  {userChooseCurrency === "USD" ? "$" : "₾"}</h1>
          </div>
          <div className="flex items-center gap-[5px] ">
            <p className="text-[14px]  text-white">{item.partName}</p>
            <FiSettings className="text-white text-[14px]" />
          </div>
        </div>
      </div>
    </div>
  );
}
