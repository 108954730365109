import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Loader from "../../loader/loader";
import axiosClient from "../../axiosClient/axiosClient";
import MyInput from "../../MyInput";
import MyAlert from "../../MyAlert";

const AdminsUpdatePage = () => {
  const [alertStatus, setAlertStatus] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showpas, setShowpas] = useState(false);

  const handleShowpass = () => {
    setShowpas((pre) => !pre);
  };
  const params = useParams();

  const handelChange = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  const { id } = params;

  const [allValues, setAllValues] = useState({
    email: "",
    password: "",
  });

  const [data, setData] = useState();

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const handlealert = () => {
    goToTopFast();
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  useEffect(() => {
    axiosClient.get(`admin/admins/${id}`).then((res) => {
      setIsLoading(false);
      setData(res.data);
      setAllValues(res.data);
    });
  }, []);

  const UpdateEachAdmin = (e) => {
    e.preventDefault();
    axiosClient
      .post(`admin/admins/${id}`, {
        email: allValues.email,
        password: allValues.password,
      })
      .then((res) => {
        setShowAlert(true);
        setAlertStatus(true);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertStatus(false);
      });
  };

  return (
    <div className="max-md:ml-0 ">
      <h1 className="hidden max-lg:flex w-full justify-center mb-[10px] text-white text-[20px]">
          ადმინის რედაქტირება
        </h1>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-y-[10px] px-[50px] py-[50px] shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px]">
          <form
            onSubmit={UpdateEachAdmin}
            encType="multipart/form-data"
            className="flex flex-col gap-y-[20px]"
          >
            <div className="flex w-[420px] max-sm:w-auto justify-between items-center gap-[20px] max-sm:gap-[10px] max-sm:flex-col max-sm:items-start">
              <p className="">მეილი</p>
              <div className="w-[230px] h-[40px]  max-sm:w-full">
                <MyInput
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.email}
                  name="email"
                />
              </div>
            </div>
            <div className="flex w-[420px] max-sm:w-auto justify-between items-center gap-[20px] max-sm:gap-[10px] max-sm:flex-col max-sm:items-start">
              <p className="">ახალი პაროლი</p>
              <div className="flex items-center w-[230px] h-[40px]  max-sm:w-full rounded-[10px] shadow-md shadow-[#3d7294] bg-[#1d263a]">
                <input
                  onChange={(e) => handelChange(e)}
                  value={data && data.password}
                  name="password"
                  placeholder="**********"
                  type={showpas ? "text" : "password"}
                  className="outline-none  w-[calc(100%-20px)] px-[10px]   bg-transparent"
                />
                <div
                  className="cursor-pointer w-[20px]"
                  onClick={() => {
                    handleShowpass();
                  }}
                >
                  {showpas ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center mt-[50px] gap-[20px] max-sm:flex-col max-sm:items-start">
              <button
                onClick={() => handlealert()}
                className="px-[10px] w-[230px] max-sm:w-auto cursor-pointer h-[50px] flex items-center rounded-[8px] justify-center bg-[#23345a] shadow-md shadow-[#3d7294]
              hover:bg-[#335f7a] duration-200 "
                type="submit"
              >
                რედაქტირება
              </button>
            </div>
          </form>
        </div>
      )}
      <MyAlert
        showAlert={showAlert}
        alertStatus={alertStatus}
        text={
          alertStatus
            ? "ინფორმაცია წარმატებით შეიცვალა"
            : "ინფორმაცია ვერ შეიცვალა"
        }
      />
    </div>
  );
};

export default AdminsUpdatePage;
