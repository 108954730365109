import { useNavigate, useParams } from "react-router-dom";
import { useDatasContext } from "../../DatasContext/DatasContext";
import { useEffect, useState } from "react";
import axiosClient from "../../axiosClient/axiosClient";
import Loader from "../../loader/loader";
import MakeModelDropDown from "../../MakeModelDropDown";
import DropDownAbsolute from "../../DropDownAbsolute";
import MyInput from "../../MyInput";
import MoreInfoButtons from "../user/MoreInfoButtons";
import AddDescr from "../user/AddDescr";
import UploadedVids from "../user/UploadedVids";
import AddVideo from "../user/AddVideo";
import UploadedPhts from "../user/UploadedPhts";
import AddPhoto from "../user/AddPhoto";
import AddProdPrice from "../user/AddProdPrice";
import AddContact from "../user/AddContact";
import AddValuableServs from "../user/AddValuableServs";

export default function AdminAllCarsInfo() {
  const navigate = useNavigate();
  const {
    vehicleType,
    cylinders,
    transmission,
    country,
    fuel,
    pullingWheels,
    weel,
    colors,
    leather,
    manufacturer,
    test1,
    setTest1,
    originalData,
  } = useDatasContext();

  const [allValues, setAllValues] = useState({
    country: "",
    firm: "",
    model: "",
    engine: "",
    metersRun: "",
    petrol: "",
    driveShaft: "",
    pullingWheels: "",
    year: "",
    weel: "",
    price: "",
    currency: "",

    vin: "",
    vehicleType: "",
    cylinders: "",
    transmission: "",
    description: "",

    color: "",
    salonColor: "",
    Hatch: "",
    Panorama: "",
    leather: "",
    multimedia: "",
    camera: "",

    o_name: "",
    o_phone: "",
    o_city: "",

    vip: "",
  });

  const [showAcceptAlert, setShowAcceptAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [data, setData] = useState();
  const [loader, setLoader] = useState(true);
  const [verified, setVerified] = useState("არა");

  const handleUploadClick = () => {
    setTimeout(() => {
      setLoader(true);
      setShowAcceptAlert(false);
      setShowErrorAlert(false);
    }, 2000);
  };

  const { id } = useParams();

  useEffect(() => {
    axiosClient.get(`admin/car/${id}`).then((res) => {
      setLoader(false);
      setData(res.data);
      setAllValues(res.data);
    });
  }, [id]);

  const UpdateCar = (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    formData.append("country", allValues.country);
    formData.append("firm", allValues.firm);
    formData.append("model", allValues.model);
    formData.append("engine", allValues.engine);
    formData.append("metersRun", allValues.metersRun);
    formData.append("petrol", allValues.petrol);
    formData.append("driveShaft", allValues.driveShaft);
    formData.append("pullingWheels", allValues.pullingWheels);
    formData.append("year", allValues.year);
    formData.append("weel", allValues.weel);
    formData.append("price", allValues.price);
    formData.append("currency", allValues.currency);
    formData.append("vin", allValues.vin);
    formData.append("vehicleType", allValues.vehicleType);
    formData.append("description", allValues.description);
    formData.append("color", allValues.color);
    formData.append("salonColor", allValues.salonColor);
    formData.append("Hatch", allValues.Hatch);
    formData.append("Panorama", allValues.Panorama);
    formData.append("leather", allValues.leather);
    formData.append("multimedia", allValues.multimedia);
    formData.append("camera", allValues.camera);
    formData.append("o_name", allValues.o_name);
    formData.append("o_phone", allValues.o_phone);
    formData.append("o_city", allValues.o_city);
    formData.append("vip", allValues.vip);
    formData.append("transmission", allValues.transmission);
    formData.append("cylinders", allValues.cylinders);
    if (verified === "კი") {
      formData.append("status", 0);
      formData.append("checked", 1);
    }
    if (verified === "არა") {
      formData.append("status", 2);
    }
    axiosClient
      .post(`admin/car/${id}`, formData)
      .then((res) => {
        setShowAcceptAlert(true);
        goToTopFast();
        navigate("/adminPanel/adminCarCheck");
      })
      .catch((error) => {
        setShowErrorAlert(true);
      });
  };

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const DeleteImgBack = (id) => {
    axiosClient
      .delete(`admin/carImage/${id}`)
      .then((res) => {
        DeleteImgFront(id);
      })
      .catch((err) => console.log(err))
      .finally(setLoader(false));
  };
  const DeleteImgFront = (id) => {
    const newData = { ...data };

    newData.cars_imgs = newData.cars_imgs.filter((item) => item.id !== id);
    setData(newData);
  };

  const DeleteVideoBack = (id) => {
    axiosClient
      .delete(`admin/carVideo/${id}`)
      .then((res) => {
        DeleteVideoFront(id);
      })
      .catch((err) => console.log(err))
      .finally(setLoader(false));
  };
  const DeleteVideoFront = (id) => {
    const newData = { ...data };

    newData.cars_videos = newData.cars_videos.filter((item) => item.id !== id);
    setData(newData);
  };

  return (
    <div className="w-full flex flex-col gap-7 text-white">
      <h1 className="hidden max-lg:flex w-full text-center justify-center mb-[10px] text-white text-[20px]">
        {data && data.status && data.status === 1 && "სავერიფიკაციო"}{" "}
        ავტომობილის რედაქტირება
      </h1>
      {loader ? (
        <Loader />
      ) : (
        <form
          onSubmit={UpdateCar}
          encType="multipart/form-data"
          className="flex flex-col gap-7"
        >
          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">დეტალური ინფორმაცია</p>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>მწარმოებელი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <MakeModelDropDown
                  data={manufacturer}
                  test1={test1}
                  setTest1={setTest1}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={allValues.firm}
                  name="firm"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>მოდელი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <MakeModelDropDown
                  data={test1}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data.model}
                  name="model"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>კატეგორია</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={vehicleType}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data.vehicleType}
                  name="vehicleType"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>ძრავის მოცულობა</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={"engine"}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.engine}
                  name="engine"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>ცილინდრები</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={cylinders}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.cylinders}
                  name="cylinders"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>გადაცემათა კოლოფი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={transmission}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.transmission}
                  name="transmission"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>საჭე</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={weel}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.weel}
                  name="weel"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>გარბენი</p>
              <div className="w-[300px] gap-[10px] h-[50px] flex items-center max-sm:w-full">
                <MyInput
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.metersRun}
                  name="metersRun"
                />
                <p>კმ</p>
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>საწვავის ტიპი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={fuel}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.petrol}
                  name="petrol"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>წამყვანი თვლები</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={pullingWheels}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.pullingWheels}
                  name="pullingWheels"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>წელი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data="year"
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.year}
                  name="year"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>მდებარეობა</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={country}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.country}
                  name="country"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>ფერი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={colors}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.color}
                  name="color"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>სალონის ფერი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={colors}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.salonColor}
                  name="salonColor"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>სალონის მასალა</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={leather}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.leather}
                  name="leather"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>VIN</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <MyInput
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.vin}
                  name="vin"
                />
              </div>
            </div>
          </div>
          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">დამატებითი ინფორმაცია</p>
            <div className="grid grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-tiny:grid-cols-2 w-full gap-y-[20px] gap-[20px]">
              <MoreInfoButtons
                text={"ლუქი"}
                allValues={allValues}
                setAllValues={setAllValues}
                firstValue={data && data.Hatch}
                name="Hatch"
              />
              <MoreInfoButtons
                text={"პანორამა"}
                allValues={allValues}
                setAllValues={setAllValues}
                firstValue={data && data.Panorama}
                name="Panorama"
              />
              <MoreInfoButtons
                text={"მულტიმედია"}
                allValues={allValues}
                setAllValues={setAllValues}
                firstValue={data && data.multimedia}
                name="multimedia"
              />
              <MoreInfoButtons
                text={"კამერა"}
                allValues={allValues}
                setAllValues={setAllValues}
                firstValue={data && data.camera}
                name="camera"
              />
            </div>
          </div>
          <AddDescr
            allValues={allValues}
            setAllValues={setAllValues}
            firstValue={data && data.description}
            name="description"
          />
          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">ატვირთული ვიდეობი</p>
            <div className="grid grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-sm:grid-cols-2 gap-4">
              {loader ? (
                <Loader />
              ) : (
                data &&
                data.cars_videos &&
                data.cars_videos.map((item) => (
                  <UploadedVids
                    key={item.id}
                    item={item}
                    DeleteVideoBack={DeleteVideoBack}
                  />
                ))
              )}
            </div>

            <p className="hmli text-[17px]">დაამატე ვიდეო</p>
            <AddVideo inputName={"cars_videos"} />

            <p className="hmli text-[17px]">ატვირთული ფოტოები</p>

            <div className="grid grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-sm:grid-cols-2 gap-4 items-center">
              {loader ? (
                <Loader />
              ) : (
                data &&
                data.cars_imgs.map((item) => (
                  <UploadedPhts
                    key={item.id}
                    item={item}
                    DeleteImgBack={DeleteImgBack}
                  />
                ))
              )}
            </div>
            <p className="hmli text-[17px]">დაამატე ფოტო</p>
            <AddPhoto inputName={"cars_imgs"} />
          </div>
          <AddProdPrice
            allValues={allValues}
            setAllValues={setAllValues}
            firstValue={data && data.price}
            secValue={data && data.currency}
            name1="price"
            name2="currency"
          />
          <AddContact
            allValues={allValues}
            setAllValues={setAllValues}
            data={data}
          />
          <AddValuableServs
            allValues={allValues}
            setAllValues={setAllValues}
            firstValue={data && data.vip}
            name="vip"
          />
          {data.status === 1 && (
            <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
              <p className="hmli text-[17px]">ვერიფიკაცია</p>
              <div className="flex items-center justify-between max-tiny:flex-col max-tiny:items-start max-tiny:gap-y-[10px]">
                <div className="flex items-center gap-[10px]">
                  <p>ვერიფიცირდეს TLgroup-ის მიერ? </p>
                  <p>{verified}</p>
                </div>
                <div className=" h-[50px] flex items-center gap-[20px]">
                  {originalData.map((item) => (
                    <p
                      key={item.id}
                      onClick={() => {
                        setVerified(item.text);
                      }}
                      className={`flex items-center justify-center rounded-[10px] h-full w-[50px] cursor-pointer duration-150 ${
                        verified === item.text
                          ? "shadow-md shadow-[#3d7294] bg-[#1d263a] text-white     "
                          : "shadow-md shadow-[#304f63] bg-[#252b38] text-gray-400"
                      }`}
                    >
                      {item.text}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="w-full rounded-[10px] flex justify-end gap-y-[20px]">
            <button
              onClick={() => {
                handleUploadClick();
              }}
              type="submit"
              className="bg-[#0e1420] shadow-md shadow-[#3d7294]
              hover:bg-[#335f7a] duration-200 px-[40px] h-[50px] rounded-[10px] cursor-pointer flex items-center justify-center"
            >
              რედაქტირება
            </button>
          </div>
          <div
            className={`z-[20] fixed h-[80px] flex  rounded-[5px] bg-[#174717] shadow shadow-[#386c38] text-[17px] text-white left-[50%] translate-x-[-50%] duration-100 ${
              showAcceptAlert ? "top-[30px]" : "top-[-90px]"
            }`}
          >
            <div className="w-[8px] h-full bg-[green] rounded-l-[5px]"></div>
            <p className="px-[20px] flex items-center">წარმატებით აიტვირთა</p>
          </div>
          <div
            className={`z-[20] fixed h-[80px] flex  rounded-[5px] bg-[#671c1c] shadow shadow-[#671c1c] text-[17px] text-white left-[50%] translate-x-[-50%] duration-100 ${
              showErrorAlert ? "top-[30px]" : "top-[-90px]"
            }`}
          >
            <div className="w-[8px] h-full bg-[red] rounded-l-[5px]"></div>
            <p className="px-[20px] flex items-center">შეიტანე ფასი და VIN!</p>
          </div>
        </form>
      )}
    </div>
  );
}
