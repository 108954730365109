import AddProdPrice from "./AddProdPrice";
import AddDescr from "./AddDescr";
import AddValuableServs from "./AddValuableServs";
import AddContact from "./AddContact";
import AddPhoto from "./AddPhoto";
import { useState } from "react";
import PartsDropDown from "../../PartsDropDown/PartsDropDown";
import { useDatasContext } from "../../DatasContext/DatasContext";
import axiosClient from "../../axiosClient/axiosClient";
import Loader from "../../loader/loader";
import PartsFirmModYear from "./PartsFirmModYear";
import MyAlert from "../../MyAlert";

export default function AddPart() {
  const [loader, setLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);

  const handleUploadPart = () => {
    goToTopFast();
    setTimeout(() => {
      setShowAlert(false);
    }, 2200);
  };

  const {
    conditionData,
    originalData,
    setTest1,
    test1,
    manufacturer,
    partsInp,
  } = useDatasContext();

  const [allValues, setAllValues] = useState({
    partName: "",
    part_models: [],
    condition: "",
    original: "",
    description: "",
    price: "",
    currency: "",

    o_name: "",
    o_phone: "",
    o_city: "",

    vip: "",
  });

  const [condition, setCondition] = useState("");

  const [original, setOriginal] = useState("");

  const [addModel, setAddModel] = useState({
    fyear: "",
    tyear: "",
    firm: "",
    model: "",
  });

  const UploadPart = (e) => {
    setLoader(true);
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    formData.append("partName", allValues.partName);
    formData.append("part_models", JSON.stringify(allValues.part_models));
    formData.append("condition", condition);
    formData.append("original", original);
    formData.append("description", allValues.description);
    formData.append("price", allValues.price);
    formData.append("currency", allValues.currency);

    formData.append("o_name", allValues.o_name);
    formData.append("o_phone", allValues.o_phone);
    formData.append("o_city", allValues.o_city);

    formData.append("vip", allValues.vip);
    axiosClient
      .post("user/part", formData)
      .then((res) => {
        setShowAlert(true);
        setAlertStatus(true);
        setCondition("");
        setOriginal("");
        setAllValues({
          part_models: [],
        });
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertStatus(false);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const handleDelete = (Id) => {
    const newValues = { ...allValues };
    const filtered = newValues.part_models.filter((item) => item.id !== Id);
    newValues.part_models = filtered;
    setAllValues(newValues);
  };

  const handleAdd = (data) => {
    if (
      data.firm !== "" &&
      data.model !== "" &&
      data.fyear !== "" &&
      data.tyear !== ""
    ) {
      const newValues = { ...allValues };
      newValues.part_models = [
        ...newValues.part_models,
        {
          firm: data.firm,
          model: data.model,
          fyear: data.fyear,
          tyear: data.tyear,
        },
      ];
      setAllValues(newValues);
      setAddModel({ fyear: "", tyear: "", firm: "", model: "" });
    }
  };

  return (
    <div className="w-full flex flex-col gap-7 text-white">
      {loader ? (
        <Loader />
      ) : (
        <form
          onSubmit={UploadPart}
          encType="multipart/form-data"
          className="flex flex-col gap-7"
        >
          <h1 className="text-[22px] hidden max-lg:flex">ნაწილის დამატება</h1>
          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">დეტალური ინფორმაცია</p>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>ნაწილი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <PartsDropDown
                  data={partsInp}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="partName"
                />
              </div>
            </div>
            {(allValues.part_models || []).map((item) => (
              <PartsFirmModYear
                key={item.id}
                item={item}
                manufacturer={manufacturer}
                test1={test1}
                setTest1={setTest1}
                allValues={allValues}
                setAllValues={setAllValues}
                handleDelete={handleDelete}
              />
            ))}
            <PartsFirmModYear
              manufacturer={manufacturer}
              test1={test1}
              setTest1={setTest1}
              allValues={addModel}
              setAllValues={setAddModel}
            />
            <div className="flex justify-start">
              <p
                onClick={() => handleAdd(addModel)}
                className="flex self-start h-[40px] items-center justify-center rounded-[10px] px-[10px] cursor-pointer duration-150 shadow-md shadow-[#3d7294] bg-[#1d263a] text-white"
              >
                მოდელის დამატება
              </p>
            </div>
            <div className="flex items-center justify-between max-tiny:flex-col max-tiny:items-start max-tiny:gap-y-[10px]">
              <div className="flex gap-[20px] items-end">
                <p>მდგომარეობა</p>
                <p className="text-[13px]">{condition}</p>
              </div>
              <div className=" h-[50px] flex items-center gap-[20px]">
                {conditionData.map((item) => (
                  <p
                    key={item.id}
                    onClick={() => {
                      setCondition(item.text);
                    }}
                    className={`flex items-center justify-center rounded-[10px] h-full px-[10px] cursor-pointer duration-150 ${
                      condition === item.text
                        ? "shadow-md shadow-[#304f63] bg-[#252b38] text-gray-400"
                        : "shadow-md shadow-[#3d7294] bg-[#1d263a] text-white"
                    }`}
                  >
                    {item.text}
                  </p>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-between max-tiny:flex-col max-tiny:items-start max-tiny:gap-y-[10px]">
              <div className="flex gap-[20px] items-end">
                <p>ორიგინალი</p>
                <p className="text-[13px]">{original}</p>
              </div>
              <div className=" h-[50px] flex items-center gap-[20px]">
                {originalData.map((item) => (
                  <p
                    key={item.id}
                    onClick={() => {
                      setOriginal(item.text);
                    }}
                    className={`flex items-center justify-center rounded-[10px] h-full w-[50px] cursor-pointer duration-150 ${
                      original === item.text
                        ? "shadow-md shadow-[#304f63] bg-[#252b38] text-gray-400"
                        : "shadow-md shadow-[#3d7294] bg-[#1d263a] text-white"
                    }`}
                  >
                    {item.text}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <AddDescr
            allValues={allValues}
            setAllValues={setAllValues}
            name="description"
          />
          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">ატვირთე ფოტო</p>
            <AddPhoto inputName={"parts_imgs"} />
          </div>
          <AddProdPrice
            allValues={allValues}
            setAllValues={setAllValues}
            name1="price"
            name2="currency"
          />
          <AddContact allValues={allValues} setAllValues={setAllValues} />
          <AddValuableServs
            allValues={allValues}
            setAllValues={setAllValues}
            name="vip"
          />
          <div className="w-full rounded-[10px] flex justify-end gap-y-[20px]">
            <button
            onClick={()=>(handleUploadPart())}
              type="submit"
              className="bg-[#0e1420] shadow-md shadow-[#3d7294]
              hover:bg-[#335f7a] duration-200 px-[40px] h-[50px] rounded-[10px] cursor-pointer flex items-center justify-center"
            >
              გამოქვეყნება
            </button>
            <MyAlert
              showAlert={showAlert}
              alertStatus={alertStatus}
              text={
                alertStatus
                  ? "ნაწილი წარმატებით აიტვირთა"
                  : "შეიტანე ნაწილის სახელი!"
              }
            />
          </div>
        </form>
      )}
    </div>
  );
}
