import { AiOutlineClose } from "react-icons/ai";
import { BsArrowRightCircle } from "react-icons/bs";
import { Link } from "react-router-dom";
import { UserContext } from "./UserSettingContext/AxiosContext";
import { useContext } from "react";

export default function MenuPopUp({ showMenu, setShowMenu, logOut }) {
  const { isAuthorized } = useContext(UserContext);
  return (
    <div
      className={`ChromePopUp fixed bg-[#00000078] w-full top-0 left-0 flex justify-end ${
        showMenu
          ? "z-[11] duration-100 opacity-1"
          : "z-[-1] duration-200 opacity-0"
      }`}
    >
      <div
        onClick={() => setShowMenu(false)}
        className="absolute top-[40px] left-[40px] flex items-center justify-center text-5xl max-tiny:text-4xl text-[white]"
      >
        <AiOutlineClose />
      </div>
      <div
        className={`p-[40px] max-tiny:px-[20px] h-full bg-black shadow-md shadow-white  ${
          showMenu
            ? "w-[350px] max-tiny:w-[270px] duration-200"
            : "w-0 duration-200"
        }`}
      >
        <div className="flex flex-col gap-y-[20px] text-[18px]">
          <Link to="/">
            <h1 onClick={() => setShowMenu(false)} className="text-white">
              მთავარი
            </h1>
          </Link>
          <h1 className="text-white  w-[220px] overflow-hidden">
            ვინ კოდის შემოწმება
          </h1>
          <Link to="/productcars">
            <h1 onClick={() => setShowMenu(false)} className="text-white">
              ავტომობილები
            </h1>
          </Link>
          <Link to="/productparts">
            <h1 onClick={() => setShowMenu(false)} className="text-white">
              ნაწილები
            </h1>
          </Link>
          <Link to="/services">
            <h1 onClick={() => setShowMenu(false)} className="text-white">
              სერვის ცენტრები
            </h1>
          </Link>
          {isAuthorized && (
            <div
              onClick={() => {
                logOut();
                setShowMenu(false);
              }}
              className="border border-solid border-white bg-[#00000095] hover:bg-[#000000c4] duration-200 cursor-pointer max-sm:flex hidden flex-row gap-[7px]  items-center justify-center w-full h-[40px] rounded-[20px] "
            >
              <BsArrowRightCircle className="text-white h-6 w-6 ml-1 text-white-A700" />
              <h1 className="   text-white">გასვლა</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
