import AddDescr from "./AddDescr";
import AddValuableServs from "./AddValuableServs";
import AddContact from "./AddContact";
import AddVideo from "./AddVideo";
import AddPhoto from "./AddPhoto";
import { useEffect, useState } from "react";
import axiosClient from "../../axiosClient/axiosClient";
import Loader from "../../loader/loader";
import AddServicesInServ from "./AddServicesInServ";
import AddLocationInServ from "./AddLocationServ";
import MyAlert from "../../MyAlert";

export default function AddService() {
  const [allValues, setAllValues] = useState({
    services: "",
    location: [],
    description: "",
    ServcenterName: "",
    phone: "",
    vip: 0,
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [loader, setLoader] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  // Update the selected items
  const toggleItemSelection = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((items) => items.id !== item.id));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const handleUploadClick = () => {
    goToTopFast();
    setTimeout(() => {
      setShowAlert(false);
    }, 2200);
  };

  const UploadServ = (e) => {
    handleUploadClick()
    setLoader(true);
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    formData.append("services", JSON.stringify(allValues.services));
    formData.append("location", JSON.stringify(allValues.location));
    formData.append("description", allValues.description);
    formData.append("servcenterName", allValues.servcenterName);
    formData.append("phone", allValues.phone);
    formData.append("vip", allValues.vip);

    axiosClient
      .post("user/Servcenters", formData)
      .then((res) => {
        setShowAlert(true);
        setAlertStatus(true);
        setSelectedItems([]);
        setAllValues({
          services: "",
          location: [],
          description: "",
          ServcenterName: "",
          phone: "",
          vip: 0,
        });
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertStatus(false);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    setAllValues({ ...allValues, services: selectedItems });
  }, [selectedItems]);

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  return (
    <div className="w-full flex flex-col gap-7 text-white">
      {loader ? (
        <Loader />
      ) : (
        <form
          onSubmit={UploadServ}
          encType="multipart/form-data"
          className="flex flex-col gap-7"
        >
          <h1 className="text-[22px] hidden max-lg:flex">სერვისის დამატება</h1>
          <AddContact
            allValues={allValues}
            setAllValues={setAllValues}
            isService={true}
          />
          <AddServicesInServ
            toggleItemSelection={toggleItemSelection}
            selectedItems={selectedItems}
          />

          <AddLocationInServ
            allValues={allValues}
            setAllValues={setAllValues}
          />

          <AddDescr
            allValues={allValues}
            setAllValues={setAllValues}
            name="description"
          />
          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">ატვირთე ვიდეო</p>
            <AddVideo inputName={"servcenters_videos"} />
            <p className="hmli text-[17px]">ატვირთე ფოტო</p>
            <AddPhoto inputName={"servcenters_imgs"} />
          </div>
          <AddValuableServs
            allValues={allValues}
            setAllValues={setAllValues}
            name="vip"
          />
          <div className="w-full rounded-[10px] flex justify-end gap-y-[20px]">
            <button
              type="submit"
              className="bg-[#0e1420] shadow-md shadow-[#3d7294]
              hover:bg-[#335f7a] duration-200 px-[40px] h-[50px] rounded-[10px] cursor-pointer flex items-center justify-center"
            >
              გამოქვეყნება
            </button>
            <MyAlert
              showAlert={showAlert}
              alertStatus={alertStatus}
              text={
                alertStatus
                  ? "სერვისი წარმატებით აიტვირთა"
                  : "შეიტანე ტელეფონის ნომერი!"
              }
            />
          </div>
        </form>
      )}
    </div>
  );
}
