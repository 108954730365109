import AddProdPrice from "./AddProdPrice";
import AddDescr from "./AddDescr";
import AddValuableServs from "./AddValuableServs";
import DropDownAbsolute from "../../DropDownAbsolute";
import AddContact from "./AddContact";
import AddVideo from "./AddVideo";
import AddPhoto from "./AddPhoto";
import MyInput from "../../MyInput";
import { useState } from "react";
import MakeModelDropDown from "../../MakeModelDropDown";
import { useDatasContext } from "../../DatasContext/DatasContext";
import axiosClient from "../../axiosClient/axiosClient";
import MoreInfoButtons from "./MoreInfoButtons";
import Loader from "../../loader/loader";
import MyAlert from "../../MyAlert";
import logo from "../../../photos/tllogo.webp";
import RunningType from "./RunningType";

export default function AddCar() {
  const {
    vehicleType,
    cylinders,
    transmission,
    country,
    fuel,
    pullingWheels,
    colors,
    leather,
    manufacturer,
    weel,
    test1,
    setTest1,
    originalData,
  } = useDatasContext();

  const [allValues, setAllValues] = useState({
    country: "",
    firm: "",
    model: "",
    engine: "",
    metersRun: "",
    petrol: "",
    driveShaft: "",
    pullingWheels: "",
    year: "",
    weel: "",
    price: "",
    currency: "",

    vin: "",
    vehicleType: "",
    cylinders: "",
    transmission: "",
    description: "",

    name: "",

    color: "",
    salonColor: "",
    leather: "",
    Hatch: "",
    Panorama: "",
    multimedia: "",
    camera: "",

    o_name: "",
    o_phone: "",
    o_city: "",

    vip: "",
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [verified, setVerified] = useState("არა");
  const [rType, setRType] = useState("კმ");

  const handleUploadCarAlert = () => {
    goToTopFast();
    setTimeout(() => {
      setShowAlert(false);
    }, 2200);
  };

  const UploadCar = (e) => {
    handleUploadCarAlert();
    setLoader(true);
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    formData.append("country", allValues.country);
    formData.append("firm", allValues.firm);
    formData.append("model", allValues.model);
    formData.append("engine", allValues.engine);
    formData.append("metersRun", allValues.metersRun);
    formData.append("petrol", allValues.petrol);
    formData.append("driveShaft", allValues.driveShaft);
    formData.append("pullingWheels", allValues.pullingWheels);
    formData.append("year", allValues.year);
    formData.append("weel", allValues.weel);
    formData.append("price", allValues.price);
    formData.append("currency", allValues.currency);
    formData.append("vin", allValues.vin);
    formData.append("vehicleType", allValues.vehicleType);
    formData.append("description", allValues.description);
    formData.append("color", allValues.color);
    formData.append("salonColor", allValues.salonColor);
    formData.append("Hatch", allValues.Hatch);
    formData.append("Panorama", allValues.Panorama);
    formData.append("leather", allValues.leather);
    formData.append("multimedia", allValues.multimedia);
    formData.append("camera", allValues.camera);
    formData.append("o_name", allValues.o_name);
    formData.append("o_phone", allValues.o_phone);
    formData.append("o_city", allValues.o_city);
    formData.append("vip", allValues.vip);
    formData.append("transmission", allValues.transmission);
    formData.append("cylinders", allValues.cylinders);
    if (verified === "კი") {
      formData.append("status", 1);
    }
    if (verified === "არა") {
      formData.append("status", 0);
    }
    if (rType === "კმ") {
      formData.append("name", "კმ");
    }
    if (rType === "მილი") {
      formData.append("name", "მილი");
    }

    axiosClient
      .post("user/car", formData)
      .then((res) => {
        setShowAlert(true);
        setAlertStatus(true);
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertStatus(false);
      })
      .finally(() => {
        setLoader(false);
      });
  };
  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  return (
    <div className="w-full flex flex-col gap-7 text-white">
      {loader ? (
        <Loader />
      ) : (
        <form
          onSubmit={UploadCar}
          encType="multipart/form-data"
          className="flex flex-col gap-7"
        >
          <h1 className="text-[22px] hidden max-lg:flex">
            ავტომობილის დამატება
          </h1>
          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">დეტალური ინფორმაცია</p>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>მწარმოებელი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <MakeModelDropDown
                  data={manufacturer}
                  test1={test1}
                  setTest1={setTest1}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="firm"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>მოდელი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <MakeModelDropDown
                  data={test1}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="model"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>კატეგორია</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={vehicleType}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="vehicleType"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>ძრავის მოცულობა</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={"engine"}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="engine"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>ცილინდრები</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={cylinders}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="cylinders"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>გადაცემათა კოლოფი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={transmission}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="transmission"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>საჭე</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={weel}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="weel"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>გარბენი</p>
              <div className="w-[300px] gap-[10px] flex items-center max-sm:w-full">
                <div className="w-[calc(100%-115px)] h-[50px]">
                  <MyInput
                    allValues={allValues}
                    setAllValues={setAllValues}
                    name="metersRun"
                    justNums={true}
                  />
                </div>
                <RunningType
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="name"
                  rType={rType}
                  setRType={setRType}
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>საწვავის ტიპი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={fuel}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="petrol"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>წამყვანი თვლები</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={pullingWheels}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="pullingWheels"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>წელი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data="year"
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="year"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>მდებარეობა</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={country}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="country"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>ფერი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={colors}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="color"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>სალონის ფერი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={colors}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="salonColor"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>სალონის მასალა</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <DropDownAbsolute
                  data={leather}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="leather"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>VIN</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <MyInput
                  allValues={allValues}
                  setAllValues={setAllValues}
                  name="vin"
                />
              </div>
            </div>
            <div className="flex items-center justify-between max-tiny:flex-col max-tiny:items-start max-tiny:gap-y-[10px]">
              <div className="flex items-center gap-[10px]">
                <p className="flex items-center gap-[10px]">
                  ვერიფიცირდეს
                  <div className="flex items-center gap-[5px]">
                    <img
                      src={logo}
                      alt=""
                      className="w-[20px] h-[20px] rounded-full object-cover"
                    />
                    -ის მიერ?
                  </div>
                </p>
                <p>{verified}</p>
              </div>
              <div className=" h-[50px] flex items-center gap-[20px]">
                {originalData.map((item) => (
                  <p
                    key={item.id}
                    onClick={() => {
                      setVerified(item.text);
                    }}
                    className={`flex items-center justify-center rounded-[10px] h-full w-[50px] cursor-pointer duration-150 ${
                      verified === item.text
                        ? "shadow-md shadow-[#3d7294] bg-[#1d263a] text-white     "
                        : "shadow-md shadow-[#304f63] bg-[#252b38] text-gray-400"
                    }`}
                  >
                    {item.text}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">დამატებითი ინფორმაცია</p>
            <div className="grid grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-tiny:grid-cols-2 w-full gap-y-[20px] gap-[20px] max-sm:gap-[10px]">
              <MoreInfoButtons
                text={"ლუქი"}
                allValues={allValues}
                setAllValues={setAllValues}
                name="Hatch"
              />
              <MoreInfoButtons
                text={"პანორამა"}
                allValues={allValues}
                setAllValues={setAllValues}
                name="Panorama"
              />
              <MoreInfoButtons
                text={"მულტიმედია"}
                allValues={allValues}
                setAllValues={setAllValues}
                name="multimedia"
              />
              <MoreInfoButtons
                text={"კამერა"}
                allValues={allValues}
                setAllValues={setAllValues}
                name="camera"
              />
            </div>
          </div>
          <AddDescr
            allValues={allValues}
            setAllValues={setAllValues}
            name="description"
          />
          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">ატვირთე ვიდეო</p>
            <AddVideo inputName={"cars_videos"} />
            <p className="hmli text-[17px]">ატვირთე ფოტო</p>
            <AddPhoto inputName={"cars_imgs"} />
          </div>
          <AddProdPrice
            allValues={allValues}
            setAllValues={setAllValues}
            name1="price"
            name2="currency"
          />
          <AddContact allValues={allValues} setAllValues={setAllValues} />
          <AddValuableServs
            allValues={allValues}
            setAllValues={setAllValues}
            name="vip"
          />
          <div className="w-full rounded-[10px] flex justify-end gap-y-[20px]">
            <button
              type="submit"
              className="bg-[#0e1420] shadow-md shadow-[#3d7294]
              hover:bg-[#335f7a] duration-200 px-[40px] h-[50px] rounded-[10px] cursor-pointer flex items-center justify-center"
            >
              გამოქვეყნება
            </button>

            <MyAlert
              showAlert={showAlert}
              alertStatus={alertStatus}
              text={
                alertStatus
                  ? verified === "კი"
                    ? "მანქანა გაიგზავნა TL-თან ვერიფიცირებისთვის"
                    : "მანქანა წარმატებით აიტვირთა"
                  : "შეიტანე ფასი და VIN!"
              }
            />
          </div>
        </form>
      )}
    </div>
  );
}
