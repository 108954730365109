import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { FreeMode, Navigation, Thumbs } from "swiper/modules";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { ScreenWidth } from "../../ScreenWidth";

export default function SliderParts({data}) {
  let swiperRef = useRef();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const screenWidth = ScreenWidth();

  return (
    <div className="flex h-[400px] w-full gap-[30px] max-xl:gap-[20px] max-xl:flex-col-reverse max-xl:h-auto">
      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        direction={screenWidth < 1280 ? "horizontal" : "vertical"}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper max-xl:w-full"
      >
        {data &&
          data.map((item) => (
            <SwiperSlide key={item.id}>
              <img
                src={`https://back.carfax.ge/${item.url}`}
                alt=""
                className="h-full w-[150px] max-xl:w-full max-xl:h-[100px] max-tiny:h-[70px] cursor-pointer object-cover rounded-[10px] "
              />
            </SwiperSlide>
          ))}
      </Swiper>

      <div className="relative w-[calc(100%-180px)] h-full max-xl:w-full max-xl:h-[400px] max-sm:h-[300px]">
        <button
          className="absolute top-[50%] translate-y-[-50%] left-[15px] bg-[#0000005b] hover:bg-[#00000099] duration-150  z-10 h-[50px] rounded-full w-[50px] flex justify-center items-center"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <BsChevronLeft className="text-white text-[35px]	" />
        </button>
        <button
          className="absolute top-[50%] right-[15px] translate-y-[-50%] bg-[#0000005b] hover:bg-[#00000099] duration-150  z-10 h-[50px] rounded-full w-[50px] flex justify-center items-center"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <BsChevronRight className="text-white text-[35px]" />
        </button>
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          loop={true}
          spaceBetween={10}
          navigation={true}
          thumbs={{
            swiper:
              thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          modules={[FreeMode, Navigation, Thumbs]}
          className="mySwiper2 h-full w-full"
        >
          {data &&
            data.map((item) => (
              <SwiperSlide key={item.id}>
                <img
                  src={`https://back.carfax.ge/${item.url}`}
                  alt=""
                  className="h-full object-cover rounded-[10px]  w-full"
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
    </div>
  );
}
