import { useEffect } from "react";

export default function RunningType({allValues, setAllValues, name, rType,
  setRType, firstValue}){

  

  useEffect(() => {
    if (setAllValues) {
      setAllValues({ ...allValues, [name]: rType });
    }
    
  }, [rType]);

    return(
        <div className="flex items-center gap-[10px]">
                  <p
                    onClick={() => setRType("კმ")}
                    className={`text-white select-none flex items-center cursor-pointer justify-center rounded-full ${
                      rType === "კმ"
                        ? "shadow shadow-[green] bg-[#0e1420] h-[30px] w-[60px] duration-200 text-[17px]"
                        : "duration-200 shadow shadow-[red] bg-[#0e1420] h-[20px] w-[35px]  text-[10px]"
                    }`}
                  >
                    კმ
                  </p>
                  <p
                    onClick={() => setRType("მილი")}
                    className={`text-white select-none flex items-center cursor-pointer justify-center rounded-full ${
                      rType === "მილი"
                        ? "shadow shadow-[green] bg-[#0e1420] h-[30px] w-[60px] duration-200 text-[17px]"
                        : "duration-200 shadow shadow-[red] bg-[#0e1420] h-[20px] w-[35px]  text-[10px]"
                    }`}
                  >
                    მილი
                  </p>
                </div>
    )
}