import {BsArrowDownUp} from "react-icons/bs"
import TransactionEvery from "./TransactionsEvery";
import { useDatasContext } from "../../DatasContext/DatasContext";

export default function Transactions() {
  const { transactionsData } = useDatasContext()
  return (
    <div className="w-full flex flex-col gap-y-[10px] px-[50px] py-[20px] shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] max-sm:px-[16px] overflow-x-auto">
      <div className="flex  items-center gap-[20px] min-w-[560px]">
        <div className="flex gap-[5px] items-center ">
          <input
            className="shadow shadow-[#3d7294] bg-[#1d263a] rounded-[8px] px-[5px] h-[40px] outline-none"
            type="date"
          />
          დან
        </div>
        -
        <div className="flex gap-[5px] items-center ">
          <input
            className="shadow shadow-[#3d7294] bg-[#1d263a] rounded-[8px] px-[5px] h-[40px] outline-none"
            type="date"
          />
          მდე
        </div>
        <div className="h-[40px] rounded-[8px] bg-[black] shadow shadow-[#3d7294]
      hover:bg-[#3d7294] duration-200 cursor-pointer flex items-center px-[10px]">
          <p>მოძებნე</p>
        </div>
      </div>
      <div className="flex items-center bg-[#495c85] text-white shadow shadow-[#3d7294] rounded-[8px] justify-between h-[40px] min-w-[560px]">
        <div className="w-[10%] flex items-center gap-[5px] justify-center ">
          <p>#</p> <BsArrowDownUp />
        </div>
        <p className="w-[20%] flex justify-center">ოპერაცია</p>
        <p className="w-[25%] flex justify-center">განცხადების ID</p>
        <p className="w-[20%] flex justify-center">თანხა</p>
        <p className="w-[25%] flex justify-center">თარიღი</p>
      </div>

    {transactionsData.map((item)=>(
        <TransactionEvery key={item.id} item={item}/>
    ))}
      
    </div>
  );
}
