import AddProdPrice from "./AddProdPrice";
import AddDescr from "./AddDescr";
import AddValuableServs from "./AddValuableServs";
import AddContact from "./AddContact";
import AddPhoto from "./AddPhoto";
import { useEffect, useState } from "react";
import PartsDropDown from "../../PartsDropDown/PartsDropDown";
import { useDatasContext } from "../../DatasContext/DatasContext";
import { useNavigate, useParams } from "react-router-dom";
import axiosClient from "../../axiosClient/axiosClient";
import PartsFirmModYear from "./PartsFirmModYear";
import Loader from "../../loader/loader";
import UploadedPhts from "./UploadedPhts";

export default function EditPart({
  handleUploadClick,
  setShowAlert,
  setAlertStatus,
}) {
  const navigate = useNavigate();
  const {
    conditionData,
    originalData,
    setTest1,
    test1,
    manufacturer,
    partsInp,
  } = useDatasContext();

  const [allValues, setAllValues] = useState({
    partName: "",
    Parts_models: [],
    condition: "",
    description: "",
    price: "",
    currency: "",

    o_name: "",
    o_phone: "",
    o_city: "",

    vip: "",
  });

  const [condition, setCondition] = useState("");
  const [original, setOriginal] = useState("");
  const [addModel, setAddModel] = useState({
    fyear: "",
    tyear: "",
    firm: "",
    model: "",
  });

  const [data, setData] = useState();

  const [loader, setLoader] = useState(true);

  const params = useParams();

  useEffect(() => {
    axiosClient.get(`user/part/${params.Id}`).then((res) => {
      setLoader(false);
      setData(res.data);
      setAllValues(res.data);
      setOriginal(res.data.original);
    });
  }, [params.Id]);

  const UpdatePart = (e) => {
    e.preventDefault();
    handleUploadClick();

    const form = e.target;
    const formData = new FormData(form);

    formData.append("partName", allValues.partName);
    formData.append("part_models", JSON.stringify(data.Parts_models));
    formData.append("condition", condition);
    formData.append("description", allValues.description);
    formData.append("price", allValues.price);
    formData.append("currency", allValues.currency);

    formData.append("o_name", allValues.o_name);
    formData.append("o_phone", allValues.o_phone);
    formData.append("o_city", allValues.o_city);

    formData.append("vip", allValues.vip);

    axiosClient
      .post(`user/part/${params.Id}`, formData)
      .then((res) => {
        setShowAlert(true);
        setAlertStatus(true);
        goToTopFast();
        navigate("/user/mystatements");
      })
      .catch((err) => {
        setShowAlert(true);
        setAlertStatus(false);
      });
  };

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const handleDelete = (Id) => {
    const newValues = { ...data };
    const filtered = newValues.Parts_models.filter((item) => item.id !== Id);
    newValues.Parts_models = filtered;
    setData(newValues);
  };

  const handleAdd = (data1) => {
    setData({
      ...data,
      Parts_models: [
        ...data.Parts_models,
        {
          id: Math.random(),
          firm: data1.firm,
          model: data1.model,
          fyear: data1.fyear,
          tyear: data1.tyear,
        },
      ],
    });
  };

  const DeleteImgBack = (id) => {
    axiosClient
      .delete(`user/partImage/${id}`)
      .then((res) => {
        DeleteImgFront(id);
      })
      .catch((err) => console.log(err))
      .finally(setLoader(false));
  };
  const DeleteImgFront = (id) => {
    const newData = { ...data };

    newData.parts_imgs = newData.parts_imgs.filter((item) => item.id !== id);
    setData(newData);
  };

  return (
    <div className="w-full flex flex-col gap-7 text-white">
      {loader ? (
        <Loader />
      ) : (
        <form
          onSubmit={UpdatePart}
          encType="multipart/form-data"
          className="flex flex-col gap-7"
        >
          <h1 className="text-[22px] hidden max-lg:flex">
            ნაწილის რედაქტირება
          </h1>
          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">დეტალური ინფორმაცია</p>
            <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]">
              <p>ნაწილი</p>
              <div className="w-[300px] h-[50px] max-sm:w-full">
                <PartsDropDown
                  data={partsInp}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.partName}
                  name="partName"
                />
              </div>
            </div>
            {data &&
              data.Parts_models &&
              data.Parts_models.map((item) => (
                <PartsFirmModYear
                  key={item.id}
                  item={item}
                  manufacturer={manufacturer}
                  test1={test1}
                  setTest1={setTest1}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  handleDelete={handleDelete}
                />
              ))}
            <PartsFirmModYear
              manufacturer={manufacturer}
              test1={test1}
              setTest1={setTest1}
              allValues={addModel}
              setAllValues={setAddModel}
              name="Parts_models"
            />
            <p
              onClick={() => handleAdd(addModel)}
              className="flex self-start h-[40px] items-center justify-center rounded-[10px] px-[10px] cursor-pointer duration-150 shadow-md shadow-[#3d7294] bg-[#1d263a] text-white"
            >
              მოდელის დამატება
            </p>
            <div className="flex items-center justify-between max-tiny:flex-col max-tiny:items-start max-tiny:gap-y-[10px]">
              <div className="flex gap-[20px] items-end">
                <p>მდგომარეობა</p>
                <p className="text-[13px]">{condition ? "ახალი" : "მეორადი"}</p>
              </div>
              <div className=" h-[50px] flex items-center gap-[20px]">
                {conditionData.map((item) => (
                  <p
                    key={item.id}
                    onClick={() => {
                      setCondition(item.text);
                    }}
                    className={`flex items-center justify-center rounded-[10px] h-full px-[10px] cursor-pointer duration-150 ${
                      (condition ? "ახალი" : "მეორადი") === item.text
                        ? "shadow-md shadow-[#3d7294] bg-[#1d263a] text-white    "
                        : " shadow-md shadow-[#304f63] bg-[#252b38] text-gray-400"
                    }`}
                  >
                    {item.text}
                  </p>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-between max-tiny:flex-col max-tiny:items-start max-tiny:gap-y-[10px]">
              <div className="flex gap-[20px] items-end">
                <p>ორიგინალი</p>
                <p className="text-[13px]">{original ? "კი" : "არა"}</p>
              </div>
              <div className=" h-[50px] flex items-center gap-[20px]">
                {originalData.map((item) => (
                  <p
                    key={item.id}
                    onClick={() => {
                      setOriginal((pre) => !pre);
                    }}
                    className={`flex items-center justify-center rounded-[10px] h-full w-[50px] cursor-pointer duration-150 ${
                      (original ? "კი" : "არა") === item.text
                        ? "shadow-md shadow-[#3d7294] bg-[#1d263a] text-white     "
                        : "shadow-md shadow-[#304f63] bg-[#252b38] text-gray-400"
                    }`}
                  >
                    {item.text}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <AddDescr
            allValues={allValues}
            setAllValues={setAllValues}
            firstValue={data && data.description}
            name="description"
          />
          <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
            <p className="hmli text-[17px]">ატვირთული ფოტოები</p>
            <div className="grid grid-cols-4 max-xl:grid-cols-3 max-sm:grid-cols-2 gap-4 items-center">
              {loader ? (
                <Loader />
              ) : (
                data &&
                data.parts_imgs.map((item) => (
                  <UploadedPhts
                    key={item.id}
                    item={item}
                    DeleteImgBack={DeleteImgBack}
                  />
                ))
              )}
            </div>
            <p className="hmli text-[17px]">დაამატე ფოტო</p>
            <AddPhoto inputName={"parts_imgs"} />
          </div>
          <AddProdPrice
            allValues={allValues}
            setAllValues={setAllValues}
            firstValue={data && data.price}
            secValue={data && data.currency}
            name1="price"
            name2="currency"
          />
          <AddContact
            allValues={allValues}
            setAllValues={setAllValues}
            data={data}
          />
          <AddValuableServs
            allValues={allValues}
            setAllValues={setAllValues}
            firstValue={data && data.vip}
            name="vip"
          />
          <div className="w-full rounded-[10px] flex justify-end gap-y-[20px]">
            <button
              onClick={(e) => {
                handleUploadClick();
              }}
              type="submit"
              className="bg-[#0e1420] shadow-md shadow-[#3d7294]
              hover:bg-[#335f7a] duration-200 px-[40px] h-[50px] rounded-[10px] cursor-pointer flex items-center justify-center"
            >
              გამოქვეყნება
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
