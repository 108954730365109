import backgr from "../../../photos/bground.jpg";

import ProductsDropDown from "../../ProductsDropDown";
import { useDatasContext } from "../../DatasContext/DatasContext";
import ProductsMakeModel from "../../ProductsMakeModel";
import { useEffect, useState } from "react";
import Loader from "../../loader/loader";
import axiosClient from "../../axiosClient/axiosClient";
import FirstItemStyle from "../mainPage/FirstItemStyle";
import { AiOutlineClose } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import PriceFilterInp from "./PriceFilterInp";

export default function ProductParts() {
  const { manufacturer, test1, setTest1 } = useDatasContext();
  const [filter, setShowFilter] = useState(false);
  const handleShowFilter = () => {
    setShowFilter((pre) => !pre);
  };
  const [isLoader, setIsLoader] = useState(true);
  const [perPage, setPerPage] = useState(1);
  const [partdata, setPartData] = useState([]);
  const [fulldata, setFulldata] = useState();

  const [filterData, setFilterData] = useState({
    vehicleType: [],
    minPrice: "",
    maxPrice: "",
    minYear: "",
    maxYear: "",
    minMetersRun: "",
    maxMetersRun: "",
    minEngine: "",
    maxEngine: "",
    transmission: "",
    petrol: "",
    weel: "",
    color: "",
    salonColor: "",
    
  });

  useEffect(() => {

    const queryParams = new URLSearchParams({
      page: perPage,
      per_page: 15,
      
      minPrice: filterData.minPrice || "",
      maxPrice: filterData.maxPrice || "",
      minYear: filterData.minYear || "",
      maxYear: filterData.maxYear || "",
      
    }).toString();

    axiosClient
      .get("front/parts")
      .get(
        `front/parts?${queryParams}`
      )
      .then((res) => {
        setPartData(res.data.data);
        setFulldata(res.data.length);
      })
      .finally(() => {
        setIsLoader(false);
      });
  }, [perPage, filterData]);

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const items = Array.from(
    { length: Math.ceil(fulldata / 15 + 1) },
    (_, index) => index
  );

  return (
    <div className="flex flex-col gap-[30px] min-h-[100vh]">
      <div className="relative w-full h-[250px] max-sm:h-[190px]">
        <div className="w-full h-full">
          <img src={backgr} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="flex justify-between px-[100px] max-md:px-[50px] max-sm:px-[16px] text-[30px] text-white w-[100%] absolute top-[135px] max-sm:top-[95px]">
          <h1>ნაწილები</h1>
        </div>
      </div>
      <div
        className={`w-full flex max-lg:flex-col px-[100px] max-md:px-[50px] max-sm:px-[16px] gap-[30px] duration-200 ${
          filter ? "" : "max-lg:gap-[15px]"
        }`}
      >
        <div className="flex justify-center">
          <div
            onClick={() => handleShowFilter()}
            className="gap-[5px] h-[40px] self-start px-[10px] rounded-[10px] cursor-pointer text-white hidden max-lg:flex items-center bg-[black] shadow shadow-[#3d7294]
          active:bg-[#3d7294] duration-200 px"
          >
            {filter ? <AiOutlineClose /> : <FiFilter />}
            <p className="duration-200 ">{filter ? "დახურვა" : "ფილტრი"}</p>
          </div>
        </div>

        <div
          className={`w-[260px]  max-lg:w-full   max-lg:overflow-hidden duration-200 ${
            filter ? "pb-[10px]" : "max-lg:h-0"
          }`}
        >
          <div className="flex flex-col w-full">
            <div className="flex flex-col gap-[10px]">
              <ProductsMakeModel
                data={manufacturer}
                test1={test1}
                setTest1={setTest1}
                title="მწარმოებელი"
              />
              <ProductsMakeModel data={test1} title="მოდელი" />
              <PriceFilterInp searchValue={filterData}
                setSearchValue={setFilterData}/>
              <ProductsDropDown data={"year"} title="წელი" searchValue={filterData}
                setSearchValue={setFilterData}
                name="minYear"
                name1="maxYear"/>
            </div>
            <div className="flex flex-row  gap-2 items-center justify-between mt-4 w-full">
              <p className="shadow-[#3d7294] shadow pointer-events-none leading-[normal] w-[115px] py-2.5 rounded-[10px] text-center text-sm text-white">
                სულ: {partdata.length}
              </p>
              <p
                className="bg-[#0e1420] shadow-md shadow-[#3d7294]
              hover:bg-[#0d344c] duration-200 cursor-pointer leading-[normal] px-[20px] py-2.5 rounded-[10px] text-center text-sm text-white"
              >
                ძებნა
              </p>
            </div>
          </div>
        </div>
        {isLoader ? (
          <div className="w-[calc(100%-320px)] max-lg:w-full">
            <Loader />
          </div>
        ) : (
          <div className="flex flex-col gap-y-[30px] justify-between items-center w-[calc(100%-290px)] max-lg:w-full">
            <div className="w-full grid grid-cols-4 max-2xl:grid-cols-3 max-xl:grid-cols-2 max-lg:grid-cols-3 max-sm:grid-cols-2 max-tiny:grid-cols-1 gap-[20px] gap-y-[40px] ">
              {partdata.map((item) => (
                <FirstItemStyle key={item.id} item={item} />
              ))}
            </div>
            <div className="flex items-center gap-[10px] mb-[80px]">
              {items.map((item) => (
                <div
                  key={item}
                  onClick={() => {
                    setPerPage(item);
                    goToTopFast();
                  }}
                  className={`w-[40px] h-[30px] rounded-[10px] flex items-center justify-center cursor-pointer  duration-100 shadow shadow-[#3d7294] bg-[#0e1420]  text-white ${
                    perPage === item
                      ? "scale-110 bg-[#2d3e64]"
                      : "hover:scale-110 hover:bg-[#192338]"
                  }`}
                >
                  {item + 1}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
