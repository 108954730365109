import { AiOutlineClose } from "react-icons/ai";

export default function UploadedPhts({ item, DeleteImgBack }) {
  return (
    <div
      key={item.id}
      className={` group shadow-md shadow-[#3d7294] bg-[#182540] relative flex items-center
                                             w-full h-[150px] rounded-[10px]
              }`}
    >
      <div className="flex items-center w-full h-full max-sm:flex-col">
        <img
          src={`https://back.carfax.ge/${item.url}`}
          className={`w-full h-full object-cover 
                      rounded-[6px]
                  }`}
          alt="#"
        />
      </div>
      <div
        onClick={() => {
          DeleteImgBack(item.id);
        }}
        className="rounded-full absolute group-hover:flex max-lg:flex top-[7px] right-[10px] w-8 h-8 bg-[#21335a] cursor-pointer hidden items-center justify-center"
      >
        <AiOutlineClose className="text-gray-100 text-2xl" />
      </div>
    </div>
  );
}
