import { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineCheck } from "react-icons/ai";

export default function MoreInfoButtons({
  text,
  allValues,
  setAllValues,
  firstValue,
  name,
}) {
  const [select, setSelect] = useState(false);

  const handleSelect = () => {
    setSelect((pre) => !pre);
  };

  useEffect(() => {
    if (setAllValues) {
      const newValue = select ? 'true': 'false'
      setAllValues({ ...allValues, [name]: newValue });
    }
  }, [select]);

  useEffect(() => {
    if(firstValue === 'true')
    {
      setSelect(true);
    }
  }, [firstValue]);

  return (
    <div
      onClick={() => {
        handleSelect();
      }}
      className={`flex justify-between w-full h-[60px] duration-200 items-center px-[10px] rounded-[10px] cursor-pointer
                    
                ${
                  select
                    ? "bg-[#335f7a] text-white"
                    : "shadow-md shadow-[#3d7294] bg-[#0e1420]"
                }
                `}
    >
      <p className="text-[14px]">{text}</p>
      <div
        className={`w-[30px] h-[30px] rounded-full flex items-center justify-center duration-200 
        ${
          select
            ? "bg-[#3d7294] border-[1px] text-[22px] text-white"
            : "bg-[#335f7a]"
        }  `}
      >
        {select ? <AiOutlineCheck /> : <AiOutlinePlus />}
      </div>
    </div>
  );
}
