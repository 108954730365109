import { useDatasContext } from "../../DatasContext/DatasContext";
import DropDownAbsolute from "../../DropDownAbsolute";
import MyInput from "../../MyInput";

export default function AddContact({
  allValues,
  setAllValues,
  data,
  isService,
}) {
  const { adress } = useDatasContext();
  return (
    <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
      <p className="hmli text-[17px]">კონტაქტი</p>

      <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]
">
        <p>{isService ? "კომპანიის სახელი" : "სახელი"}</p>
        <div className="w-[200px] h-[50px] max-sm:w-full">
          <MyInput
            allValues={allValues}
            setAllValues={setAllValues}
            firstValue={isService ? data && data.servcenterName : data && data.o_name}
            name={isService ? "servcenterName" : "o_name"}
          />
        </div>
      </div>
      <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]
">
        <p>ტელეფონის ნომერი</p>
        <div className="w-[200px] h-[50px] max-sm:w-full">
          <MyInput
            allValues={allValues}
            setAllValues={setAllValues}
            firstValue={isService ? data && data.phone : data && data.o_phone}
            name={isService ? "phone" : "o_phone"}
            itsTellNum={true}
          />
        </div>
      </div>
      {!isService && (
        <div className="flex items-center justify-between max-sm:flex-col max-sm:items-start max-sm:gap-y-[10px]
        ">
          <p>მდებარეობა</p>
          <div className="w-[200px] h-[50px] max-sm:w-full">
            <DropDownAbsolute
              data={adress}
              allValues={allValues}
              setAllValues={setAllValues}
              firstValue={data && data.o_city}
              name={isService ? "location" : "o_city"}
            />
          </div>
        </div>
      )}
    </div>
  );
}
