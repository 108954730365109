import React, { useContext, useState } from "react";
import { BsArrowRightCircle, BsFillPersonFill, BsList } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import logo from "../../photos/logo.png";
import LoginRegister from "../authorizationPopup/AuthorizationPopup";
import { UserContext } from "../UserSettingContext/AxiosContext";
import MyAlert from "../MyAlert";
import { CurrencyContext } from "../CurrencyContext/CurrencyContext";

const Header = ({ setShowMenu, logOut }) => {
  const { currency, setCurrency, userChooseCurrency, setUserChooseCurrency } =
    useContext(CurrencyContext);

  const location = useLocation();

  const { isAuthorized } = useContext(UserContext);

  const [logreg, setLogreg] = useState(false);
  const handleLogreg = () => {
    setLogreg((pre) => !pre);
  };

  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const handleAlertClose = () => {
    goToTopFast();
    setTimeout(() => {
      setShowAlert(false);
    }, 2200);
  };

  return (
    <div
      className={`absolute top-0 flex px-[100px] max-md:px-[50px] max-sm:px-[16px] h-[100px] max-sm:h-[80px] items-center justify-between w-full z-[11] ${
        (location.pathname.split("/")[1] === "adminPanel" ||
          location.pathname.split("/")[1] === "ErrorPage" ||
          location.pathname.split("/")[1] === "admin") &&
        "hidden"
      }`}
    >
      <div className="flex w-[200px] max-sm:w-[150px]  items-center justify-start">
        <Link to="/" className="w-full">
          <img className="w-full object-cover" src={logo} alt="logo" />
        </Link>
      </div>

      <div className="flex gap-7 items-center justify-center max-xl:hidden">
        <h1 className="text-white">ვინ კოდის შემოწმება</h1>
        <Link to="/productcars">
          <h1 className="text-white">ავტომობილები</h1>
        </Link>
        <Link to="/productparts">
          <h1 className="text-white">ნაწილები</h1>
        </Link>
        <Link to="/services">
          <h1 className="text-white">სერვის ცენტრები</h1>
        </Link>
      </div>

      <div className="flex items-center gap-[10px]">
        <div
          onClick={() =>
            setUserChooseCurrency(userChooseCurrency === "GEL" ? "USD" : "GEL")
          }
          className="border border-solid select-none border-white text-[25px] text-white bg-[#00000095] hover:bg-[#000000c4] duration-200 cursor-pointer flex items-center justify-center w-[40px] h-[40px] rounded-[20px]"
        >
          {userChooseCurrency === "GEL" ? "₾" : "$"}
        </div>
        {isAuthorized ? (
          <div className="flex items-center gap-[10px]">
            <Link to="/user/addcar">
              <div className="border border-solid border-white text-[25px] text-white bg-[#00000095] hover:bg-[#000000c4] duration-200 cursor-pointer flex items-center justify-center w-[40px] h-[40px] rounded-[20px] ">
                <BsFillPersonFill />
              </div>
            </Link>
            <div
              onClick={() => {
                logOut();
              }}
              className="border border-solid border-white bg-[#00000095] hover:bg-[#000000c4] duration-200 cursor-pointer flex max-sm:hidden flex-row gap-[7px]  items-center justify-center px-[24px] h-[40px] rounded-[20px] "
            >
              <BsArrowRightCircle className="text-white h-6 w-6 ml-1 text-white-A700" />
              <h1 className="   text-white">გასვლა</h1>
            </div>
          </div>
        ) : (
          <div
            onClick={handleLogreg}
            className="border border-solid border-white bg-[#00000095] hover:bg-[#000000c4] duration-200 cursor-pointer flex  flex-row gap-[7px]  items-center justify-center w-[200px] max-sm:w-[170px] max-tiny:w-[140px] h-[40px] max-sm:h-[35px] rounded-[20px] "
          >
            <BsArrowRightCircle className="h-6 w-6 max-sm:h-5 max-sm:w-5 ml-1 text-white max-tiny:hidden" />
            <h1 className="text-white max-sm:text-[14px]">ავტორიზაცია</h1>
          </div>
        )}

        {logreg && (
          <LoginRegister
            setLogreg={setLogreg}
            handleLogreg={handleLogreg}
            setShowAlert={setShowAlert}
            setAlertStatus={setAlertStatus}
            handleAlertClose={handleAlertClose}
          />
        )}

        <div
          onClick={() => setShowMenu(true)}
          className="hidden text-white cursor-pointer max-xl:flex items-center justify-center text-5xl max-sm:text-4xl"
        >
          <BsList />
        </div>
      </div>

      <MyAlert
        showAlert={showAlert}
        alertStatus={alertStatus}
        text={alertStatus ? "ავტორიზაცია შესრულდა" : "გადაამოწმე ინფორმაცია"}
      />
    </div>
  );
};

export default Header;
