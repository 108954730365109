import { useEffect, useRef, useState } from "react";
import { PiCaretDownLight } from "react-icons/pi";
import { AiOutlineClose } from "react-icons/ai";

export default function PartsYearFromTo({ data, name1, name2, firstValue1, firstValue2, setAllValues, allValues }) {
  const dropRef = useRef(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setDrop(false);
    }
  }

  const [drop, setDrop] = useState(false);

  const handleDrop = () => {
    setDrop((pre) => !pre);
  };

  const [yearFrom, setYearFrom] = useState("დან");
  const [yearBis, setYearBis] = useState("მდე");

  const year = [];
  for (let yearr = 2024; yearr > 1949; yearr -= 1) {
    year.push(yearr);
  }

  useEffect(() => {
    if(setAllValues){
      setAllValues({...allValues, [name1]:yearFrom, [name2]:yearBis})
    }
  },[yearFrom, yearBis])

  useEffect(()=>{
    if(firstValue1){
        setYearFrom(firstValue1)
      setAllValues({...allValues, [name1]:firstValue1})
    }
    if(firstValue2){
        setYearBis(firstValue2)
      setAllValues({...allValues, [name2]:firstValue2})
    }
  },[firstValue1, firstValue2])

  return (
    <div
      ref={dropRef}
      className={`shadow-md relative rounded-[10px] duration-150 
          shadow-[#3d7294] bg-[#0e1420] text-white
      `}
    >
      <div
        onClick={() => {
          handleDrop();
        }}
        className=" h-[50px] px-[10px] flex items-center justify-between cursor-pointer"
      >
        <div className="flex items-center gap-[20px]">
          <p>{yearFrom}</p>
          <p className="border-t-[3px] w-[40px] border-t-[white] rounded-full"></p>
          <p>{yearBis}</p>
        </div>

        <div className="flex items-center justify-center gap-[10px]">
          <AiOutlineClose
            onClick={() => {
              setYearFrom("დან");
              setYearBis("მდე");
            }}
            className={` ${
              yearFrom !== "დან" || yearBis !== "მდე" ? "flex" : "hidden"
            }`}
          />
          <PiCaretDownLight
            className={`${drop ? "rotate-180 duration-200" : "duration-200"}`}
          />
        </div>
      </div>

      <div className={`z-[1] w-full absolute justify-around rounded-[10px] top-[60px] shadow-md shadow-[#3d7294] bg-[#0e1420] border-[1px] border-[#3d7294] duration-150 h-[200px] p-[5px] ${drop ? "flex" : "hidden"
        }`}
      >
        <div className="w-[100px]">
          <p className="h-[30px] px-[10px]">დან</p>
          <div className="showScrollbar bg-[#141d2d] rounded-[8px] h-[155px] overflow-y-scroll">
            {data === "year" &&
              year.map((yearr, index) => (
                <p
                  className="cursor-pointer px-[10px] rounded-[5px] hover:bg-[#203f52] duration-100"
                  onClick={() => {
                    setYearFrom(yearr.toFixed(0));
                  }}
                  key={index}
                >
                  {yearr.toFixed(0)}
                </p>
              ))}
          </div>
        </div>
        <div className="w-[100px]">
          <p className="h-[30px] px-[10px]">მდე</p>
          <div className="showScrollbar bg-[#141d2d] rounded-[8px] h-[155px] overflow-y-scroll">
            {data === "year" &&
              year.map((yearr, index) => (
                <p
                  className="cursor-pointer px-[10px] rounded-[5px] hover:bg-[#203f52] duration-100"
                  onClick={() => {
                    setYearBis(yearr.toFixed(0));
                  }}
                  key={index}
                >
                  {yearr.toFixed(0)}
                </p>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
