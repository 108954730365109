import React, { createContext, useContext, useState } from "react";

import car1 from "../../photos/bmw.webp";
import car2 from "../../photos/carimage.jpg";
import car3 from "../../photos/hyundai.webp";
import car4 from "../../photos/jetta.jpg";
import car5 from "../../photos/mercedes.avif";
import car6 from "../../photos/opel.jpg";
import car7 from "../../photos/shkoda.jpg";

import part1 from "../../photos/part1.jpg";
import part2 from "../../photos/parts2.jpg";
import part3 from "../../photos/parts3.jpg";
import part4 from "../../photos/parts4.jpg";
import part5 from "../../photos/parts5.jpg";

const DatasContext = createContext();

export function useDatasContext() {
  return useContext(DatasContext);
}

export function DatasContextProvider({ children }) {
  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const [test1, setTest1] = useState([]);

  const [cardata, setCardata] = useState([
    {
      id: 1,
      title: "Bmw",
      img: car1,
    },
    {
      id: 2,
      title: "Audi",
      img: car2,
    },
    {
      id: 3,
      title: "Hunday",
      img: car3,
    },
    {
      id: 4,
      title: "Volkswagen",
      img: car4,
    },
    {
      id: 5,
      title: "Mercedes-benz",
      img: car5,
    },
    {
      id: 6,
      title: "Opel",
      img: car6,
    },
    {
      id: 7,
      title: "Shkoda",
      img: car7,
    },
    {
      id: 8,
      title: "Bmw",
      img: car1,
    },
    {
      id: 9,
      title: "Audi",
      img: car2,
    },
    {
      id: 10,
      title: "Hunday",
      img: car3,
    },
    {
      id: 11,
      title: "Volkswagen",
      img: car4,
    },
    {
      id: 12,
      title: "Mercedes-benz",
      img: car5,
    },
    {
      id: 13,
      title: "Opel",
      img: car6,
    },
    {
      id: 14,
      title: "Shkoda",
      img: car7,
    },
  ]);
  const [partsdata, setPartsdata] = useState([
    {
      id: 1,
      title: "ბაბინა",
      model: "Mercedes-benz",
      img: part1,
    },
    {
      id: 2,
      title: "პრაგლატკა",
      model: "Bmw",
      img: part2,
    },
    {
      id: 3,
      title: "შატუნი",
      model: "Shkoda",
      img: part3,
    },
    {
      id: 4,
      title: "დისკი",
      model: "Opel",
      img: part4,
    },
    {
      id: 5,
      title: "ხიდი",
      model: "Volkswagen",
      img: part5,
    },
    {
      id: 6,
      title: "კარობკა",
      model: "Bmw",
      img: part1,
    },
    {
      id: 7,
      title: "ფარები",
      model: "Mercedes-benz",
      img: part2,
    },
    {
      id: 8,
      title: "რიჩაგი",
      model: "Shkoda",
      img: part3,
    },
    {
      id: 9,
      title: "პადკარობაჩნი",
      model: "Renault",
      img: part4,
    },
    {
      id: 10,
      title: "ლაბავოი",
      model: "Opel",
      img: part5,
    },
    {
      id: 11,
      title: "კალოდკები",
      model: "Mercedes-benz",
      img: part1,
    },
    {
      id: 12,
      title: "შკივი",
      model: "Bmw",
      img: part2,
    },
    {
      id: 13,
      title: "რული",
      model: "Shevrolet",
      img: part3,
    },
    {
      id: 14,
      title: "სიდენია",
      model: "Mercedes-benz",
      img: part4,
    },
  ]);
  const [fuel, setFuel] = useState([
    {
      id: 1,
      text: "ბენზინი",
    },
    {
      id: 2,
      text: "გაზი",
    },
    {
      id: 3,
      text: "დიზელი",
    },
    {
      id: 4,
      text: "ჰიბრიდი",
    },
    {
      id: 5,
      text: "გაზი/ბენზინი",
    },
  ]);
  const [weel, setWeel] = useState([
    {
      id: 1,
      text: "მარჯვენა",
    },
    {
      id: 2,
      text: "მარცხენა",
    },
  ]);
  const [colors, setColors] = useState([
    {
      id: 1,
      text: "თეთრი",
      color: "white",
    },
    {
      id: 2,
      text: "შავი",
      color: "black",
    },
    {
      id: 3,
      text: "ვერცხლისფერი",
      color: "#e0e0e0",
    },
    {
      id: 4,
      text: "რუხი",
      color: "#7f7f7f",
    },
    {
      id: 5,
      text: "წითელი",
      color: "red",
    },
    {
      id: 6,
      text: "ლურჯი",
      color: "blue",
    },
    {
      id: 7,
      text: "ყვითელი",
      color: "yellow",
    },
    {
      id: 8,
      text: "მწვანე",
      color: "green",
    },
    {
      id: 9,
      text: "ნარინჯისფერი",
      color: "orange",
    },
    {
      id: 10,
      text: "ოქროსფერი",
      color: "#7f7f7f",
    },
    {
      id: 11,
      text: "იისფერი",
      color: "#8c3bf0",
    },
    {
      id: 12,
      text: "ვარდისფერი",
      color: "pink",
    },
    {
      id: 13,
      text: "ჩალისფერი",
      color: "#ede1d3",
    },
    {
      id: 14,
      text: "შინდისფერი",
      color: "#990000",
    },
    {
      id: 15,
      text: "ცისფერი",
      color: "#a2d2ff",
    },
    {
      id: 16,
      text: "ყავისფერი",
      color: "brown",
    },
  ]);
  const [transmission, setTransmission] = useState([
    {
      id: 1,
      text: "მექანიკა",
    },
    {
      id: 2,
      text: "ავტომატიკა",
    },
    {
      id: 3,
      text: "ტიპტრონიკი",
    },
    {
      id: 4,
      text: "ვარიატორი",
    },
  ]);
  const [vehicleType, setVehicleType] = useState([
    {
      id: 1,
      text: "სედანი",
    },
    {
      id: 2,
      text: "ჯიპი",
    },
    {
      id: 3,
      text: "კუპე",
    },
    {
      id: 4,
      text: "ჰეტჩბექი",
    },
    {
      id: 5,
      text: "უნივერსალი",
    },
    {
      id: 6,
      text: "კაბრიოლეტი",
    },
    {
      id: 7,
      text: "პიკაპი",
    },
    {
      id: 8,
      text: "მინივენი",
    },
    {
      id: 9,
      text: "მიკროავტობუსი",
    },
    {
      id: 10,
      text: "ფურგონი",
    },
    {
      id: 11,
      text: "ლიმუზინი",
    },
    {
      id: 12,
      text: "კროსოვერი",
    },
  ]);
  const [cylinders, setCylinders] = useState([
    {
      id: 1,
      text: "1",
    },
    {
      id: 2,
      text: "2",
    },
    {
      id: 3,
      text: "3",
    },
    {
      id: 4,
      text: "4",
    },
    {
      id: 5,
      text: "5",
    },
    {
      id: 6,
      text: "6",
    },
    {
      id: 7,
      text: "7",
    },
    {
      id: 8,
      text: "8",
    },
    {
      id: 9,
      text: "9",
    },
    {
      id: 10,
      text: "10",
    },
    {
      id: 11,
      text: "11",
    },
    {
      id: 12,
      text: "12",
    },
    {
      id: 13,
      text: "13",
    },
    {
      id: 14,
      text: "14",
    },
    {
      id: 15,
      text: "15",
    },
    {
      id: 16,
      text: "16",
    },
  ]);
  const [country, setCountry] = useState([
    {
      id: 1,
      text: "გზაში საქ-კენ",
      cities: [],
    },
    {
      id: 2,
      text: "საქართველო",
      cities: [
        {
          id: 1,
          city: "თბილისი",
        },
        {
          id: 2,
          city: "ქუთაისი",
        },
        {
          id: 3,
          city: "ბათუმი",
        },
        {
          id: 4,
          city: "ფოთი",
        },
        {
          id: 5,
          city: "რუსთავი",
        },
        {
          id: 6,
          city: "რუსთავის ავტობაზრობა",
        },
        {
          id: 7,
          city: "კავკასიის ავტომარკეტი",
        },
        {
          id: 8,
          city: "ჩხოროწყუ",
        },
        {
          id: 9,
          city: "ახმეტა",
        },
        {
          id: 10,
          city: "ზესტაფონი",
        },
        {
          id: 11,
          city: "ბორჯომი",
        },
        {
          id: 12,
          city: "კასპი",
        },
        {
          id: 13,
          city: "საგარეჯო",
        },
        {
          id: 14,
          city: "ახალციხე",
        },
        {
          id: 15,
          city: "სოხუმი",
        },
        {
          id: 16,
          city: "ქობულეთი",
        },
        {
          id: 17,
          city: "გურჯაანი",
        },
        {
          id: 18,
          city: "მარტვილი",
        },
        {
          id: 19,
          city: "ჭიათურა",
        },
        {
          id: 20,
          city: "დუშეთი",
        },
        {
          id: 21,
          city: "ლაგოდეხი",
        },
        {
          id: 22,
          city: "თელავი",
        },
        {
          id: 23,
          city: "მცხეთა",
        },
        {
          id: 24,
          city: "ცხინვალი",
        },
        {
          id: 25,
          city: "ახალქალაქი",
        },
        {
          id: 26,
          city: "გორი",
        },
        {
          id: 27,
          city: "ხაშური",
        },
        {
          id: 28,
          city: "ამბროლაური",
        },
        {
          id: 29,
          city: "ოზურგეთი",
        },
        {
          id: 30,
          city: "ზუგდიდი",
        },
        {
          id: 31,
          city: "სენაკი",
        },
        {
          id: 32,
          city: "სიღნაღი",
        },
        {
          id: 33,
          city: "ქარელი",
        },
        {
          id: 34,
          city: "მარნეული",
        },
        {
          id: 35,
          city: "გარდაბანი",
        },
        {
          id: 36,
          city: "სამტრედია",
        },
        {
          id: 37,
          city: "მესტია",
        },
        {
          id: 38,
          city: "საჩხერე",
        },
        {
          id: 39,
          city: "ხობი",
        },
        {
          id: 40,
          city: "თიანეთი",
        },
        {
          id: 41,
          city: "ფოთის 'გეზ'-ი",
        },
        {
          id: 42,
          city: "ბათუმის 'გეზ'-ი",
        },
        {
          id: 43,
          city: "ყვარელი",
        },
        {
          id: 44,
          city: "ტყიბული",
        },
        {
          id: 45,
          city: "დედოფლისწყარო",
        },
        {
          id: 46,
          city: "ონი",
        },
        {
          id: 47,
          city: "ბოლნისი",
        },
        {
          id: 48,
          city: "წყალტუბო",
        },
        {
          id: 49,
          city: "თეთრიწყარო",
        },
        {
          id: 50,
          city: "თიანეთი",
        },
        {
          id: 51,
          city: "ხარაგაული",
        },
        {
          id: 52,
          city: "წალკა",
        },
        {
          id: 53,
          city: "წალენჯიხა",
        },
        {
          id: 54,
          city: "წეროვანი",
        },
        {
          id: 55,
          city: "ლანჩხუთი",
        },
        {
          id: 56,
          city: "სართიჭალა",
        },
        {
          id: 57,
          city: "ხონი",
        },
        {
          id: 58,
          city: "ნინოწმინდა",
        },
        {
          id: 59,
          city: "ასპინძა",
        },
        {
          id: 60,
          city: "აბაშა",
        },
        {
          id: 61,
          city: "ცაგერი",
        },
      ],
    },
    {
      id: 3,
      text: "საზღვარგარეთი",
      cities: [
        {
          id: 1,
          city: "აშშ",
        },
        {
          id: 2,
          city: "გერმანია",
        },
        {
          id: 3,
          city: "იაპონია",
        },
        {
          id: 4,
          city: "ევროპა",
        },
        {
          id: 5,
          city: "საფრანგეთი",
        },
        {
          id: 6,
          city: "ესპანეთი",
        },
        {
          id: 7,
          city: "სამხ. კორეა",
        },
        {
          id: 8,
          city: "რუსეთი",
        },
        {
          id: 9,
          city: "ირლანდია",
        },
        {
          id: 10,
          city: "ჰოლანდია",
        },
        {
          id: 11,
          city: "იტალია",
        },
        {
          id: 12,
          city: "დუბაი",
        },
        {
          id: 13,
          city: "ინგლისი",
        },
        {
          id: 14,
          city: "სხვა",
        },
        {
          id: 15,
          city: "უკრანია",
        },
        {
          id: 16,
          city: "ჩინეთი",
        },
        {
          id: 17,
          city: "კანადა",
        },
        {
          id: 18,
          city: "თურქეთი",
        },
        {
          id: 19,
          city: "პოლონეთი",
        },
      ],
    },
  ]);
  const [adress, setAdress] = useState([
    {
      id: 1,
      text: "თბილისი",
    },
    {
      id: 2,
      text: "ქუთაისი",
    },
    {
      id: 3,
      text: "ბათუმი",
    },
    {
      id: 4,
      text: "ფოთი",
    },
    {
      id: 5,
      text: "რუსთავი",
    },
    {
      id: 6,
      text: "რუსთავის ავტობაზრობა",
    },
    {
      id: 7,
      text: "კავკასიის ავტომარკეტი",
    },
    {
      id: 8,
      text: "ჩხოროწყუ",
    },
    {
      id: 9,
      text: "ახმეტა",
    },
    {
      id: 10,
      text: "ზესტაფონი",
    },
    {
      id: 11,
      text: "ბორჯომი",
    },
    {
      id: 12,
      text: "კასპი",
    },
    {
      id: 13,
      text: "საგარეჯო",
    },
    {
      id: 14,
      text: "ახალციხე",
    },
    {
      id: 15,
      text: "სოხუმი",
    },
    {
      id: 16,
      text: "ქობულეთი",
    },
    {
      id: 17,
      text: "გურჯაანი",
    },
    {
      id: 18,
      text: "მარტვილი",
    },
    {
      id: 19,
      text: "ჭიათურა",
    },
    {
      id: 20,
      text: "დუშეთი",
    },
    {
      id: 21,
      text: "ლაგოდეხი",
    },
    {
      id: 22,
      text: "თელავი",
    },
    {
      id: 23,
      text: "მცხეთა",
    },
    {
      id: 24,
      text: "ცხინვალი",
    },
    {
      id: 25,
      text: "ახალქალაქი",
    },
    {
      id: 26,
      text: "გორი",
    },
    {
      id: 27,
      text: "ხაშური",
    },
    {
      id: 28,
      text: "ამბროლაური",
    },
    {
      id: 29,
      text: "ოზურგეთი",
    },
    {
      id: 30,
      text: "ზუგდიდი",
    },
    {
      id: 31,
      text: "სენაკი",
    },
    {
      id: 32,
      text: "სიღნაღი",
    },
    {
      id: 33,
      text: "ქარელი",
    },
    {
      id: 34,
      text: "მარნეული",
    },
    {
      id: 35,
      text: "გარდაბანი",
    },
    {
      id: 36,
      text: "სამტრედია",
    },
    {
      id: 37,
      text: "მესტია",
    },
    {
      id: 38,
      text: "საჩხერე",
    },
    {
      id: 39,
      text: "ხობი",
    },
    {
      id: 40,
      text: "თიანეთი",
    },
    {
      id: 41,
      text: "ფოთის 'გეზ'-ი",
    },
    {
      id: 42,
      text: "ბათუმის 'გეზ'-ი",
    },
    {
      id: 43,
      text: "ყვარელი",
    },
    {
      id: 44,
      text: "ტყიბული",
    },
    {
      id: 45,
      text: "დედოფლისწყარო",
    },
    {
      id: 46,
      text: "ონი",
    },
    {
      id: 47,
      text: "ბოლნისი",
    },
    {
      id: 48,
      text: "წყალტუბო",
    },
    {
      id: 49,
      text: "თეთრიწყარო",
    },
    {
      id: 50,
      text: "თიანეთი",
    },
    {
      id: 51,
      text: "ხარაგაული",
    },
    {
      id: 52,
      text: "წალკა",
    },
    {
      id: 53,
      text: "წალენჯიხა",
    },
    {
      id: 54,
      text: "წეროვანი",
    },
    {
      id: 55,
      text: "ლანჩხუთი",
    },
    {
      id: 56,
      text: "სართიჭალა",
    },
    {
      id: 57,
      text: "ხონი",
    },
    {
      id: 58,
      text: "ნინოწმინდა",
    },
    {
      id: 59,
      text: "ასპინძა",
    },
    {
      id: 60,
      text: "აბაშა",
    },
    {
      id: 61,
      text: "ცაგერი",
    },
  ]);
  const [pullingWheels, setPullingWheels] = useState([
    {
      id: 1,
      text: "წინა",
    },
    {
      id: 2,
      text: "უკანა",
    },
    {
      id: 3,
      text: "4x4",
    },
  ]);
  const [leather, setLeather] = useState([
    {
      id: 1,
      text: "ნაჭერი",
    },
    {
      id: 2,
      text: "ტყავი",
    },
    {
      id: 3,
      text: "ხელოვნური ტყავი",
    },
    {
      id: 4,
      text: "კომბინირებული",
    },
    {
      id: 5,
      text: "ალკანტარა",
    },
  ]);
  const [manufacturer, setManufacturer] = useState([
    {
      id: 1,
      make: "BMW",
      models: [
        {
          id: 1,
          make: "1 series",
        },
        {
          id: 2,
          make: "2 series",
        },
        {
          id: 3,
          make: "3 series",
        },
      ],
    },
    {
      id: 2,
      make: "Mercedes-benz",
      models: [
        {
          id: 1,
          make: "A class",
        },
        {
          id: 2,
          make: "B class",
        },
        {
          id: 3,
          make: "C class",
        },
      ],
    },
    {
      id: 3,
      make: "Opel",
      models: [
        {
          id: 1,
          make: "vectra",
        },
        {
          id: 2,
          make: "cadet",
        },
        {
          id: 3,
          make: "astra",
        },
      ],
    },
  ]);
  const [days, setDays] = useState([
    {
      id: 1,
    },
    {
      id: 2,
    },
    {
      id: 3,
    },
    {
      id: 4,
    },
    {
      id: 5,
    },
    {
      id: 6,
    },
    {
      id: 7,
    },
    {
      id: 8,
    },
    {
      id: 9,
    },
    {
      id: 10,
    },
    {
      id: 11,
    },
    {
      id: 12,
    },
    {
      id: 13,
    },
    {
      id: 14,
    },
    {
      id: 15,
    },
    {
      id: 16,
    },
    {
      id: 17,
    },
    {
      id: 18,
    },
    {
      id: 19,
    },
    {
      id: 20,
    },
    {
      id: 21,
    },
    {
      id: 22,
    },
    {
      id: 23,
    },
    {
      id: 24,
    },
    {
      id: 25,
    },
    {
      id: 26,
    },
    {
      id: 27,
    },
    {
      id: 28,
    },
    {
      id: 29,
    },
    {
      id: 30,
    },
  ]);
  const [conditionData, setConditionData] = useState([
    {
      id: 1,
      text: "ახალი",
    },
    {
      id: 2,
      text: "მეორადი",
    },
  ]);
  const [originalData, setOriginaldata] = useState([
    {
      id: 1,
      text: "კი",
    },
    {
      id: 2,
      text: "არა",
    },
  ]);
  const [partsInp, setPartsInp] = useState([
    {
      id: 1,
      part1: "ძრავი",
      partIn1: [
        {
          id: 1,
          part2: "პრაგლატკა",
          partIn2: [
            {
              id: 1,
              part3: "პრაგლატკა1",
            },
            {
              id: 2,
              part3: "პრაგლატკა2",
            },
          ],
        },
        {
          id: 2,
          part2: "შატუნი",
          partIn2: [
            {
              id: 1,
              part3: "შატუნი1",
            },
            {
              id: 2,
              part3: "შატუნი2",
            },
            {
              id: 3,
              part3: "შატუნი3",
            },
          ],
        },
        {
          id: 3,
          part2: "დროსელი",
          partIn2: [
            {
              id: 1,
              part3: "დროსელი1",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      part1: "ფარები",
      partIn1: [
        {
          id: 1,
          part2: "წინა ფარები",
          partIn2: [
            {
              id: 1,
              part3: "წინა ფარები1",
            },
            {
              id: 2,
              part3: "წინა ფარები2",
            },
          ],
        },
        {
          id: 2,
          part2: "უკანა ფარები",
          partIn2: [
            {
              id: 1,
              part3: "უკანა ფარები1",
            },
            {
              id: 2,
              part3: "უკანა ფარები2",
            },
            {
              id: 3,
              part3: "უკანა ფარები3",
            },
          ],
        },
        {
          id: 3,
          part2: "პავაროტნიკები",
          partIn2: [
            {
              id: 1,
              part3: "პავაროტნიკები1",
            },
          ],
        },
      ],
    },
  ]);
  const [workinghours, setWorkinghours] = useState([
    {
      id: 1,
      title: "ღია განსაზღვრულ საათებში",
      coTitle: "აირჩიეთ საკუთარი საათები",
    },
    {
      id: 2,
      title: "მუდმივად ღიაა",
      coTitle: "24 საათის განმავლობაში მუშაობს",
    },
    {
      id: 3,
      title: "დაკეტილია",
      coTitle: "სერვისცენტრი არ ფუნქიონირებს",
    },
  ]);
  const [weekdays, setWeekDays] = useState([
    {
      id: 1,
      status: false,
      day: "ორშაბათი",
    },
    {
      id: 2,
      status: false,
      day: "სამშაბათი",
    },
    {
      id: 3,
      status: false,
      day: "ოთხშაბათი",
    },
    {
      id: 4,
      status: false,
      day: "ხუთშაბათი",
    },
    {
      id: 5,
      status: false,
      day: "პარასკევი",
    },
    {
      id: 6,
      status: false,
      day: "შაბათი",
    },
    {
      id: 7,
      status: false,
      day: "კვირა",
    },
  ]);
  const [servicetype, setServiceType] = useState([
    {
      id: 1,
      text: "ძრავი",
    },
    {
      id: 2,
      text: "სავალი ნაწილი",
    },
    {
      id: 3,
      text: "ქიმწმენდა",
    },
    {
      id: 4,
      text: "ვულკანიზაცია",
    },
    {
      id: 5,
      text: "ელ-დიაგნოსტიკა",
    },
    {
      id: 6,
      text: "მინების დაბურვა",
    },
    {
      id: 7,
      text: "მეთუნუქე",
    },
  ]);
  const [transactionsData, setTransactionsData] = useState([
    {
      id: 1,
      amount: "200",
      date: "01/05/2021",
    },
    {
      id: 2,
      amount: "50",
      date: "11/08/2021",
    },
    {
      id: 3,
      amount: "65",
      date: "01/12/2021",
    },
    {
      id: 4,
      amount: "12",
      date: "15/10/2021",
    },
  ]);
  const [parameters, setParameters] = useState([
    {
      id: 1,
      text: "ლუქი",
    },
    {
      id: 2,
      text: "მულტიმედია",
    },
    {
      id: 3,
      text: "პანორამა",
    },
    {
      id: 4,
      text: "კამერა",
    },
  ]);


  const datascontextValue = {
    goToTopFast,
    test1,
    setTest1,
    cardata,
    partsdata,
    fuel,
    weel,
    colors,
    transmission,
    vehicleType,
    cylinders,
    country,
    pullingWheels,
    leather,
    manufacturer,
    adress,
    days,
    conditionData,
    originalData,
    partsInp,
    workinghours,
    weekdays,
    transactionsData,
    servicetype,
    parameters
  };

  return (
    <DatasContext.Provider value={datascontextValue}>
      {children}
    </DatasContext.Provider>
  );
}
