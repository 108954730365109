import backgr from "../../../photos/bground.jpg";
import SecondItemStyle from "../mainPage/SecondItemStyle";

import ProductsDropDown from "../../ProductsDropDown";
import { useDatasContext } from "../../DatasContext/DatasContext";
import ProductsMakeModel from "../../ProductsMakeModel";
import MyInput from "../../MyInput";
import { useContext, useEffect, useState } from "react";
import axiosClient from "../../axiosClient/axiosClient";
import Loader from "../../loader/loader";
import { FiFilter } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { ScreenWidth } from "../../ScreenWidth";
import PriceFilterInp from "./PriceFilterInp";
import { RunningTypeContext } from "../../runningTypeContext/RunningTypeContext";

export default function ProductCar() {
  const screenWidth = ScreenWidth();
  const [isLoader, setIsLoader] = useState(true);
  const [perPage, setPerPage] = useState(1);

  const [newItems, setNewItems] = useState([]);
  const {
    fuel,
    weel,
    parameters,
    colors,
    transmission,
    vehicleType,
    manufacturer,
    test1,
    setTest1,
  } = useDatasContext();

  const [filter, setShowFilter] = useState(false);
  const handleShowFilter = () => {
    setShowFilter((pre) => !pre);
  };

  const { runType, setRunType } = useContext(RunningTypeContext);

  const [cardata, setCarData] = useState([]);
  const [fulldata, setFulldata] = useState();

  const [filterData, setFilterData] = useState({
    vehicleType: [],
    minPrice: "",
    maxPrice: "",
    minYear: "",
    maxYear: "",
    minMetersRun: "",
    maxMetersRun: "",
    minEngine: "",
    maxEngine: "",
    transmission: "",
    petrol: "",
    weel: "",
    color: "",
    salonColor: "",
    Hatch: newItems.filter((item) => item === "ლუქი")[0] ? true : "",
    multimedia: newItems.filter((item) => item === "მულტიმედია")[0] ? true : "",
    Panorama: newItems.filter((item) => item === "პანორამა")[0] ? true : "",
    camera: newItems.filter((item) => item === "კამერა")[0] ? true : "",
  });
  useEffect(() => {
    setFilterData((prev) => ({
      ...prev,
      Hatch: newItems.filter((item) => item === "ლუქი")[0] ? true : "",
      multimedia: newItems.filter((item) => item === "მულტიმედია")[0]
        ? true
        : "",
      Panorama: newItems.filter((item) => item === "პანორამა")[0] ? true : "",
      camera: newItems.filter((item) => item === "კამერა")[0] ? true : "",
    }));
  }, [newItems]);

  useEffect(() => {
    setIsLoader(true);

    const queryParams = new URLSearchParams({
      page: perPage,
      per_page: 15,

      vehicleType:
        (filterData.vehicleType.length > 0 &&
          JSON.stringify(filterData.vehicleType)) ||
        "",
      minPrice: filterData.minPrice || "",
      maxPrice: filterData.maxPrice || "",
      minYear: filterData.minYear || "",
      maxYear: filterData.maxYear || "",
      minMetersRun: filterData.minMetersRun || "",
      maxMetersRun: filterData.maxMetersRun || "",
      minEngine: filterData.minEngine || "",
      maxEngine: filterData.maxEngine || "",
      transmission:
        (filterData.transmission.length > 0 &&
          JSON.stringify(filterData.transmission)) ||
        "",
      petrol:
        (filterData.petrol.length > 0 && JSON.stringify(filterData.petrol)) ||
        "",
      weel:
        (filterData.weel.length > 0 && JSON.stringify(filterData.weel)) || "",
      color:
        (filterData.color.length > 0 && JSON.stringify(filterData.color)) || "",
      salonColor:
        (filterData.salonColor.length > 0 &&
          JSON.stringify(filterData.salonColor)) ||
        "",

      hatch: filterData.Hatch,
      multimedia: filterData.multimedia,
      panorama: filterData.Panorama,
      camera: filterData.camera,
    }).toString();

    axiosClient
      .get(`front/cars?${queryParams}`)
      .then((res) => {
        setCarData(res.data.data);
        setFulldata(res.data.length);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  }, [perPage, filterData]);

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const items = Array.from(
    { length: Math.ceil(fulldata / 15 + 1) },
    (_, index) => index
  );

  return (
    <div className="flex flex-col gap-[30px] min-h-[100vh]">
      <div className="relative w-full h-[250px] max-sm:h-[190px]">
        <div className="w-full h-full">
          <img src={backgr} alt="" className="w-full h-full object-cover" />
        </div>
        <div className="flex justify-between px-[100px] max-md:px-[50px] max-sm:px-[16px] text-[30px] text-white w-[100%] absolute top-[135px] max-sm:top-[95px]">
          <h1>ავტომობილები</h1>
        </div>
      </div>

      <div
        className={`w-full flex max-lg:flex-col px-[100px] max-md:px-[50px] max-sm:px-[16px] gap-[30px] duration-200 ${
          filter ? "" : "max-lg:gap-[15px]"
        }`}
      >
        <div className="hidden max-lg:flex justify-center">
          <div
            onClick={() => handleShowFilter()}
            className="gap-[5px] h-[40px] self-start px-[10px] rounded-[10px] cursor-pointer text-white flex items-center bg-[black] shadow shadow-[#3d7294]
          active:bg-[#3d7294] duration-200"
          >
            {filter ? <AiOutlineClose /> : <FiFilter />}
            <p className="duration-200 ">{filter ? "დახურვა" : "ფილტრი"}</p>
          </div>
        </div>

        <div
          className={`w-[290px]  max-lg:w-full   max-lg:overflow-hidden duration-200 ${
            filter ? "pb-[10px]" : "max-lg:h-0"
          }`}
        >
          <div className="flex flex-col w-full">
            <div className="flex flex-col gap-[10px]">
              <ProductsMakeModel
                data={manufacturer}
                test1={test1}
                setTest1={setTest1}
                title="მწარმოებელი"
                setSearchValue={setFilterData}
                searchValue={filterData}
                name="firm"
              />
              <ProductsMakeModel
                data={test1}
                title="მოდელი"
                setSearchValue={setFilterData}
                searchValue={filterData}
                name="model"
              />
              <ProductsDropDown
                data={vehicleType}
                title="მანქანის ტიპი"
                searchValue={filterData}
                setSearchValue={setFilterData}
                name="vehicleType"
              />
              <PriceFilterInp
                searchValue={filterData}
                setSearchValue={setFilterData}
              />
              <ProductsDropDown
                data={"year"}
                title="წელი"
                searchValue={filterData}
                setSearchValue={setFilterData}
                name="minYear"
                name1="maxYear"
              />
              <div className="flex flex-col justify-center gap-[5px] shadow shadow-[#3d7294] bg-[#0e1420] p-[15px] rounded-[10px]">
                <p className="text-white">გარბენი</p>
                <div className="flex items-center justify-between gap-[10px]">
                  <div className="h-[40px] w-[calc((100%-125px)/2)]">
                    <MyInput
                      placeholder="დან"
                      justNums={true}
                      setSearchValue={setFilterData}
                      searchValue={filterData}
                      name="minMetersRun"
                    />
                  </div>
                  <div className="h-[40px] w-[calc((100%-125px)/2)]">
                    <MyInput
                      placeholder="მდე"
                      justNums={true}
                      setSearchValue={setFilterData}
                      searchValue={filterData}
                      name="maxMetersRun"
                    />
                  </div>
                  <div className="flex items-center gap-[10px]">
                    <p
                      onClick={() => setRunType("კმ")}
                      className={`text-white select-none flex items-center cursor-pointer justify-center rounded-full ${
                        runType === "კმ"
                          ? "shadow shadow-[green] bg-[#0e1420] h-[30px] w-[60px] duration-200 text-[17px]"
                          : "duration-200 shadow shadow-[red] bg-[#0e1420] h-[20px] w-[35px]  text-[10px]"
                      }`}
                    >
                      კმ
                    </p>
                    <p
                      onClick={() => setRunType("მილი")}
                      className={`text-white select-none flex items-center cursor-pointer justify-center rounded-full ${
                        runType === "მილი"
                          ? "shadow shadow-[green] bg-[#0e1420] h-[30px] w-[60px] duration-200 text-[17px]"
                          : "duration-200 shadow shadow-[red] bg-[#0e1420] h-[20px] w-[35px]  text-[10px]"
                      }`}
                    >
                      მილი
                    </p>
                  </div>
                </div>
              </div>
              <ProductsDropDown
                data={"engine"}
                title="ძრავი"
                searchValue={filterData}
                setSearchValue={setFilterData}
                name="minEngine"
                name1="maxEngine"
              />
              <ProductsDropDown
                data={transmission}
                title="გადაცემათა კოლოფი"
                setSearchValue={setFilterData}
                searchValue={filterData}
                name="transmission"
              />
              <ProductsDropDown
                data={fuel}
                title="საწვავის ტიპი"
                setSearchValue={setFilterData}
                searchValue={filterData}
                name="petrol"
              />
              <ProductsDropDown
                data={weel}
                title="საჭე"
                setSearchValue={setFilterData}
                searchValue={filterData}
                name="weel"
              />
              <ProductsDropDown
                data={colors}
                title="ფერი"
                setSearchValue={setFilterData}
                searchValue={filterData}
                name="color"
              />
              <ProductsDropDown
                data={colors}
                title="სალონის ფერი"
                setSearchValue={setFilterData}
                searchValue={filterData}
                name="salonColor"
              />
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              <ProductsDropDown
                data={parameters}
                title="პარამეტრები"
                setSearchValue={setFilterData}
                searchValue={filterData}
                setNewItems={setNewItems}
                name=""
                name1=""
                name2=""
                name3=""
              />
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
              {/* ?????????????????????????????????????????????????????????????????????????????????????? */}
            </div>
            <div className="flex flex-row  gap-2 items-center justify-between mt-4 w-full">
              <p className="shadow-[#3d7294] shadow pointer-events-none leading-[normal] w-[115px] py-2.5 rounded-[10px] text-center text-sm text-white">
                სულ: {fulldata}
              </p>
              <p
                onClick={() => (
                  screenWidth < 1024 && setShowFilter(false), goToTopFast()
                )}
                className="bg-[#0e1420] shadow shadow-[#3d7294]
              hover:bg-[#0d344c] duration-200 cursor-pointer leading-[normal] px-[20px] py-2.5 rounded-[10px] text-center text-sm text-white"
              >
                ძებნა
              </p>
            </div>
          </div>
        </div>
        {isLoader ? (
          <div className="w-[calc(100%-320px)] max-lg:w-full">
            <Loader />
          </div>
        ) : (
          <div className="flex flex-col gap-y-[30px] justify-between items-center w-[calc(100%-320px)] max-lg:w-full">
            <div className="w-full grid grid-cols-4 max-2xl:grid-cols-3 max-xl:grid-cols-2 max-lg:grid-cols-3 max-sm:grid-cols-2 max-tiny:grid-cols-1 gap-[20px] gap-y-[40px] ">
              {cardata.map((item) => (
                <SecondItemStyle key={item.id} item={item} />
              ))}
            </div>
            <div className="flex items-center gap-[10px] mb-[80px]">
              {items.map((item) => (
                <div
                  key={item.id}
                  onClick={() => {
                    setPerPage(item + 1);
                    goToTopFast();
                  }}
                  className={`w-[40px] h-[30px] rounded-[10px] flex items-center justify-center cursor-pointer  duration-100 shadow shadow-[#3d7294] bg-[#0e1420]  text-white ${
                    perPage === item + 1
                      ? "scale-110 bg-[#2d3e64]"
                      : "hover:scale-110 hover:bg-[#192338]"
                  }`}
                >
                  {item + 1}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
