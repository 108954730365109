import { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axiosClient from "../../axiosClient/axiosClient";
import Loader from "../../loader/loader";
import MyInput from "../../MyInput";
import MyAlert from "../../MyAlert";

export default function ProfileEditing() {
  const [showAlert, setShowAlert] = useState(false);
  const [alertStatus, setAlertStatus] = useState(false);

  const [showpas, setShowPass] = useState(true);
  const handleShowpass = () => {
    setShowPass((pre) => !pre);
  };

  const handelChange = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };

  const [allValues, setAllValues] = useState({
    email: "",
    name: "",
    password: "",
    phone: "",
  });

  const [data, setData] = useState();
  const [loader, setLoader] = useState(true);

  const handleUploadClick = () => {
    setTimeout(() => {
      setLoader(true);
      setShowAlert(false);
    }, 2200);
  };

  useEffect(() => {
    axiosClient.get(`user`).then((res) => {
      setLoader(false);
      setData(res.data);
      setAllValues(res.data);
    });
  }, []);

  const UpdateProfile = (e) => {
    axiosClient
      .put(`user`, {
        email: allValues.email,
        name: allValues.name,
        password: allValues.password,
        phone: allValues.phone,
      })
      .then((res) => {
        handleRefreshClick();
        setShowAlert(true);
        setAlertStatus(true);
        goToTopFast();
      })
      .catch((error) => {
        setShowAlert(true);
        setAlertStatus(false);
      });
  };

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  const handleRefreshClick = () => {
    window.location.reload();
  };

  return (
    <div className="w-full flex flex-col  gap-y-[10px] px-[50px] py-[50px] text-white shadow-md shadow-[#3d7294] bg-[#0e1420] rounded-[10px] ">
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="flex flex-col gap-y-[10px] max-md:gap-y-[20px] max-md:items-center">
            <div className="flex items-center w-full gap-[20px] max-sm:flex-col  justify-between max-sm:gap-y-[10px]">
              <p className="flex justify-end max-sm:justify-start">ელ-ფოსტა</p>
              <div className="max-smaller:w-full w-[260px] max-md:w-[200px] h-[40px] max-xl:w-[180px] max-sm:w-full">
                <MyInput
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.email}
                  name="email"
                />
              </div>
            </div>
            <div className="flex items-center w-full gap-[20px] max-sm:flex-col  justify-between  max-sm:gap-y-[10px]">
              <p className="flex justify-end max-sm:justify-start">სახელი</p>
              <div className="max-smaller:w-full w-[260px] max-md:w-[200px] h-[40px] max-xl:w-[180px] max-sm:w-full">
                <MyInput
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.name}
                  name="name"
                />
              </div>
            </div>
            <div className="flex items-center w-full gap-[20px] max-sm:flex-col  justify-between max-sm:gap-y-[10px]">
              <p className="flex justify-end max-sm:justify-start">
                მობილურის ნომერი
              </p>
              <div className="max-smaller:w-full w-[260px] max-md:w-[200px] h-[40px] max-xl:w-[180px] max-sm:w-full">
                <MyInput
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={data && data.phone}
                  name="phone"
                  itsTellNum={true}
                />
              </div>
            </div>
            <div className="flex items-center w-full gap-[20px] max-sm:flex-col  justify-between max-sm:gap-y-[10px]">
              <p className="flex justify-end max-sm:justify-start">
                შეიყვანე ახალი პაროლი
              </p>
              <div className="flex items-center w-[260px] max-smaller:w-full max-md:w-[200px] pr-[10px] h-[40px] rounded-[10px] max-md:justify-between max-md:pr-[4px] shadow-md shadow-[#3d7294] bg-[#1d263a] max-xl:w-[180px] max-sm:w-full">
                <input
                  onChange={(e) => handelChange(e)}
                  value={data.password}
                  name="password"
                  placeholder="**************"
                  type={showpas ? "password" : "text"}
                  className="outline-none bg-[#1d263a] px-[10px] w-[calc(100%-18px)] "
                />
                <div
                  className="w-[18px] flex justify-center h-[18px] cursor-pointer"
                  onClick={() => {
                    handleShowpass();
                  }}
                >
                  {showpas ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                </div>
              </div>
            </div>

            <div className="flex items-center mt-[10px] justify-end gap-[20px] w-full max-sm:flex-col max-sm:items-center ">
              <button
                onClick={() => {
                  handleUploadClick();
                  UpdateProfile();
                }}
                className="px-[10px] cursor-pointer h-[40px] flex items-center rounded-[8px] justify-center 
            bg-[black] shadow shadow-[#3d7294]
      hover:bg-[#3d7294] duration-200"
                type="submit"
              >
                რედაქტირება
              </button>
            </div>
          </div>
        </>
      )}
      <MyAlert
        showAlert={showAlert}
        alertStatus={alertStatus}
        text={
          alertStatus
            ? "ინფორმაცია წარმატებით შეიცვალა"
            : "ინფორმაცია ვერ შეიცვალა!"
        }
      />
    </div>
  );
}
