import { useEffect, useState } from "react";

export default function MyInput({
  allValues,
  setAllValues,
  name,
  itsTellNum,
  firstValue,
  justNums,
  placeholder,
  searchValue,
  setSearchValue
}) {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (setAllValues) {
      setAllValues({ ...allValues, [name]: value });
    }
  }, [value]);

  useEffect(() => {
    if (setSearchValue) {
      setSearchValue({ ...searchValue, [name]: value });
    }
  }, [value]);

  const handelChangeValiue = (e) => {
    if (itsTellNum) {
      e.target.value = e.target.value
        .replace(/[^0-9]/g, "")
        .replace(/\s/g, "")
        .replace(/(.{3})/g, "$1 ")
        .trim()
        .slice(0, 11);
    }

    if (justNums) {
      e.target.value = e.target.value
        .replace(/[^0-9]/g, "");
    }

    setValue(e.target.value);
  };

  useEffect(() => {
    if (firstValue) {
      setValue(firstValue);
    }
  }, [firstValue]);

  return (
    <input
      value={value}
      onChange={(e) => handelChangeValiue(e)}
      placeholder={placeholder === "დან" ? "დან" : placeholder === "მდე" ? "მდე" : "ჩაწერე"}
      min="0"
      className="outline-none w-full  rounded-[10px] h-full px-[8px] flex items-center text-white shadow shadow-[#3d7294] bg-[#1d263a]"
    />
  );
}
