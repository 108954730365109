import React, { useContext } from "react";
import { FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";
import { CurrencyContext } from "../../CurrencyContext/CurrencyContext";

export default function FirstItemStyle({ item }) {
  const { currency, userChooseCurrency } = useContext(CurrencyContext);

  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };

  return (
    <div className="flex flex-1 flex-col items-center justify-start w-full">
      <div className="flex flex-col gap-y-[5px] items-start justify-start w-full">
        <div className="h-[250px] max-md:h-[200px] max-tiny:h-[180px] relative w-full">
          <Link to={`/part/${item.id}`}>
            <img
              onClick={() => goToTopFast()}
              className="h-full m-auto object-cover rounded-[10px] w-full"
              src={
                item.parts_imgs &&
                item.parts_imgs[0] &&
                `https://back.carfax.ge/${item.parts_imgs[0].url}`
              }
              alt=""
            />
          </Link>
          <div className="absolute flex flex-row gap-1.5 items-center justify-start top-[10px] left-[10px]">
            {item.vip > 0 && (
              <div className="bg-[red] text-white   h-[30px] px-[10px] flex items-center rounded-full">
                VIP
              </div>
            )}

            <p className="bg-[#000000a4] justify-center text-white h-[30px] px-[10px] rounded-full flex items-center text-[13px]">
              {item.condition ? "ახალი" : "მეორადი"}
            </p>
            <p className="bg-[#000000a4] justify-center text-white h-[30px] px-[10px] rounded-full flex items-center text-[13px]">
              {item.original ? "ორიგინალი" : "პატენტი"}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start">
          <div className="flex items-center w-full gap-[5px]">
            {item.Parts_models &&
              item.Parts_models.map((item2, index) => (
                <p key={item2.id} className="oneline text-white text-[15px] ">
                  {item2.firm}
                  {index < item.Parts_models.length - 1 && ","}
                </p>
              ))}
          </div>
          <div className="flex flex-row  items-start justify-between w-[86%] md:w-full">
            <div className="flex items-center w-full gap-[5px]">
              {item.Parts_models &&
                item.Parts_models.map((item2, index) => (
                  <p
                    key={item2.model}
                    className="oneline text-white text-[15px] "
                  >
                    {item2.model}
                    {index < item.Parts_models.length - 1 && ","}
                  </p>
                ))}
            </div>
          </div>
        </div>

        <div className="flex flex-row gap-[3px] justify-between items-center w-full">
          <div className="flex items-center gap-[5px]">
            <h1 className=" text-[18px]  text-white">
              {userChooseCurrency === "USD"
                ? item.currency === "USD"
                  ? `${item.price} $`
                  : `${Math.round(item.price / currency.rate)} $`
                : item.currency === "USD"
                ? `${Math.round(item.price * currency.rate)} ₾`
                : `${item.price} ₾`}
            </h1>
          </div>
          <div className="flex items-center gap-[5px] ">
            <FiSettings className="text-white text-[14px]" />
            <p className="text-[15px]  text-white">{item.partName}</p>
          </div>
        </div>
      </div>
    </div>
  );
}