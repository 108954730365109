import { useState, useContext } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axiosClient from "../axiosClient/axiosClient";
import { UserContext } from "../UserSettingContext/AxiosContext";
import Loader from "../loader/loader";

function SignIn({
  setActive,
  handleLogreg,
  setShowAlert,
  setAlertStatus,
  handleAlertClose,
}) {
  const { setUser, setToken, setAdmin, setIsAuthorizedAdmin, setTokenAdmin } =
    useContext(UserContext);

  const [saveChecked, setSaveChecked] = useState(false);
  const handleSaveChecked = () => {
    setSaveChecked((pre) => !pre);
  };
  const [show, setShow] = useState(false);

  const handleshow = () => {
    setShow((pre) => !pre);
  };

  const [EmailValue, setEmailValue] = useState("");
  const [Error, setError] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");

  const [isLoader, setIsLoader] = useState(false);

  const handleInputKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handelSubmit();
    }
  };

  const handelChangeEmail = (e) => {
    setEmailValue(e.target.value);
  };
  const handelChangePassword = (e) => {
    setPasswordValue(e.target.value);
  };

  const handelSubmit = () => {
    setIsAuthorizedAdmin(false);
    setAdmin({});
    setTokenAdmin(null);

    setIsLoader(true);
    if (EmailValue && passwordValue) {
      return axiosClient
        .post("auth/login", {
          email: EmailValue,
          password: passwordValue,
        })
        .then(({ data }) => {
          setUser(data.user);
          setToken(data.token);
          handleLogreg(false);
          setShowAlert(true);
          setAlertStatus(true);
        })
        .catch((error) => {
          setError(true);
          setShowAlert(true);
          setAlertStatus(false);
        })
        .finally(() => {
          setIsLoader(false);
          handleAlertClose();
        });
    } else {
      setIsLoader(false);
    }
  };

  return (
    <div className="w-[100%] flex flex-col gap-y-[20px]">
      <div className="flex flex-col gap-y-[10px]">
        <p>ელ-ფოსტა</p>
        <input
          value={EmailValue}
          onChange={(e) => handelChangeEmail(e)}
          type="text"
          onKeyPress={handleInputKeyPress}
          className={`w-[100%] h-[50px] px-[10px] border-[1px] rounded-[10px] outline-none ${
            Error && "border-red-600"
          }`}
        />
      </div>
      <div className="flex flex-col gap-y-[10px]">
        <p>პაროლი</p>
        <div
          className={`flex items-center h-[50px] px-[10px] border-[1px] 
           rounded-[10px] outline-none`}
        >
          <input
            value={passwordValue}
            onChange={(e) => handelChangePassword(e)}
            placeholder="***********"
            type={show ? "text" : "password"}
            onKeyPress={handleInputKeyPress}
            className={`w-[100%] h-[full] outline-none ${
              Error && "border-red-600"
            }`}
          />
          <div onClick={handleshow} className="text-[20px] text-[#010125]">
            {show ? (
              <AiOutlineEye className="cursor-pointer " />
            ) : (
              <AiOutlineEyeInvisible className="cursor-pointer" />
            )}
          </div>
        </div>
      </div>
      {Error && (
        <div className="flex items-center justify-center">
          <p className="text-[#ff0000] font-bold">
            ელ-ფოსტა ან პაროლი არასწორია
          </p>
        </div>
      )}
      <div className="flex justify-between items-center">
        <div
          onClick={() => {
            handleSaveChecked();
          }}
          className="flex gap-[5px] items-center cursor-pointer"
        >
          <input
            type="checkbox"
            checked={saveChecked}
            className="w-[15px] h-[15px]"
          />
          <p>დამახსოვრება</p>
        </div>
        <p
          className="hover:underline cursor-pointer"
          onClick={() => {
            setActive("forgetpassword");
          }}
        >
          დაგავიწყდა პაროლი?
        </p>
      </div>
      {isLoader ? (
        <Loader />
      ) : (
        <div
          onClick={() => {
            handelSubmit();
          }}
          className="flex gap-[5px] items-center pt-[2px] w-[100%] h-[50px] rounded-[10px]  text-white justify-center  text-[18px] cursor-pointer hover:bg-[#000000c1]  border-[1px]  duration-200 bg-black"
        >
          <h1>შესვლა</h1>
        </div>
      )}
    </div>
  );
}

export default SignIn;
