import { useDatasContext } from "../../DatasContext/DatasContext";
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai";

export default function AddServicesInServ({
  toggleItemSelection,
  selectedItems,
}) {
  const { servicetype } = useDatasContext();
  return (
    <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
      <p className="hmli text-[17px]">სერვისები</p>

      <div className="grid grid-cols-5 max-2xl:grid-cols-4 max-xl:grid-cols-3 max-sm:grid-cols-2  gap-4  w-full gap-y-[20px]">
        {servicetype.map((item) => (
          <div
            key={item.id}
            onClick={() => toggleItemSelection(item)}
            className={`flex justify-between w-full h-[60px] duration-200 items-center px-[10px] rounded-[10px] cursor-pointer 
                    
                ${
                  selectedItems.includes(item)
                    ? "bg-[#335f7a] text-white"
                    : "shadow-md shadow-[#3d7294] bg-[#0e1420]"
                }
                `}
          >
            <p className="text-[14px]">{item.text}</p>
            <div
              className={`w-[30px] h-[30px] rounded-full flex items-center justify-center duration-200 
        ${
          selectedItems.includes(item)
            ? "bg-[#3d7294] border-[1px] text-[22px] text-white"
            : "bg-[#335f7a]"
        }  `}
            >
              {selectedItems.includes(item) ? (
                <AiOutlineCheck />
              ) : (
                <AiOutlinePlus />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
