import React, { createContext, useEffect, useState } from "react";
import axiosClient from "../axiosClient/axiosClient";

export const CurrencyContext = createContext();

export function CurrencyContextProvider({ children }) {
  const [currency, setCurrency] = useState({});

  useEffect(() => {
    axiosClient.get(`front/currency`).then((res) => {
      setCurrency(res.data);
    });
  }, []);

  const [userChooseCurrency, setUserChooseCurrency] = useState("USD");


  const currencycontextValue = {
    currency,
    setCurrency,
    userChooseCurrency,
    setUserChooseCurrency
  };

  return (
    <CurrencyContext.Provider value={currencycontextValue}>
      {children}
    </CurrencyContext.Provider>
  );
}
