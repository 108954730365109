import React from "react";
import { Link } from "react-router-dom";

export default function Service({ item }) {
  const goToTopFast = () => {
    window.scrollTo({ top: 0, left: 0 });
  };
  return (
    <Link to={`/serv/${item.id}`}>
      <div onClick={() => goToTopFast()} className="h-[180px] relative w-full">
        <img
          className="h-[180px] m-auto object-cover rounded-[10px] w-full"
          src={
            item.servcenters_imgs &&
            item.servcenters_imgs[0] &&
            `https://back.carfax.ge/${item.servcenters_imgs[0].url}`
          }
          alt="service"
        />
        <div className="absolute bg-[#0f152180] flex flex-col h-full inset-[0] items-center justify-center m-auto p-6 sm:px-5 rounded-[10px] w-full">
          <p className="my-[27px] sm:text-[21px] md:text-[23px] text-[25px] text-center text-white w-[120px]">
            {item.servcenterName}
          </p>
        </div>
        {item.vip > 0 && (
          <div className="absolute top-[10px] left-[10px] bg-[red] text-white   h-[30px] px-[10px] flex items-center rounded-full">
            VIP
          </div>
        )}
      </div>
    </Link>
  );
}
