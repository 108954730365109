import React, { useEffect, useState } from "react";
import AdminCard from "./AdminCard";
import axiosClient from "../../axiosClient/axiosClient";
import Loader from "../../loader/loader";

const Admins = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [adminAccs, setAdminsAccs] = useState([]);
  const [newRender, setNewRender] = useState();
  
  useEffect(() => {
    setIsLoading(true);
    axiosClient
      .get(`admin/admins`)
      .then((res) => {
        setAdminsAccs(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [newRender]);
  
  return (
    <div className="max-md:ml-0 ">
      <h1 className="hidden max-lg:flex w-full justify-center mb-[10px] text-white text-[20px]">
          ადმინები
        </h1>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="grid grid-cols-4 max-2xl:grid-cols-3 gap-4 max-xl:grid-cols-2 max-lg:grid-cols-3 max-sm:grid-cols-2 max-tiny:grid-cols-1  ">
          {(adminAccs || []).map((item) => (
            <AdminCard key={item.id} item={item} setNewRender={setNewRender} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Admins;