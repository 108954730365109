import React, { createContext, useState } from "react";

export const RunningTypeContext = createContext();

export function RunningTypeContextProvider({ children }) {
  const [runType, setRunType] = useState("კმ");

  const runningtypecontextValue = {
    runType,
    setRunType,
  };

  return (
    <RunningTypeContext.Provider value={runningtypecontextValue}>
      {children}
    </RunningTypeContext.Provider>
  );
}
