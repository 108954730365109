import React, { useContext } from "react";
import { RunningTypeContext } from "../../runningTypeContext/RunningTypeContext";

const InfoItem = ({ item, data }) => {
  const { runType, setRunType } = useContext(RunningTypeContext);

  return (
    <div className="flex gap-1.5 items-center">
      <div className="border-2 border-red-600 text-[18px] border-solid flex h-[40px] max-tiny:h-[30px] items-center justify-center p-[px] rounded-[5px] w-[40px] max-tiny:w-[30px] text-red-600">
        {item.title === "წამყვანი თვლები" ? (
          <img src={item.icon} alt="" className="w-5 h-5 object-contain" />
        ) : (
          item.icon
        )}
      </div>
      <div className="flex flex-col items-start">
        <h1 className="text-[11px] text-white">{item.title}</h1>
        <div className="flex items-center gap-[10px]">
          <h1 className="text-[13px] text-white">
            {item.title === "გარბენი"
              ? runType === "კმ"
                ? data.name === "კმ"
                  ? item.text
                  : `${(item.text * 1.609).toFixed(0)}`
                : data.name === "მილი"
                ? item.text
                : `${(item.text / 1.609).toFixed(0)}`
              : item.text}
          </h1>
          {item.title === "გარბენი" && (
            <div className="flex items-center gap-[5px] h-[25px]">
              <p
                onClick={() => setRunType("კმ")}
                className={`text-white duration-200 cursor-pointer ${
                  runType === "კმ"
                    ? "text-[13px] w-[25px] h-[20px] shadow shadow-[green] bg-[#171616]  rounded-full flex items-center justify-center"
                    : "text-[9px] w-[20px] h-[15px] shadow shadow-[red] rounded-full flex items-center justify-center"
                }`}
              >
                კმ
              </p>
              <p
                onClick={() => setRunType("მილი")}
                className={`text-white duration-200 cursor-pointer ${
                  runType === "მილი"
                    ? "text-[13px] w-[40px] h-[25px] shadow shadow-[green] bg-[#171616] cursor-pointer rounded-full flex items-center justify-center"
                    : "text-[9px] w-[25px] h-[15px] shadow shadow-[red] rounded-full flex items-center justify-center"
                }`}
              >
                მილი
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InfoItem;
