import { useEffect, useRef, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BsCheckAll } from "react-icons/bs";
import { PiCaretDownLight } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";

export default function ProductsMakeModel({
  data,
  setTest1,
  title,
  searchValue,
  setSearchValue,
  name,
}) {
  const [drop, setDrop] = useState(false);
  const handleDrop = () => {
    setDrop((pre) => !pre);
  };

  const [selectedItems, setSelectedItems] = useState([]);

  // Update the selected items
  const toggleItemSelection = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((items) => items !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const [value, setValue] = useState(title);

  const handelClick = (item) => {
    setSelectedItems(item.make);
    if (setTest1) {
      setTest1(item.models);
    }
  };

  useEffect(() => {
    if (setSearchValue && selectedItems !== title) {
      setSearchValue({ ...searchValue, [name]: selectedItems });
    }
  }, [selectedItems]);

  return (
    
    <div
      className="shadow h-full rounded-[10px] duration-150 
    shadow-[#3d7294] bg-[#0e1420] text-white"
    >
      <div
        onClick={() => {
          handleDrop();
        }}
        className={`h-[50px]  px-[15px] flex items-center justify-between cursor-pointer`}
      >
        <div className="flex items-center gap-[5px]">
          <p className="text-[17px] text-uppercase">{title}</p>
          <AiOutlineCheck
            className={`text-[#42ff42] text-[20px] ${
              selectedItems.length !== 0 ? "flex" : "hidden"
            }`}
          />
        </div>
        <div className="flex items-center justify-center gap-[10px]">
          {value !== title && (
            <RxCross2
              className=""
              onClick={() => {
                setValue(title);
              }}
            />
          )}

          <div className="flex items-center justify-center gap-[10px]">
            <AiOutlineClose
              onClick={() => {
                setSelectedItems([]);
              }}
              className={` ${selectedItems.length !== 0 ? "flex" : "hidden"}`}
            />
            <PiCaretDownLight
              className={`${drop ? "rotate-180 duration-200" : "duration-200"}`}
            />
          </div>
        </div>
      </div>

      <div
        className={`showScrollbar w-full duration-150 
        ${
          drop
            ? "max-h-[140px] overflow-y-scroll p-[5px]"
            : "h-0 overflow-hidden p-0"
        }
        `}
      >
        <ul className="w-full flex flex-col gap-y-[5px]">
          {data &&
            data.map((item) => (
              <p
                key={item.id}
                onClick={() => {
                  handelClick(item);
                  toggleItemSelection(item.make);
                }}
                className="cursor-pointer hover:bg-[#203f52] flex items-center gap-[8px] duration-100 px-[10px] text-white"
              >
                <div
                  className={`w-[20px] rounded-full h-[20px] text-white flex items-center justify-center duration-100 ${
                    selectedItems.includes(item.make)
                      ? "bg-[green]"
                      : "bg-white"
                  }`}
                >
                  <BsCheckAll />
                </div>
                {item.make}
              </p>
            ))}
        </ul>
      </div>
    </div>
  );
}
