import React, { useEffect, useState } from "react";
import {
  AiOutlineEye,
  AiOutlinePrinter,
  AiOutlineShareAlt,
} from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { TbSquareToggle } from "react-icons/tb";

import ServiceSection from "../mainPage/ServiceSection";
import SecondSlider from "../mainPage/SecondSlider";

import { useDatasContext } from "../../DatasContext/DatasContext";
import Loader from "../../loader/loader";
import { useParams } from "react-router-dom";
import axiosClient from "../../axiosClient/axiosClient";
import SliderParts from "../productPage/ProductSlider";
import { ServMainInfo } from "./ServMainInfo";
import ProductSlider from "../productPage/ProductSlider";

export default function Serv() {
  const [isLoader, setIsLoader] = useState(true);
  const [active, setActive] = useState("characteristics");

  const params = useParams();

  const { id } = params;
  const [data, setData] = useState({});
  useEffect(() => {
    axiosClient
      .get(`front/Servcenters/${id}`)
      .then(({ data }) => {
        setData(data);
        setIsLoader(false);
      })
      .catch((error) => {});
  }, [id]);

  return (
    <div className="flex flex-col py-[150px] px-[190px] max-sm:pt-[110px] max-xl:px-[100px] max-md:px-[50px] max-sm:px-[16px]">
      {isLoader ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-y-[20px]">
          <div className="flex items-start w-full py-2 justify-between gap-y-[10px] max-sm:flex-col">
            <div className="flex items-center gap-[20px]">
              {data && data.vip > 0 && (
                <h1 className="bg-[red] h-[30px] flex items-center pt-[3px] px-3 rounded-full text-[15px] text-center text-white">
                  {data && data.vip && "VIP"}
                </h1>
              )}
              <h1 className="text-[18px] text-white">
                {data && data.servcenterName}
              </h1>
              <div className="flex gap-[3px] items-center">
                <AiOutlineEye className="text-white text-[18px]" />
                <h1 className="text-[14px] text-white">{data && data.view}</h1>
              </div>
              <div className="flex items-center justify-center gap-[3px] ">
                <BiTimeFive className="text-white text-[18px]" />

                <h1 className="text-[14px] text-white">
                  {data && data.createdAt.split("T")[0].replace(/-/g, " ")}
                </h1>
              </div>
            </div>

            <div className="flex items-center gap-[30px]">
              <div className="flex items-center gap-[15px]">
                <TbSquareToggle className="text-white text-[18px] cursor-pointer" />
                <AiOutlinePrinter className="text-white text-[18px] cursor-pointer" />
                <AiOutlineShareAlt className="text-white text-[18px] cursor-pointer" />
              </div>
            </div>
          </div>

          <div className="w-full h-auto flex gap-[20px] max-lg:flex-col">
            <div className="w-[70%] max-xl:w-[65%] max-lg:w-full h-auto flex flex-col gap-[50px]">
              <ProductSlider
                data={data && data.servcenters_imgs}
                datavid={data && data.servcenters_videos}
              />
            </div>
            <div className="flex w-[30%] max-xl:w-[45%] max-lg:w-auto max-lg:self-start">
              <ServMainInfo data={data} />
            </div>
          </div>

          <div className="flex gap-[37px] items-start justify-start w-[70%] max-xl:w-[65%] max-lg:w-full max-sm:flex-col">
            <div className="flex md:flex-1 flex-col gap-[15px] items-center justify-start  mt-0.5 max-sm:flex-row">
              <p
                onClick={() => {
                  setActive("characteristics");
                }}
                className={` cursor-pointer leading-[normal] w-[170px] max-tiny:w-[160px] h-[40px] flex items-center justify-center rounded-[20px] text-center text-white text-[12px] duration-100 ${
                  active === "characteristics"
                    ? "scale-105 bg-[#208A16]"
                    : "scale-100 bg-[#FD022F]"
                }`}
              >
                სერვისები
              </p>
              <p
                onClick={() => {
                  setActive("description");
                }}
                className={` cursor-pointer leading-[normal] w-[170px] max-tiny:w-[160px] h-[40px] flex items-center justify-center rounded-[20px] text-center text-white text-[15px] duration-100 ${
                  active === "description"
                    ? "scale-105 bg-[#208A16]"
                    : "scale-100 bg-[#FD022F]"
                }`}
              >
                დეტალური აღწერა
              </p>
            </div>
            <div className="flex flex-col gap-5 justify-start w-[calc(100%-185px)] max-sm:w-full">
              <div
                className={`flex items-center flex-wrap gap-[10px] ${
                  active === "characteristics" ? "flex" : "hidden"
                }`}
              >
                {data &&
                  data.services &&
                  JSON.parse(data.services).map((item) => (
                    <p
                      key={item.id}
                      className="bg-white px-[10px] h-[40px] rounded-full flex items-center hover:scale-105 duration-150 cursor-pointer"
                    >
                      {item.text}
                    </p>
                  ))}
              </div>

              <div
                className={`gap-y-[10px] ${
                  active === "description" ? "flex flex-col" : "hidden"
                }`}
              >
                <p className={`text-white`}>{data && data.description}</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <SecondSlider />
      <ServiceSection />
    </div>
  );
}
