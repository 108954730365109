import { useContext } from "react";
import MyInput from "../../MyInput";
import { CurrencyContext } from "../../CurrencyContext/CurrencyContext";

export default function PriceFilterInp({ searchValue, setSearchValue }) {
  const { userChooseCurrency, setUserChooseCurrency } =
    useContext(CurrencyContext);
  return (
    <div className="flex flex-col justify-center gap-[5px] shadow shadow-[#3d7294] bg-[#0e1420] p-[15px] rounded-[10px]">
      <p className="text-white">ფასი</p>

      <div className="flex w-full items-center justify-between gap-[10px]">
        <div className="h-[40px] w-[calc((100%-80px)/2)] ">
          <MyInput
            placeholder="დან"
            justNums={true}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            name="minPrice"
          />
        </div>
        <div className="h-[40px] w-[calc((100%-80px)/2)]">
          <MyInput
            placeholder="მდე"
            justNums={true}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            name="maxPrice"
          />
        </div>
        <div className="flex items-center gap-[10px]">
          <p
            onClick={() => setUserChooseCurrency("GEL")}
            className={`text-white select-none flex items-center cursor-pointer justify-center rounded-full ${
              userChooseCurrency === "GEL"
                ? "shadow shadow-[green] bg-[#0e1420] h-[30px] w-[30px]  duration-200 text-[17px]"
                : "duration-200 shadow shadow-[red] bg-[#0e1420] h-[20px] w-[20px]  text-[10px]"
            }`}
          >
            ₾
          </p>
          <p
            onClick={() => setUserChooseCurrency("USD")}
            className={`text-white select-none flex items-center cursor-pointer justify-center rounded-full ${
              userChooseCurrency === "USD"
                ? "shadow shadow-[green] bg-[#0e1420] h-[30px] w-[30px]  duration-200 text-[17px]"
                : "duration-200 shadow shadow-[red] bg-[#0e1420] h-[20px] w-[20px]  text-[10px]"
            }`}
          >
            $
          </p>
        </div>
      </div>
    </div>
  );
}
