import React, { useContext, useState } from "react";

import { BsFillPersonFill, BsFillTelephoneFill } from "react-icons/bs";
import { CurrencyContext } from "../../CurrencyContext/CurrencyContext";
const MainInfo = ({ data }) => {
  const { currency, userChooseCurrency, setUserChooseCurrency } =
    useContext(CurrencyContext);
  const [morenum, setMorenum] = useState(false);

  const handleMorenum = () => {
    setMorenum((pre) => !pre);
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-col gap-y-[30px]">
        <div className="flex flex-row  gap-[13px] items-center justify-start ">
          <div className="flex flex-col items-center">
            <h1 className="text-[44px]  text-white">
              {userChooseCurrency === "USD"
                ? data.currency === "USD"
                  ? `${data.price} $`
                  : `${Math.round(data.price / currency.rate)} $`
                : data.currency === "USD"
                ? `${Math.round(data.price * currency.rate)} ₾`
                : `${data.price} ₾`}
            </h1>
          </div>
          <div
            onClick={() => {
              setUserChooseCurrency(
                userChooseCurrency === "USD" ? "GEL" : "USD"
              );
            }}
            className="bg-white  flex items-center justify-center  py-0.5 px-1 gap-[5px] rounded-[20px] "
          >
            <div
              className={`w-[25px] h-[25px] text-[17px] rounded-full duration-200 cursor-pointer flex items-center justify-center ${
                userChooseCurrency === "USD"
                  ? "text-white bg-black"
                  : "bg-white"
              }`}
            >
              $
            </div>
            <div
              className={`w-[25px] h-[25px] text-[17px] rounded-full cursor-pointer duration-200 font-semibold flex items-center justify-center ${
                userChooseCurrency === "GEL"
                  ? "text-white bg-black"
                  : "bg-white"
              }`}
            >
              ₾
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-start w-full">
          <div className="flex flex-row items-center gap-[10px] w-full">
            <div className="bg-gray-600 flex flex-row gap-1.5 items-center justify-start  pr-[10px] rounded-full">
              <div className="bg-slate-100 flex flex-col h-[41px] items-center justify-center p-1 rounded-full w-[41px]">
                <BsFillPersonFill className="text-[25px] text-gray-500" />
              </div>
              <h1 className="text-[17px] text-white ">{data.o_name}</h1>
            </div>
          </div>
        </div>
        <div className="flex items-center self-start  gap-4 border border-solid border-white rounded-full py-2 px-3">
          <div className="flex items-center gap-[10px]">
            <BsFillTelephoneFill className="text-white text-[17]" />
            <p className="text-white w-[110px]">
              {data && data.o_phone.split(" ")[0]}{" "}
              {data && data.o_phone.split(" ")[1]}{" "}
              {!morenum ? "***" : data && data.o_phone.split(" ")[2]}
            </p>
          </div>
          <div className="flex items-center w-[95px]">
            <p
              onClick={() => {
                handleMorenum();
              }}
              className="text-white text-[14px] cursor-pointer"
            >
              ნომრის ნახვა
            </p>
          </div>
        </div>
        <div className="flex items-center text-white">
          <p>VIN: {data && data.vin}</p>
        </div>
      </div>
    </div>
  );
};

export default MainInfo;
