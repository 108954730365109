import React from "react";
import { createContext, useEffect, useState } from "react";
import axiosClient from "../axiosClient/axiosClient";

export const UserContext = createContext();

const AxiosContext = ({ children }) => {
  const [user, setUser] = useState({});
  const [admin, setAdmin] = useState({});
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAuthorizedadmin, setIsAuthorizedAdmin] = useState(false);

  const [token, _setToken] = useState(localStorage.getItem("CarFaxUser"));
  const [tokenadmin, _setTokenAdmin] = useState(
    localStorage.getItem("CarFaxAdmin")
  );

  const setToken = (token) => {
    _setToken(token);
    if (token) {
      localStorage.setItem("CarFaxUser", token);
    } else {
      localStorage.removeItem("CarFaxUser");
    }
  };
  const setTokenAdmin = (tokenadmin) => {
    _setTokenAdmin(tokenadmin);
    if (tokenadmin) {
      localStorage.setItem("CarFaxAdmin", tokenadmin);
    } else {
      localStorage.removeItem("CarFaxAdmin");
    }
  };

  useEffect(() => {
    if (user.phone || user.email) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
    }
  }, [user]);

  useEffect(() => {
    if (admin.email || admin.password) {
      setIsAuthorizedAdmin(true);
    } else {
      setIsAuthorizedAdmin(false);
    }
  }, [admin]);

  // get current logged in user
  useEffect(() => {
    if (token) {
      axiosClient
        .get("/user")
        .then(({ data }) => {
          setUser(data);
        })
        .catch((err) => console.log(err));
    }
  }, [token]);

  // get current logged in admin
  useEffect(() => {
    if (tokenadmin) {
      axiosClient
        .get("/admin")
        .then((res) => {
          setAdmin(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [tokenadmin]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        admin,
        setAdmin,
        token,
        setToken,
        tokenadmin,
        setTokenAdmin,
        isAuthorized,
        setIsAuthorized,
        isAuthorizedadmin,
        setIsAuthorizedAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default AxiosContext;
