import { AiOutlineClose } from "react-icons/ai";
import { useDatasContext } from "../../DatasContext/DatasContext";
import DropDownAbsolute from "../../DropDownAbsolute";
import MyInput from "../../MyInput";
import { PiCaretDownLight } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";
import { useEffect, useRef, useState } from "react";

export default function AddLocationInServ({ allValues, setAllValues, data }) {
  const { adress } = useDatasContext();
  const [addAdress, setAddAdress] = useState({
    city: "",
    adress: "",
  });

  const [value, setValue] = useState("აირჩიე");
  const [value1, setValue1] = useState("");
  const dropRef = useRef(null);

  useEffect(() => {
    if (setAddAdress) {
      setAddAdress({ ...addAdress, city: value, adress: value1 });
    }
  }, [value, value1]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setDrop(false);
    }
  }

  const [drop, setDrop] = useState(false);
  const handleDrop = () => {
    setDrop((pre) => !pre);
  };

  const handleAdd = (data) => {
    if (data.city !== "" && data.adress !== "") {
      const newValues = { ...allValues };
      newValues.location = [
        ...newValues.location,
        {
          id: Math.random(),
          city: data.city,
          adress: data.adress,
        },
      ];
      setAllValues(newValues);
      setAddAdress({ city: "", adress: "" });
      setValue("აირჩიე");
      setValue1("");
    }
  };

  const handleDelete = (Id) => {
    const newValues = { ...allValues };
    const filtered = newValues.location.filter((item) => item.id !== Id);
    newValues.location = filtered;
    setAllValues(newValues);
  };

  return (
    <div className="w-full shadow-md shadow-[#3d7294] bg-[#0e1420] text-white rounded-[10px] flex flex-col gap-y-[20px] px-[20px] py-[30px] max-sm:px-[10px]">
      <p className="hmli text-[17px]">მისამართი</p>

      {(allValues.location || []).map((item) => (
        <div className="flex items-end gap-[20px] max-md:border-b-[1px] max-md:pb-[20px] max-md:items-center">
          <div className="flex gap-[20px] max-md:flex-col">
            <div className="pointer-events-none flex flex-col items-center justify-between max-md:items-start">
              <p>ქალაქი</p>
              <div className="w-[200px] h-[50px] max-md:w-[250px]">
                <DropDownAbsolute
                  data={adress}
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={item && item.city}
                  name={"city"}
                />
              </div>
            </div>
            <div className="pointer-events-none flex flex-col items-center justify-between max-md:items-start">
              <p>მისამართი</p>
              <div className="w-[300px] h-[50px] max-md:w-[250px]">
                <MyInput
                  allValues={allValues}
                  setAllValues={setAllValues}
                  firstValue={item && item.adress}
                  name={"adress"}
                />
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              handleDelete(item.id);
            }}
            className="w-[50px] h-[50px] text-[20px] flex items-center justify-center cursor-pointer rounded-[10px]"
          >
            <AiOutlineClose />
          </div>
        </div>
      ))}
      <div className="flex items-end gap-[20px] max-md:flex-col max-md:items-start">
        <div className="flex flex-col items-center justify-between max-md:items-start">
          <p>ქალაქი</p>
          <div className="w-[200px] h-[50px] max-md:w-[300px]">
            <div ref={dropRef} className="relative  h-full">
              <div
                onClick={() => {
                  handleDrop();
                }}
                className={`h-full cursor-pointer px-[10px] flex items-center justify-between shadow-md shadow-[#3d7294] bg-[#0e1420] text-white ${
                  drop ? " rounded-t-[10px]" : "rounded-[10px] "
                } `}
              >
                <div className="flex items-center gap-[10px]">
                  <p
                    className={`${
                      value !== "აირჩიე"
                        ? "text-[18px]"
                        : "text-[15px] text-gray-400"
                    }`}
                  >
                    {value}
                  </p>
                </div>
                <div className="flex items-center justify-center gap-[10px]">
                  {value !== "აირჩიე" && (
                    <RxCross2
                      className=""
                      onClick={() => {
                        setValue("აირჩიე");
                      }}
                    />
                  )}
                  <PiCaretDownLight
                    className={`${
                      drop ? "rotate-180 duration-200" : "duration-200"
                    }`}
                  />
                </div>
              </div>
              <div
                className={`showScrollbar absolute z-[1] w-full px-[10px] max-h-[200px] shadow-md shadow-[#3d7294] bg-[#0e1420] rounded-b-[10px] overflow-y-scroll  ${
                  drop ? "flex" : "hidden"
                }`}
              >
                <ul className="w-full">
                  {adress &&
                    adress.map((item) => (
                      <li
                        key={item.id}
                        className=" w-full duration-100   mb-[5px]"
                      >
                        <div
                          onClick={() => {
                            setDrop(false);
                            setValue(item.text);
                          }}
                          className="text-[17px] flex items-center gap-[10px]"
                        >
                          <p className="hover:bg-[#203f52] cursor-pointer w-full px-[10px]">
                            {item.text}
                          </p>
                        </div>
                        <div className="px-[10px] flex flex-col gap-y-[5px] ">
                          {(item.cities || []).map((item1) => (
                            <p
                              key={item1.id}
                              onClick={() => {
                                setDrop(false);
                                setValue(item1.city);
                              }}
                              className="hover:bg-[#203f52] cursor-pointer px-[10px] duration-100"
                            >
                              {item1.city}
                            </p>
                          ))}
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-between max-md:items-start">
          <p>მისამართი</p>
          <div className="w-[300px] h-[50px]">
            <input
              value={value1}
              onChange={(e) => setValue1(e.target.value)}
              placeholder="ჩაწერე"
              min="0"
              className="outline-none w-full  rounded-[10px] h-full px-[10px] flex items-center text-white shadow-md shadow-[#3d7294] bg-[#1d263a]"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-start">
        <p
          onClick={() => handleAdd(addAdress)}
          className="flex h-[50px] items-center justify-center rounded-[10px] px-[10px] cursor-pointer duration-150 shadow-md shadow-[#3d7294] bg-[#1d263a] text-white"
        >
          მისამართის დამატება
        </p>
      </div>
    </div>
  );
}
